<div id="modal-content-wrapper">
	<section id="modal-body">
		<mat-card class="ux-card border border-light p-0">
			<mat-card-header class="p-2" fxLayout="row" fxLayoutAlign="space-between center">
				<mat-card-title>Link Customer</mat-card-title>
				<button (click)="closeModal()" class="btn btn-link " type="button" id="dismiss" aria-label="Close">
					<mat-icon class="material-symbols-outlined">close</mat-icon>
				</button>
			</mat-card-header>
			<mat-card-content class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch">
				<form fxLayout="column" fxLayoutGap="20px" [formGroup]="linkCustomerForm">
					<div fxflex="100" fxLayout="row" fxLayout.lt-sm="column">
						<div fxFlex="80" fxFlex.lt-sm="100" fxLayout.lt-sm="column" fxLayout="row"
						[ngClass]="'field-class'" 
						[ngClass.lt-sm]="'field-class-sm'">
							<div fxFlex="50" fxFlex.lt-sm="100" [ngClass]="'form-height'" [ngClass.lt-sm]="'form-height-sm'">
								<mat-label for="linkCustomerId">Parent Customer ID</mat-label>
							</div>
							<div fxFlex="50" fxFlex.lt-sm="100" [ngClass.lt-sm]="'margin-left-sm'" fxLayoutAlign="start center" >
								<span>{{parentCustomerId}}</span>
							</div>
						</div>
					</div>
					<div fxflex="100" fxLayout="row" fxLayout.lt-sm="column">
						<div fxFlex="80" fxFlex.lt-sm="100" fxLayout.lt-sm="column" fxLayout="row"
						[ngClass]="'field-class'" 
						[ngClass.lt-sm]="'field-class-sm'">
							<div fxFlex="50" fxFlex.lt-sm="100" [ngClass]="'form-height'" [ngClass.lt-sm]="'form-height-sm'">
								<mat-label for="linkCustomerId">Customer ID *</mat-label>
							</div>
							<div fxFlex="50" fxFlex.lt-sm="100" [ngClass.lt-sm]="'margin-left-sm'" >
								<mat-form-field appearance="outline">
									<input matInput type="text" id="link-customer"
										formControlName="customerId" [maxlength]="maxDigits" required>
								</mat-form-field>
								<div *ngIf="parentCustomerId === linkCustomerForm.controls.customerId.value" class="text-danger">
										Child customer id should not be same as the parent.
								</div>
							</div>
						</div>
					</div>
				</form>
				
				<mat-card-actions fxLayoutGap=" 20px" fxLayoutAlign="end end">
					<button class="btn btn-sm ux-icon-button" (click)="closeModal()">Cancel</button>
					<button [disabled]="linkCustomerForm.invalid" class="btn btn-sm ux-icon-button mr-3"
						data-toggle="tooltip" title="Link Customer Details" (click)="linkCustomer()">Link
						Customer</button>
				</mat-card-actions>
			</mat-card-content>
		</mat-card>
	</section>
</div>