import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-additional-details',
  templateUrl: './additional-details.component.html',
  styleUrls: ['./additional-details.component.scss']
})
export class AdditionalDetailsComponent implements OnInit {

  @Input() formDetails :any;
  @Input() attribute :any;
  
  constructor() { }

  ngOnInit(): void {
  }

  downloadPdf(url: string) {
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = 'document.pdf';
    downloadLink.click();
    downloadLink.remove();
  }
}
