<div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="showPageLoader" id="loaderdiv">
	<mat-spinner></mat-spinner>
</div>
<div fxLayout="column" *ngIf="auditListDS">

	<div fxFlex="100" fxLayout="column" fxLayoutAlign="space-around stretch" class="p-2">
		<form [formGroup]="formsFilterForm" (ngSubmit)="getAuditEvents()"
			*ngIf="formsFilterForm && advSearchOn">
			<div fxLayout="row wrap" fxLayoutAlign="space-around center">
				<div fxLayout="column" fxFlex="24.5" fxFlex.lt-sm="80">
					<mat-form-field appearance="outline" data-toggle="tooltip" title="Search By User Name">
						<mat-label>Username:</mat-label>
						<input matInput type="text" placeholder="" formControlName="userName">
					</mat-form-field>
				</div>
				<div fxFlex="1" fxShow.lt-sm="false"></div>

				<!-- <div fxLayout="column" fxFlex="48" class="mt-4 ">
					<mat-form-field appearance="outline" data-toggle="tooltip" title="Search By Status">
						<mat-label>Status:</mat-label>
						<mat-select #statusSelect formControlName="status" >

							<mat-option  *ngFor="let sStatus of statusList; let i = index" value="{{sStatus}}">
								{{sStatus}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div> -->
				<div fxLayout="column" fxFlex="24.5" fxFlex.lt-sm="80">
					<mat-form-field appearance="outline" data-toggle="tooltip" title="Search By Event Name">
						<mat-label>Event Name:</mat-label>
						<mat-select #eventNameSelect formControlName="eventName">

							<mat-option *ngFor="let eventname of auditEventList; let i = index" value="{{eventname}}">
								{{eventname}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div fxFlex="1" fxShow.lt-sm="false"></div>
				<div fxLayout="column" fxFlex="49" fxFlex.lt-sm="80">
					<div fxLayout="row" fxFlex="100">
						<div fxLayout="column" fxFlex="48" fxFlex.lt-sm="48">
							<mat-form-field appearance="fill" data-toggle="tooltip" title="Search From Start Date">
								<mat-label>From:</mat-label>
								<input matInput [matDatepicker]="pickerFromDate" formControlName="startDate"
								(dateChange)="dateChangeHandler('startDate')">
								<mat-datepicker-toggle matSuffix [for]="pickerFromDate"></mat-datepicker-toggle>
								<mat-datepicker #pickerFromDate></mat-datepicker>
							</mat-form-field>

						</div>
						<div fxFlex="2"></div>
						<div fxLayout="column" fxFlex="48" fxFlex.lt-sm="48">
							<mat-form-field appearance="fill" data-toggle="tooltip" title="Search upto Ent Date">
								<mat-label>To:</mat-label>
								<input matInput [matDatepicker]="pickerToDate" formControlName="endDate" 
								(dateChange)="dateChangeHandler('endDate')">
								<mat-datepicker-toggle matSuffix [for]="pickerToDate"></mat-datepicker-toggle>
								<mat-datepicker #pickerToDate></mat-datepicker>
							</mat-form-field>
							<!-- <mat-error *ngIf="formsFilterForm.errors?.dateInvalid || formsFilterForm.invalid">
									Please enter a valid date
								</mat-error> -->
						</div>
					</div>
				</div>
			</div>
			<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" class="mb-4">
				<button class="btn btn-sm ux-icon-button" type="submit"
				[disabled]="formsFilterForm.invalid">
					Search
				</button>
				<button class="btn btn-sm ux-icon-button" type="button" (click)="onResetFormSubmit()">
					Reset
				</button>
			</div>
		</form>
		<div fxLayout="column" fxLayoutAlign="space-between stretch" class="mt-2">
			<div fxLayout="row" fxLayout.lt-sm="column" fxLayout.lt-md="column" [ngClass.lt-md]="'mb-4'"
				[ngClass.lt-sm]="'mb-4'" fxFlex="100" fxLayoutAlign="end center" fxLayoutGap="15px"
				*ngIf="formsFilterForm" class="mb-2">

				<mat-form-field *ngIf="!advSearchOn">
					<mat-label>Quick search</mat-label>
					<input matInput class="form-field" #quickSearchInput (keyup)="applyFilter($event.target.value)">
					<button matSuffix mat-icon-button aria-label="Clear"
						(click)="applyFilter(''); quickSearchInput.value=''">
						<mat-icon matSuffix>close</mat-icon>
					</button>
				</mat-form-field>

				<button type="button" class="btn btn-sm ux-icon-button" title="Toggle Advanced Search Form" sty
					(click)="toggleAdvSearch()" fxLayout="row" fxLayoutAlign="start center">
					<mat-icon data-toggle="tooltip" data-placement="bottom" title="Toggle Advanced Search Form"
						class="mr-2">
						manage_search</mat-icon>
					<span>{{advSearchOn ?'Hide' : 'Show'}} Advanced Search</span>
				</button>

				<button type="button" class="btn btn-sm ux-icon-button" title="Refresh" (click)=" onResetFormSubmit()"
					fxLayout="row" fxLayoutAlign="start center">
					<mat-icon data-toggle="tooltip" data-placement="bottom" title="Refresh" class="mr-2">refresh
					</mat-icon><span>Refresh</span>
				</button>
				<button type="button" class="btn btn-sm ux-icon-button mr-2" title="Export Data in CSV format"
					(click)="exportToCSV()" fxLayout="row" fxLayoutAlign="start center">
					<mat-icon data-toggle="tooltip" data-placement="bottom" title="Export Data" class="mr-2">
						file_download
					</mat-icon><span>Export Data</span>
				</button>

			</div>
			<div>
				<mat-table [formGroup]="formsFilterForm" [dataSource]="auditListDS"
					class="live-data-container mat-elevation-z8" matSort 
					[ngClass.lt-sm]="'mat-table-responsive'" #auditTableSort="matSort">
					
					<ng-container matColumnDef="index">
						<mat-header-cell class="audit sl-index" *matHeaderCellDef mat-sort-header>
							<span>#</span>
						</mat-header-cell>

						<mat-cell class="audit sl-index" *matCellDef="let element; let i = index"> 
							<span>{{element.rowIndex}}</span>
						</mat-cell>
					</ng-container>

					<ng-container *ngFor="let attribute of formAttributeConfig; index as i" matColumnDef="{{attribute.name}}">
						<mat-header-cell [ngClass.lt-md]="'mat-cell-responsive'"
						[ngClass.lt-sm]="'mat-cell-responsive-sm'" class="audit pl-1 matHeaderCell" *matHeaderCellDef [disabled]="!attribute.sort" [class]="attribute.class" mat-sort-header>
							<div>{{attribute.label}}</div>
						</mat-header-cell>
					
						<mat-cell class="matCell audit" [ngClass.lt-md]="'mat-cell-responsive'"
						 [ngClass.lt-sm]="'mat-cell-responsive-sm'" *matCellDef="let element;" [class]="attribute.class + ' ' + attribute.type + ' ' + (attribute.class || '')">
								<span *ngIf="!attribute.type || attribute.type === 'string'">
									<span>{{element[attribute.name] || '-'}}</span>
									<!-- <span *ngIf="attribute.translate">{{(element[attribute.name] | labelTranslate) || '-'}}</span> -->
								</span>
								<span *ngIf="attribute.type === 'date'">{{element[attribute.name] | date: 'short' : uiTimezone }}</span>
								<span *ngIf="attribute.type === 'action'">
									<button *ngIf="element[attribute.name] && hasAdditionaDataTypeObj(element[attribute.name])" type="button" class="btn btn-link p-0" data-toggle="tooltip" title="View Additional Data"
											(click)="viewAdditionalData(element)"><span>View</span></button>
									<span *ngIf="element[attribute.name] && !hasAdditionaDataTypeObj(element[attribute.name])" >{{element[attribute.name]}}</span>
									<span *ngIf="!element[attribute.name]">-</span>
								</span>
						</mat-cell>
					</ng-container>


					<tr class="mat-row d-flex" *matNoDataRow>
						<td class="mat-cell d-flex w-100 p-3 font-weight-bold" fxLayoutAlign="center"
							[attr.colspan]="displayedColumns.length">
							No data matching the filter.
						</td>
					</tr>
					<mat-header-row [ngClass.lt-sm]="'d-none'" *matHeaderRowDef="displayedColumns; sticky: true;"
						class="matHeaderRow"></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumns;" fxLayout="row" fxLayout.xs="column" class="matHeaderRow"
						[ngClass.lt-md]="'responsive-mat-row'" [ngClass.lt-sm]="'responsive-mat-row-small'"></mat-row>
				</mat-table>
				<div fxLayout="row" fxLayout.lt-sm="column-reverse" class="table-footer">
					<div fxFlex="40" fxFlex.lt-sm="100" fxLayoutAlign="start center" class="table-disclaimers" *ngIf="defaultDateDisclaimer">
						<div class="text-secondary" >
							<span>** Please note: Data is displayed for last 30 days.</span>
						</div>
					</div>
					<div fxFlex="grow" fxFlex.lt-sm="100" fxLayoutAlign="end none">
						<mat-paginator #formsPaginator="matPaginator" *ngIf="formsFilterForm"
							[pageSizeOptions]="[10, 25, 50]" showFirstLastButtons [pageSize]="10"
							aria-label="Select page">
						</mat-paginator>
					</div>
				</div>
				
			</div>
		</div>
	</div>