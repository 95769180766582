import { InjectionToken, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard, FRAMEWORK_ROUTES, HomePageLayoutComponent } from '@londonhydro/ux-lib';
import { CSR_ROUTES } from './gallery/csr-routing.module';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const ROUTES2: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGuard],
    component: HomePageLayoutComponent,
    children: [
      {
        path: 'csrapp',
        loadChildren: () => import('src/app/gallery/csr.module').then(m => m.CsrModule)
      }
    ]
  },
  { path: 'external', component: HomePageLayoutComponent, canActivate: [externalUrlProvider] }
];
const PAGES: Routes = [
  { path: 'external', component: HomePageLayoutComponent, canActivate: [externalUrlProvider] }
];

const APP_ROUTES: Routes = PAGES.concat(CSR_ROUTES, FRAMEWORK_ROUTES);
@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, { useHash: false, enableTracing: false })],
  exports: [RouterModule],
  providers: [{
    provide: externalUrlProvider,
    useValue: (route: ActivatedRouteSnapshot) => {
      const externalUrl = route.paramMap.get('externalUrl');
      const newtab = route.paramMap.get('newtab');
      if (newtab) {
        window.open(externalUrl, newtab);
      } else {
        window.open(externalUrl, '_self');
      }
    },
  }],
})
export class AppRoutingModule { }
