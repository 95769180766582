import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable()
export class LocationDataService {

  constructor(private http: HttpClient) {
  }

  getServiceLocationData(param: any): Observable<any> {
    const apiUrl = '/public/locationService/location/';
    return this.http.get<any>(environment.customerservice.endPoint + apiUrl + param).pipe(
      tap(res => {
        console.log(' get LocationService Location Data', res);
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getPermitesData(): Observable<any> {
    const apiUrl = '/public/locationService/permits';
    return this.http.get<any>(environment.customerservice.endPoint + apiUrl).pipe(
      tap(res => {
        console.log(' get LocationService Permits Data', res);
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
}
