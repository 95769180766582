import { DatePipe } from '@angular/common';
import { AfterContentInit, Component, OnInit } from '@angular/core';
import { ExportToCsv } from 'export-to-csv';
import * as _ from 'underscore';
import { CsrDataService } from '../../backend/csr/dataservice/csr-data.service';

@Component({
  selector: 'app-download-reports',
  templateUrl: './download-reports.component.html',
  styleUrls: ['./download-reports.component.scss']
})
export class DownloadReportsComponent implements OnInit, AfterContentInit{

  showContent: boolean;
  showPageLoader: boolean;
  formAttributeCISUpdates: any[] = [{ name: 'table', label: 'Table', type: 'string'},
                                  { name: 'column', label: 'Column', type: 'string'},
                                  { name: 'oldValue', label: 'Previous Value', type: 'string'},
                                  { name: 'newValue', label: 'Changed Value', type: 'string'},
                                  {name: 'customerId', label: 'Customer ID', type: 'string'},
                                  {name: 'name_organization', label: 'Customer Name', type: 'string'},
                                  {name: 'accountId', label: 'Account ID', type: 'string'},
                                  {name: 'createDateLocal', label: 'Transaction Date', type: 'string'}];

  formAttributePaymentNotification: any[] = [{ name: 'id', label: 'ID', type: 'string'},
                                            { name: 'accountId', label: 'Account ID', type: 'string'},
                                            { name: 'loginId', label: 'Login ID', type: 'string'},
                                            { name: 'username', label: 'Username', type: 'string'},
                                            { name: 'amount', label: 'Amount', type: 'string'},
                                            { name: 'createDate', label: 'Create Date', type: 'date'},
                                            { name: 'paymentDate', label: 'Payment Date', type: 'date'},
                                            { name: 'confirmationNumber', label: 'Confirmation Number', type: 'string'},
                                            { name: 'customerId', label: 'Customer ID', type: 'string'},
                                            { name: 'description', label: 'Description', type: 'string'},
                                            { name: 'paymentMethod', label: 'Payment Method', type: 'string'},
                                            { name: 'transactionId ', label: 'Transaction ID', type: 'string'}];
  constructor(private csrDataService: CsrDataService, private datePipe: DatePipe) { }

  ngOnInit(): void {
  }
  ngAfterContentInit(): void {
    this.showContent = true;
  }

  downloadCISUpdates(): void {
    this.csrDataService.getCISUpdates().subscribe(
      (res: any) => {
        _.each(res, (element, index) => element.rowIndex = (index + 1));
        this.exportToCSV(res, this.formAttributeCISUpdates, 'Pending CIS Updates');
      },
      (error: any) => {
        console.log('error getCustomerPendingCISUpdates)', error);
      }
    );
  }

  downloadPaymentNotification(): void {
    this.csrDataService.getPayementNotifications().subscribe(
      (res: any) => {
        _.each(res, (element, index) => element.rowIndex = (index + 1));
        this.exportToCSV(res, this.formAttributePaymentNotification, 'Payment Notifications');
      },
      (error: any) => {
        console.log('error getCustomerPendingCISUpdates)', error);
      }
    );
  }

exportToCSV(responsedata: any, formAttribute: any[], filenameforDownload: string): void {
    console.log('Response Data', responsedata);
    const tableDataCSV = [];
    console.log('tableDatCSV', tableDataCSV);
    responsedata.forEach((element, ind) => {
      const configHeader = formAttribute;
      const tempObj: any = {};
      for (const attribute of configHeader) {

          let val = element[attribute.name];
          if (attribute.type === 'date') {
            val = this.datePipe.transform(val, 'mediumDate');
          }
          const key = attribute.label;
          tempObj[key] = val ? val : '';

      }
      tableDataCSV.push(tempObj);
    });
    console.log('tableDatCSV after filter', tableDataCSV);
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: filenameforDownload

    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(tableDataCSV);
  }
}
