import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';

@Pipe({
  name: 'uicurrency'
})
export class UiCurrencyPipe extends CurrencyPipe implements PipeTransform {
  transform(value: any, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string,
    locale?: string): any{
    let modValue = value;
    let isNegetive = false;
    if (value && _.isNumber(value) && Number(value) < 0) {
      modValue = value * -1;
      isNegetive = true;
    } else if (_.isNull(value) || _.isUndefined(value)) {
      modValue = 0;
    }
    const tValue = super.transform(modValue, currencyCode, display, digitsInfo, locale);
    return isNegetive ? ('(' + tValue + ')') : tValue;
  }
}

@Pipe({
  name: 'uicurrencypositive'
})
export class UiCurrencyPositivePipe extends UiCurrencyPipe implements PipeTransform {
  transform(value: any, currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): null | string {
    let modValue = value;
    if (value && _.isNumber(value) && Number(value) < 0) {
      modValue = 0;
    } else if (_.isNull(value) || _.isUndefined(value)) {
      modValue = 0;
    }
    return super.transform(modValue, currencyCode, display, digitsInfo, locale);
  }
}
