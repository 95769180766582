<mat-card fxLayout="column">
	<mat-card-header>Create Customer Management Account</mat-card-header>
	<mat-card-content fxLayout="column">
		<div fxLayout="row" fxLayoutAlign="space-evenly center">
			<mat-radio-group [(ngModel)]="selectedOption">
				<mat-radio-button *ngFor="let portal of portals; let i = index" [value]="portal"
					(change)="selectedOption = $event.value">
					{{portal}}
				</mat-radio-button>
			</mat-radio-group>
			<!-- 	<div>Selected Value is : {{selectedOption}}</div> -->
		</div>
		<div fxLayout="row" fxLayoutAlign="center baseline" fxLayoutGap="20px">
			<form [formGroup]="customerRegForm">
				<mat-label>Search business partner number: </mat-label>
				<mat-form-field appearance="outline">
					<input matInput type="text" placeholder="Type business partner number" formControlName="customerId">
					<button matSuffix mat-icon-button aria-label="Clear"
						(click)="customerRegForm.controls.searchKey.setValue(null)">
						<mat-icon matSuffix>close</mat-icon>
					</button>
				</mat-form-field>
				<mat-error
					*ngIf="customerRegForm.controls.customerId.touched && customerRegForm.controls.customerId.invalid">
					<mat-error *ngIf="customerRegForm.controls.customerId.errors.required">Business Partner Number is
						required.</mat-error>
					<mat-error *ngIf="customerRegForm.controls.customerId.errors.pattern">Please Enter Business Partner
						Number in digits.</mat-error>
				</mat-error>
			</form>
			<div fxLayoutAlign="center center"><button mat-stroked-button color="primary" (click)="findCustomerbyId();"
					[disabled]="!customerRegForm.valid">Search</button></div>
		</div>
		<div fxLayout="row" fxLayoutAlign="space-around center">
			<mat-spinner *ngIf="pageLoader == true"></mat-spinner>
		</div>
		<div fxLayout="column" fxLayoutAlign="center center" *ngIf="customerSearched == null && noCustomerMsg == true">
			<p class="text-danger">
				<strong>Customer details not found for the provided Business Partner number.</strong>
			</p>
		</div>
		<div fxLayout="column" *ngIf="showContent== true && customerSearched !== null">
			<mat-divider></mat-divider>
			<div>
				<h2>{{customerSearched.firstName}} {{customerSearched.lastName}}</h2>
			</div>
			<div>
				<mat-label>Customer ID : </mat-label> {{customerSearched.customerId}}
			</div>
			<div>
				<mat-label>Mailing Address : </mat-label>
				<p>
					<span
						*ngIf="(customerSearched.mailingAddress.careOf != 'NULL' && customerSearched.mailingAddress.careOf != null) && customerSearched.mailingAddress.careOf != ''">
						{{customerSearched.mailingAddress.careOf}}<br />
					</span>
					<span
						*ngIf="(customerSearched.mailingAddress.streetSupplemental1 != 'NULL' && customerSearched.mailingAddress.streetSupplemental1 != null) && customerSearched.mailingAddress.streetSupplemental1 != ''">
						{{customerSearched.mailingAddress.streetSupplemental1}}<br />
					</span>
					<span
						*ngIf="(customerSearched.mailingAddress.streetSupplemental2 != 'NULL' && customerSearched.mailingAddress.streetSupplemental2 != null) && customerSearched.mailingAddress.streetSupplemental2 != ''">
						{{customerSearched.mailingAddress.streetSupplemental2}}<br />
					</span>
					<span
						*ngIf="(customerSearched.mailingAddress.streetNumber != 'NULL' && customerSearched.mailingAddress.streetNumber != null) && customerSearched.mailingAddress.streetNumber != ''">
						{{customerSearched.mailingAddress.streetNumber}}&nbsp;
					</span>
					<span
						*ngIf="(customerSearched.mailingAddress.streetName != 'NULL' && customerSearched.mailingAddress.streetName != null) && customerSearched.mailingAddress.streetName != ''">
						{{customerSearched.mailingAddress.streetName}}<br />
					</span>
					<span
						*ngIf="(customerSearched.mailingAddress.streetUnit != 'NULL' && customerSearched.mailingAddress.streetUnit != null) && customerSearched.mailingAddress.streetUnit != ''">
						{{customerSearched.mailingAddress.streetUnit}}<br />
					</span>
					<span>
						{{customerSearched.mailingAddress.city}},&nbsp;{{customerSearched.mailingAddress.province}},&nbsp;{{customerSearched.mailingAddress.postalCode}}
					</span>
				</p>
			</div>
			<div>
				<li *ngFor="let email of customerSearched.customerEmails">
					<mat-icon matPrefix>local_post_office</mat-icon>
					{{email.emailAddress}}
					<span *ngIf="email.standard == true">(Bill Ready Notification Email)</span>
				</li>
			</div>
			<div fxFlex="100" fxLayout="column" *ngIf="selectedOption === 'Property Management Portal'">
				<div fxFlex="100" fxLayout="row" fxLayoutAlign="end stretch">
					<button mat-stroked-button>
						<span>Properties: <span><strong>
									{{unitAndTenantCnt.propertyCnt}}</strong></span></span>
					</button>
					<button mat-stroked-button>
						<span>Tenant Occupied Unit(s):
							<span><strong>{{unitAndTenantCnt.tenantCount}}</strong></span></span>
					</button>
				</div>
			</div>
		</div>
	</mat-card-content>
</mat-card>