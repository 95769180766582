import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractComponent } from '@londonhydro/ux-lib';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CsrDataService } from '../../backend/csr/dataservice/csr-data.service';
import { ErrorLabelPipe } from '../../framework/shared/service/error-label.pipe';

@Component({
  selector: 'app-resend-invitation',
  templateUrl: './resend-invitation.component.html',
  styleUrls: ['./resend-invitation.component.scss']
})
export class ResendInvitationComponent extends AbstractComponent implements OnInit {

  pageLoader: boolean;
  userData: any;

  constructor(injector: Injector, public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public modalData: any,
    private toastr: ToastrService, private csrDataService: CsrDataService, private translatePipe: ErrorLabelPipe) {
    super(injector);
    this.userData = modalData;
    console.log('this.userData:::', this.userData);
  }

  ngOnInit(): void {
  }

  sendRegistrationEmail(): void {
    this.pageLoader = true;

    this.csrDataService.sendInvitationEmail(this.loginUser.id, this.userData).pipe(
      catchError(error => {
        this.closeModal('error');
        this.pageLoader = false;
        this.toastr.error('Unable to send the invitation email' + (error.statusText ? '. ' + this.translatePipe.transform(error.statusText) : ''), 'Error', {
          timeOut: 10000,
        });

        return of(null);
      })).subscribe(response => {
        console.log('response', response);
        if (response) {
          this.toastr.success('You have successfully sent the invitation email ', 'Success', {
            timeOut: 10000,
          });
          this.pageLoader = false;
          this.closeModal('success');

        }
      }
      );

  }
  closeModal(str?: string): void {
    this.dialogRef.close(str);
  }
}
