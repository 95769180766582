import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { AbstractComponent } from '@londonhydro/ux-lib';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends AbstractComponent implements OnInit, OnDestroy {

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  changePasswordForm(): void {
    this.router.navigate(['/csrapp/change-password-form']);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
