import { Component, OnInit, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractComponent } from '@londonhydro/ux-lib';
import { AccountDataProvider } from '../../backend/csr/dataservice/account.dataprovider';

@Component({
  selector: 'app-customer-registration',
  templateUrl: './customer-registration.component.html',
  styleUrls: ['./customer-registration.component.scss']
})
export class CustomerRegistrationComponent extends AbstractComponent implements OnInit {

  constructor(injector: Injector, route: ActivatedRoute, accountProvider: AccountDataProvider,
    router: Router) {
    super(injector);
  }

  userSessionLoaded() {
    console.log('On child component class::::userDataLoaded');
  }

  ngOnInit(): void {
  }

  gotoForm(): void {
    this.router.navigate(['./csrapp/customer-registration-form']);
  }
}
