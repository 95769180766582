import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-customer-search-detail',
  templateUrl: './customer-search-detail.component.html',
  styleUrls: ['./customer-search-detail.component.scss']
})
export class CustomerSearchDetailComponent implements OnInit {
  @Input() CustomerViewData: any; isCustomerViewClicked = true;
  @Output() backToCustomer = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void { }

  accountDataLoaded(): any {
    if (this.CustomerViewData){
      return { CustomerViewData: this.CustomerViewData};
    }
    else {
      return { CustomerViewData: this.CustomerViewData[0]};
    }
  }

  redirectToCustomerView(): void {
  }

  redirectToParent(): void {
    this.backToCustomer.emit(false);
  }
}
