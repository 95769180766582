import * as _ from 'underscore';

export class ContractRates {
	static RATES_FOR_CONSUMPTION = ['ERES_DIST', 'EG<50_DIST', 'EG>50_DISN', 'EG>50_DISI', 'ELRG_DIST', 'ESTL_DIST', 'ESEN_DIST', 'EUSL_DIST'];
	static RATES_FOR_DEMAND = ['EG>50_DISN', 'EG>50_DISI', 'ELRG_DIST'];
	static RATES_FOR_HDWM = ['ERES_DIST', 'EG<50_DIST', 'EG>50_DISN', 'EG>50_DISI', 'ELRG_DIST'];
	static RESI_RATES = ['ERES_DIST', 'ERES_RGEN', 'W&S_RESI'];
	static RESI_RATES_ELEC = ['ERES_DIST', 'ERES_RGEN'];
	static RESI_RATES_TOU_TIER = ['ERES_RPP', 'EG<50_RPP'];
	static SMALL_COMM_RATES = ['EG<50_DIST', 'EG<50_RGEN', 'W&S_COMM'];
	static LARGE_COMM_RATES = ['EG>50_DISI', 'EG>50_DISN', 'EG>50_SIWI', 'EG>50_SGEN', 'ELRG_DIST', 'W&S_IND', 'W&S_INS'];

	static RATES_FOR_ENERGY_GENERATION = ['EG<50_DISF', 'ECG_GENE', 'ECG_GENE50', 'ECG_GRESOP', 'ECOG_GEN', 'E_FIT', 'EG<50_RGEN',
		'EG<50_SGEN', 'EG>50_RGEN', 'EG>50_SGEN', 'E_MFGN', 'ERES_RGEN', 'ERES_SGEN'];
	static RATES_FOR_NON_ENERGY_USAGE = ['ECG_GENE', 'ECG_GENE50', 'ECOG_GEN', 'EG<50_RGEN', 'EG>50_DIWI', 'EG>50_RGEN',
		'EG>50_SGEN', 'ERES_RGEN', 'E_FIT', 'E_IMP', 'E_MFGN', 'E_RETDCB'];
	static RATES_FOR_ENERGY_USAGE = ['ECG_DI50', 'ECG_DICG', 'EG<50_DISF', 'EG<50_DIST', 'EG>50_DISI', 'ELRG_DIST', 'ERES_DIST'];
}

export class ContractRate {
	id: string;
	contractId: string;
	supplyRate: string;
	distributionRate: string;
	rateSubCat: string;
	contractRateEffDate?: string;
	contractRateEndDate?: string;
	accountId: string;
	serviceId: string;
	eff_date: string;
	endDate: string;
	supplyRateDescription: string;
	rpp: any;
	dcb: any;
	spot: any;
	supplyRateSuffix: string;
	demand: any;
	consumption: any;
	hdwm: any;
	rateType: string;
	tier: any;
	tou: any;

	isPureWaterResidential?(): boolean {
		return this.distributionRate === 'W&S_RESI' && !this.rateSubCat;
	}

	isEnergyTileApplicable?(): boolean {
		return !_.contains(ContractRates.RATES_FOR_NON_ENERGY_USAGE, this.distributionRate);
	}

	isEnergyUsageApplicable?(): boolean {
		return _.contains(ContractRates.RATES_FOR_ENERGY_USAGE, this.distributionRate);
	}

	isEnergyPricePlanSwitchApplicable?(): boolean {
		return _.contains(ContractRates.RESI_RATES_TOU_TIER, this.supplyRate);
	}
}
