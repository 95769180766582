import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent } from '@londonhydro/ux-lib';
import { error } from 'highcharts';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, subscribeOn, tap } from 'rxjs/operators';
import { CsrDataService } from 'src/app/backend/csr/dataservice/csr-data.service';

@Component({
  selector: 'app-unlink-customer',
  templateUrl: './unlink-customer.component.html',
  styleUrls: ['./unlink-customer.component.scss']
})
export class UnlinkCustomerComponent extends AbstractComponent implements OnInit, OnDestroy {

  submitted = false;
  pageLoader: boolean;
  customerId: any;

  constructor(
    injector: Injector,
    private toastr: ToastrService,
    private csrDataService: CsrDataService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public userData: any) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  unlinkCustomer(): void {
    this.submitted = true;
    this.pageLoader = true;

    const params = JSON.parse(JSON.stringify({
      customerId: this.userData.data.id
    }));

    let parentCustomerId = '';
    if (this.userData && this.userData.parentData && this.userData.parentData.id) {
      parentCustomerId = this.userData.parentData.id;
    }

    this.csrDataService.unlinkCustomer(parentCustomerId, params).pipe(
      catchError(error => {
        if(error && error.message){
          this.toastr.error( error.message, 'Error', {
            timeOut: 5000,
          });
        } else {
          this.toastr.error('Error occured while unlinking customer.', 'Error', {
            timeOut: 5000,
          });
        }
        this.pageLoader = false;
        this.closeModal();
        return of(null);
      })).subscribe((response: any) => {
        if(response && response.message){
          this.toastr.success( response.message , 'Success', {
            timeOut: 5000,
          });
          this.closeModal('success');
        }
        this.pageLoader = false;
      });
  }

  ngOnDestroy(): void {
    console.log('On child component class::::ngOnDestroy');
    super.ngOnDestroy();
  }

  closeModal(str?: string): void {
    this.dialogRef.close(str);
  }
}



