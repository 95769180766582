import { Pipe, PipeTransform } from '@angular/core';
import { CsrDataService } from '../../../backend/csr/dataservice/csr-data.service';

@Pipe({
  name: 'iamLabelPipe',
})
export class IamLabelPipe implements PipeTransform {

  iamLabel:any;
  settingsResponse:any;
  constructor(private csrDataService:CsrDataService){
    this.csrDataService.getSettingsList().subscribe((res:any)=>{
        this.settingsResponse=res;
        console.log('settingsResopnse::',this.settingsResponse);
        this.getIamLabelList();
    })
  }

  getIamLabelList(){
    console.log('i18Url::',this.settingsResponse.i18nUrl);
    if(this.settingsResponse.i18nUrl){
    this.csrDataService.getIamEnJsonList(this.settingsResponse.i18nUrl).subscribe((res:any)=>{
        this.iamLabel=res;
    })
    }
  }

  transform(value: any, _args?: any): any {
    if (value in this.iamLabel) {
      return this.iamLabel[value];
    } else {
      return value;
    }
  }
}
