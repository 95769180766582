import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-input-text-dialog',
  templateUrl: './input-text-dialog.component.html',
  styleUrls: ['./input-text-dialog.component.scss']
})
export class InputTextDialogComponent implements OnInit {

  messageType = 'info';
  message: string;

  constructor(public dialogRef: MatDialogRef<InputTextDialogComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { }

  ngOnInit(): void {
    this.messageType = 'info';
    this.message = null;
  }

  hideInfo(): void {
    this.messageType = 'info';
    this.message = null;
  }

  actionSubmit(): void {
    if (this.modalData.remarks && this.modalData.remarks.trim().length > 0) {
      this.dialogRef.close(this.modalData.remarks);
    } else {
      this.messageType = 'warning';
      this.message = 'Please provide the mandatory input.';
    }
  }

  actionCancel(): void {
    this.dialogRef.close();
  }
}
