<div id="modal-content-wrapper">
	<section id="modal-body">
		<mat-card class="ux-card border border-light p-0">
			<mat-card-header class="p-2 pt-3" fxLayout="row" fxLayoutAlign="space-between center">
				<mat-card-title>{{title}}</mat-card-title>
				<button (click)="actionCancel()" class="btn btn-link text-secondary" type="button" id="dismiss"
					aria-label="Close">
					<mat-icon class="material-symbols-outlined">close</mat-icon>
				</button>
			</mat-card-header>
			<mat-card-content fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px" class="p-2">
				<h2 class="">{{message}}</h2>

				<mat-accordion class="border border-light p-0 mt-2">
					<mat-expansion-panel [expanded]="true">
						<mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
							fxLayoutAlign="space-between center">
							<mat-panel-title class="mb-0 text-white font-weight-bold">Generated Alerts</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-card-content fxLayout="row" fxLayoutAlign="space-between center" class="mb-0">
							<div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
								<div fxFlex="100" fxShow.lt-sm="false" fxLayout="row" fxLayoutAlign="space-between center"
									class="border-bottom border-secondary">
									<div fxFlex="10" fxFlex.lt-md="30" class="p-2 font-weight-bold">
										<span>#</span>
									</div>
									<div fxFlex="65" fxFlex.lt-md="40" class="p-2 font-weight-bold">
										<span>Message/Alert</span>
									</div>
									<div fxFlex="25" fxFlex.lt-md="30" class="p-2 font-weight-bold">
										<span>Generated On</span>
									</div>
								</div>
								<div fxFlex="100" fxLayout="row wrap"
									*ngIf="requestAlerts && requestAlerts.length > 0; else noDataBlock">
									<div fxFlex="100" fxLayout="row" fxLayout.lt-sm="column"  [ngStyle.lt-sm]="'border-bottom:double;'" fxLayoutAlign="space-between center"
										*ngFor="let alert of requestAlerts; index as i">
										<div fxFlex="10"  fxFlex.lt-sm="100" fxFlex.lt-md="30" class="p-2" [ngClass.lt-sm]="'mr-auto'">
											<span fxShow.lt-sm="true" fxShow="false" class="font-weight-bold "># </span>
											<span>{{alert.serialNumber}}</span>
										</div>
										<div fxFlex="65"  fxFlex.lt-sm="100" fxFlex.lt-md="40" class="p-2" fxLayout="row" [ngClass.lt-sm]="'mr-auto'">
											<span fxShow.lt-sm="true" fxShow="false" class="font-weight-bold ">Message/Alert</span>
											<div [class]="'bg-severity-' + alert.severity" class="mr-1">
												<svg class="bi flex-shrink-0 me-2" width="20" height="20" *ngIf="alert.severity != 'L'"><use xlink:href="#exclamation-triangle-fill"/></svg>
												<svg class="bi flex-shrink-0 me-2" width="20" height="20" *ngIf="alert.severity === 'L'"><use xlink:href="#info-fill"/></svg>
											</div>
											<span>{{alert.message}}</span>
										</div>
										<div fxFlex="25" fxFlex.lt-sm="100" fxFlex.lt-md="30" class="p-2" [ngClass.lt-sm]="'mr-auto'">
											<span fxShow.lt-sm="true" fxShow="false" class="font-weight-bold ">Generated On</span>
											<span>{{alert.createdDate | date: 'short': uiTimezone}}</span>
										</div>
									</div>
								</div>
								<ng-template #noDataBlock>
									<div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
										<p class="font-weight-bold text-secondary">There are no alerts generated for the
											request.</p>
									</div>
								</ng-template>
							</div>
						</mat-card-content>
					</mat-expansion-panel>
				</mat-accordion>

				<mat-card-actions fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px" class="p-2 bg-light">
					<button type="button" class="btn btn-secondary" (click)="actionCancel()">Cancel</button>
					<button type="button" class="btn btn-success" (click)="actionSubmit()"
						cdkFocusInitial>Confirm</button>
				</mat-card-actions>
			</mat-card-content>
		</mat-card>
	</section>
</div>
