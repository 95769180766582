import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AbstractComponent } from '@londonhydro/ux-lib';
import { AccountDataProvider } from '../../backend/csr/dataservice/account.dataprovider';
import { CsrDataService } from '../../backend/csr/dataservice/csr-data.service';

@Component({
  selector: 'app-customer-registration-form',
  templateUrl: './customer-registration-form.component.html',
  styleUrls: ['./customer-registration-form.component.scss']
})
export class CustomerRegistrationFormComponent extends AbstractComponent implements OnInit, OnDestroy {

  customerSearched: any;
  customerRegForm: FormGroup = this.formBuilder.group({
    customerId: ['', [Validators.required, Validators.minLength(3),
    Validators.maxLength(20), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]]
  });
  portals: string[] = ['MyLondonHydro Portal', 'Property Management Portal', 'Builders Portal'];
  showContent: boolean;
  noCustomerMsg: boolean;
  pageLoader: boolean;
  selectedOption: string = 'MyLondonHydro Portal';
  isChildCustomer = false;
  masterCustomerId = '';
  newuser = null;
  unitAndTenantCnt: any;
  constructor(injector: Injector,route: ActivatedRoute,
              accountProvider: AccountDataProvider, private csrDataService: CsrDataService,
              private formBuilder: FormBuilder) {
    super(injector);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  findCustomerbyId(): void {
    this.showContent = false;
    this.pageLoader = true;
    if (this.selectedOption === 'Property Management Portal') {

      this.csrDataService.findCustomerUnitAndTenantCounts(this.customerRegForm.controls.customerId.value).subscribe(result => {
        this.unitAndTenantCnt = result;
        console.log('masterCustomerId:::::::', this.unitAndTenantCnt.masterCustomerId);
        if (this.unitAndTenantCnt.masterCustomerId !== 0 && this.unitAndTenantCnt.masterCustomerId !== this.newuser.customerId) {
          this.isChildCustomer = true;
          this.masterCustomerId = this.unitAndTenantCnt.masterCustomerId;
        }
        console.log('masterCustomerId:::1111::::', this.masterCustomerId, ' :::::isChildCustomer :::::: ', this.isChildCustomer);
        this.pageLoader = false;
      }, error => {
        console.log('fail', error);
        this.unitAndTenantCnt = {};
        this.pageLoader = false;
      });
    }

    this.csrDataService.getCustomerSearch(this.customerRegForm.controls.customerId.value).subscribe /* this.newuser.customerId */
      (response => {
        if (response !== null) {
          console.log(response)
          this.showContent = true;
          this.pageLoader = false;
          this.customerSearched = response;
        } else {
          this.noCustomerMsg = true;
          this.customerSearched = null;
          this.pageLoader = false;
        }
      }, error => {
        console.log(error);
      })
  }
}
