<div id="modal-content-wrapper">
<section id="modal-body">
		
<mat-card class="ux-card border border-light p-0">
			<mat-card-header class="p-2" fxLayout="row" fxLayoutAlign="space-between center">
				<mat-card-title>Change Login Email Address</mat-card-title>
				<button (click)="closeModal()" class="btn btn-link " type="button" id="dismiss" aria-label="Close">
					<mat-icon class="material-symbols-outlined">close</mat-icon>
				</button>
			</mat-card-header>
	<mat-card-content  class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch" >
		<form fxLayout="column" fxLayoutGap="20px"  [formGroup]="loginEmailChangeForm">
			<div fxLayout="column">
			<div fxflex="100" fxLayout="row"  fxLayout.lt-sm="column">
				<div fxFlex="80" fxFlex.lt-sm="100" fxLayout.lt-sm="column" fxLayout="row"
				[ngClass]="'field-class-margin'" 
				[ngClass.lt-sm]="'field-class-margin-sm mb-3'">
				<div fxFlex="50" fxFlex.lt-sm="100"  [ngClass]="'form-height-sm-2'">
					<mat-label for="login-email-address"> Current Email Address * </mat-label>
				</div>
				<div fxFlex="50" fxFlex.lt-sm="100" [ngClass.lt-sm]="'margin-left-sm'" [ngClass]="'mt-1'">
					{{userData.username == ''|| userData.username== null ? 
					'Not Availabale': this.userData.username}} <!-- customerDetails -->
				</div>
				</div>
			</div>
			<div fxflex="100" fxLayout="row" fxLayout.lt-sm="column">
				<div fxFlex="80" fxFlex.lt-sm="100" fxLayout.lt-sm="column" fxLayout="row"
				[ngClass]="'field-class'" 
				[ngClass.lt-sm]="'field-class-sm'">
				<div fxFlex="50" fxFlex.lt-sm="100" [ngClass]="'form-height'" [ngClass.lt-sm]="'form-height-sm'">
					<mat-label for="loginEmailAddress">New Email Address *</mat-label>
				</div>
				<div fxFlex="50" fxFlex.lt-sm="100" [ngClass.lt-sm]="'margin-left-sm'" >
					<mat-form-field appearance="outline">
						<input matInput type="email" id="login-email-address"
							formControlName="loginEmailAddress" maxlength="240" required>
					</mat-form-field>
					<mat-error *ngIf="loginEmailChangeForm.get('loginEmailAddress').invalid &&
					 loginEmailChangeForm.get('loginEmailAddress').touched">
						Please enter a valid email !
					</mat-error>
					<mat-error *ngIf="loginEmailChangeForm.errors?.newEmailSameAsCurrentEmail &&
					loginEmailChangeForm.get('loginEmailAddress').touched">
						Email entered is same as current email !
					</mat-error>
					
				</div>
				</div>
			</div>
			<div fxflex="100" fxLayout="row"  fxLayout.lt-sm="column">
				<div fxFlex="80" fxFlex.lt-sm="100" fxLayout.lt-sm="column" fxLayout="row" 
				 [ngClass]="'field-class'" 
				 [ngClass.lt-sm]="'field-class-sm'">
				<div fxFlex="50" fxFlex.lt-sm="100" [ngClass]="'form-height'" [ngClass.lt-sm]="'form-height-sm'"> 
					<mat-label for="confirmEmailAddress"> Confirm Email Address *
						</mat-label>
				</div>
				<div fxFlex="50" fxFlex.lt-sm="100" [ngClass.lt-sm]="'margin-left-sm'">
					<mat-form-field appearance="outline">
						<input matInput type="email" id="confirm-email-address"
							formControlName="confirmEmailAddress" maxlength="240" required>
					</mat-form-field>
					<mat-error *ngIf="loginEmailChangeForm.get('confirmEmailAddress').invalid &&
					loginEmailChangeForm.get('confirmEmailAddress').touched">
						Please enter a valid email !
					</mat-error>
					<mat-error *ngIf="loginEmailChangeForm.errors?.confirmEmailSameAsCurrentEmail &&
					loginEmailChangeForm.get('confirmEmailAddress').touched">
						Email entered is same as current email !
					</mat-error>
					<mat-error *ngIf="loginEmailChangeForm.errors?.newAndConfirmEmailNotSame &&
					loginEmailChangeForm.get('confirmEmailAddress').touched">
						Email does not match !
					</mat-error>
				</div>
				</div>
			</div>
			<div fxFlex="100" fxLayout="column" fxLayoutAlign="center">
				<div fxFlex="80">
				<small>
					An email will be sent to the current Login Email Address to remind/notify of this change.
				</small>
				</div>
			</div>
			</div>
			
		</form>
		 <mat-card-actions fxLayoutGap=" 20px" fxLayoutAlign="end end">
		<button class="btn btn-sm ux-icon-button" (click)="closeModal()">Cancel</button>
		<button [disabled]="loginEmailChangeForm.invalid || loginEmailChangeForm.errors?.newAndConfirmEmailNotSame
		|| loginEmailChangeForm.errors?.confirmEmailSameAsCurrentEmail || loginEmailChangeForm.errors?.newEmailSameAsCurrentEmail" class="btn btn-sm ux-icon-button mr-3"
		data-toggle="tooltip" title="Save new login email" (click)="editLoginEmail()">Change Login Email</button>
		</mat-card-actions>
	</mat-card-content>
	
	</mat-card>
	</section>
	</div>