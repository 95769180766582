<div fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch">
	<div fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch">
		<mat-card-header>
			<h2>Profile</h2>
		</mat-card-header>
		<mat-card>
			<mat-card-content>
				<mat-card-subtitle>
					<h3>User Profile</h3>
				</mat-card-subtitle>
				<mat-divider color="red"></mat-divider>
				<div fxLayout="column" fxLayoutAlign="start start">
					<div fxLayout="row">
						<mat-label>User Name:</mat-label>
						<span>{{user.firstName}} {{user.lastName}}</span>
						<span *ngIf="this.user.firstName == null && this.user.lastName === null">Not Set</span>
					</div>
					<div fxLayout="row">
						<mat-label>Login Email:</mat-label>
						<span>{{user.username}}</span>
					</div>
					<div fxLayout="row">
						<mat-label>Role:</mat-label>
						<span>{{user.roles[0].roleName}}</span>
					</div>
					<div fxLayout="row">
						<mat-label>Phone:</mat-label>
						<span>{{user.phoneNumber ? user.phoneNumber:'Not Available'}}</span>
					</div>
				</div>
			</mat-card-content>
		</mat-card>
		</div>
		</div>
		