import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceCharacter'
})
export class SentenceCasePipe implements PipeTransform {

  transform(value: any, arg: any, replacementStr: any): unknown {
    return (value && arg && replacementStr) ? value.replace(new RegExp(arg, 'g'), replacementStr) : value;
  }

}
