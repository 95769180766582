import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-widget-card',
    templateUrl: './widget-card.component.html',
    styleUrls: ['./widget-card.component.scss']
})
export class WidgetCardComponent implements OnInit {
    @Input() title: string;
    @Input() iconName: string;
    @Input() subTitle: string;
    @Input() contentClass: string;
    @Input() headerClass: string;
    @Input() footer: string;
    @Input() footerClass: string;
    @Input() enableToggleMode = false;
    @Input() enableBackButton = false;
    @Input() actionRow = false;

    @Input('config') set config(c: any) {
        if (c == null) {
            c = {};
        }
        this.title = c.title || null;
        this.iconName = c.iconName || null;
        this.subTitle = c.subTitle || null;
        this.contentClass = c.contentClass || null;
        this.headerClass = c.headerClass || null;
        this.footer = c.footer || null;
        this.footerClass = c.footerClass || null;
        this.enableToggleMode = c.enableToggleMode || false;

    }
    minimized = false;
    @Output() isMinimized = new EventEmitter<boolean>();

    constructor() { }

    ngOnInit(): void {
    }
    toggleView(): void {
        this.minimized = !this.minimized;
        this.isMinimized.emit(this.minimized);
    }
}
