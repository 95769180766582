export class ServiceLocation{
permitId: string;
premiseId: string;
locationId: string ;
lotNumber: string ;
defaultServiceType: string ;
streetNumber: string ;
streetName: string ;
streetCity: string ;
streetProvince: string ;
postalCode: string ;
streetUnit: string;

public get serviceAddress(): string {
const address: string =  this.streetNumber + ' ' + this.streetName + ' - ' +
this.streetUnit + ' ' + this.postalCode + ' ' + ', ' + this.streetCity + ', ' + this.streetProvince;
return address;
}
}
