<mat-card class="ux-card border border-light p-0" fxFlex="100">
	<mat-card-content class="content-body" fxLayout="row wrap" fxLayoutAlign="space-between stretch" fxLayoutGap="10" fxFlex="100">
		<mat-accordion class="border border-light p-0 mt-2" fxFlex="49" [fxFlex.lt-sm]="100" >
			<mat-expansion-panel [expanded]="true">
				<mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
					fxLayoutAlign="space-between stretch">
					<mat-panel-title class="mb-0 text-white font-weight-bold">Old Values
					</mat-panel-title>
				</mat-expansion-panel-header>
				<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between stretch" class="mb-0">
					<div fxFlex="100" fxLayout="row wrap" fxLayout.xs="column" *ngIf="oldValuesList"
						fxLayoutAlign="space-between center" fxLayoutAlign.xs="start">
						<ng-container *ngFor="let element of oldValuesList">
							<ng-container *ngIf="isConfigPresent(element[0]); else genericOld">
								<div fxFlex.xs="100" fxFlex="100" class="text-break" *ngFor="let attribute of attributeConfig">
									<div *ngIf="element[0] === attribute.name" class="pt-2 pb-2">
										<mat-label fxFlex="45" fxFlex.xs="100" class="font-weight-bold">{{attribute.label}}:</mat-label>
										<span *ngIf="attribute.type === 'text'">{{element[1] ? element[1] : '-'}}</span>
										<span *ngIf="attribute.type === 'number'">{{element[1] ? element[1] : '-'}}</span>
										<span *ngIf="attribute.type === 'date'">{{(element[1] ? (element[1] | date:'MM-dd-yyyy') : '-')}}</span>
										<span *ngIf="attribute.type === 'currency'">{{(element[1] ? (element[1] | currency) : '-')}}</span>
										<span *ngIf="attribute.type === 'email'">{{(element[1] ? (element[1] | lowercase) : '-')}}</span>
									</div>
								</div>
							</ng-container>
							<ng-template #genericOld>
								<div fxFlex.xs="100" fxFlex="100" class="text-break">
									<div class="pt-2 pb-2">
										<mat-label fxFlex="45" fxFlex.xs="100" class="font-weight-bold">{{element[0] | labelTranslate | replaceCharacter:'_':' ' | titlecase}}:</mat-label>
										<span>{{element[1] ? element[1] : '-'}}</span>
									</div>
								</div>
							</ng-template>		
						</ng-container>
					</div>
				</mat-card-content>
			</mat-expansion-panel>
		</mat-accordion>

		<mat-accordion class="border border-light p-0 mt-2" fxFlex="49" [fxFlex.lt-sm]="100" >
			<mat-expansion-panel [expanded]="true">
				<mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
					fxLayoutAlign="space-between stretch">
					<mat-panel-title class="mb-0 text-white font-weight-bold">New Values
					</mat-panel-title>
				</mat-expansion-panel-header>
				<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between stretch" class="mb-0">
					<div fxFlex="100" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="start"
						fxLayoutAlign="space-between center" *ngIf="newValuesList">
						<ng-container *ngFor="let element of newValuesList">
							<ng-container *ngIf="isConfigPresent(element[0]); else genericNew">
								<div fxFlex.xs="100" fxFlex="100" class="text-break" *ngFor="let attribute of attributeConfig">
									<div *ngIf="element[0] === attribute.name" class="pt-2 pb-2">
										<mat-label fxFlex="45" fxFlex.xs="100" class="font-weight-bold">{{attribute.label}}:</mat-label>
										<span *ngIf="attribute.type === 'text'">{{element[1] ? element[1] : '-'}}</span>
										<span *ngIf="attribute.type === 'number'">{{element[1] ? element[1] : '-'}}</span>
										<span *ngIf="attribute.type === 'date'">{{(element[1] ? (element[1] | date:'MM-dd-yyyy') : '-')}}</span>
										<span *ngIf="attribute.type === 'currency'">{{(element[1] ? (element[1] | currency) : '-')}}</span>
										<span *ngIf="attribute.type === 'email'">{{(element[1] ? (element[1] | lowercase) : '-')}}</span>
									</div>
								</div>
							</ng-container>
							<ng-template #genericNew>
								<div fxFlex.xs="100" fxFlex="100" class="text-break">
									<div class="pt-2 pb-2">
										<mat-label fxFlex="45" fxFlex.xs="100" class="font-weight-bold">
											{{element[0] | labelTranslate | replaceCharacter:'_':' ' | titlecase}}:</mat-label>
										<!-- <span [class]="(element[1].includes(',')?' newLine ':'')">
											{{element[1] ? (element[1] | labelTranslate | replaceCharacter:'_':' ' | titlecase) : '-'}}</span> -->
										<span *ngIf="element[1] && !element[1].includes(',')">
											{{element[1] ? (element[1] | labelTranslate | replaceCharacter:'_':' ' | titlecase) : '-'}}
										</span>
										<span *ngIf="element[1] && element[1].includes(',')" class="newLine">
											{{element[1] ? (element[1] | labelTranslate | replaceCharacter:',':'\n') : '-'}}
										</span>	
									</div>
								</div>
							</ng-template>		
						</ng-container>
					</div>
				</mat-card-content>
			</mat-expansion-panel>
		</mat-accordion>
	</mat-card-content>
</mat-card>