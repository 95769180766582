<div>
	<section>

		<mat-card class="ux-card border border-light p-0">
			<mat-card-header class=" p-2" fxLayout="row" fxLayoutAlign="space-between center">
				<mat-card-title>Additional Data Viewer</mat-card-title>
				<button (click)="closeModal()" class="btn btn-link text-secondary" type="button" id="dismiss"
					aria-label="Close">
					<mat-icon class="material-symbols-outlined">close</mat-icon>
				</button>
			</mat-card-header>
			<mat-card-content class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch">

				<mat-card-content class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch">

					<mat-accordion class="border border-light p-0 mt-2" *ngIf="userData && ((userData.actual && userData.actual.challenge) || userData.challenge)">
						<mat-expansion-panel [expanded]="true">
							<mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
								fxLayoutAlign="space-between center">
								<mat-panel-title class="mb-0 text-white font-weight-bold">User Input Details
								</mat-panel-title>
							</mat-expansion-panel-header>
							<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
								<div fxFlex="100" fxLayout="row wrap" fxLayout.xs="column" *ngIf="actualList"
									fxLayoutAlign="space-between center" fxLayoutAlign.xs="start">
									<div fxFlex="50" fxFlex.xs="100"  class="pt-2 pb-2" *ngFor="let item of actualList | keyvalue">
										<mat-label fxFlex="45" fxFlex.xs="100"> <span>{{item.value[0] | labelTranslate}}: </span></mat-label>
										<mat-label fxFlex="55" fxFlex.xs="100" *ngIf="item.value[1]" class="font-weight-bold" [class]="item.value[1].includes(',')?' text-break ':''">
											<!-- {{item.value[1].includes(',')?item.value[1].trim().split(',').join(', '):item.value[1]}} -->
											{{calcValue(item, false)}}
										</mat-label>
										<mat-label fxFlex="55" fxFlex.xs="100" *ngIf="!item.value[1]" class="font-weight-bold">-</mat-label>
									</div>
								</div>
							</mat-card-content>
						</mat-expansion-panel>
					</mat-accordion>

					<mat-accordion class="border border-light p-0 mt-2" *ngIf="userData && userData.expected">
						<mat-expansion-panel [expanded]="true">
							<mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
								fxLayoutAlign="space-between center">
								<mat-panel-title class="mb-0 text-white font-weight-bold">Expected Details
								</mat-panel-title>
							</mat-expansion-panel-header>
							<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
								<div fxFlex="100" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="start"
									fxLayoutAlign="space-between center" *ngIf="expectedList">
									<div fxFlex.xs="100" fxFlex="50" class="pt-2 pb-2" *ngFor="let item of expectedList | keyvalue">
										<mat-label fxFlex="45" fxFlex.xs="100" fxLayoutAlign="start baseline"> <span>{{item.value[0] | labelTranslate}}: </span></mat-label>
										<mat-label *ngIf="item.value[1]?.value1" fxFlex="55" fxFlex.xs="100" class="font-weight-bold" [class]="item.value[1].value1.includes(',')?' text-break ':''">
											<!-- {{item.value[1].value1.includes(',')?item.value[1].value1.trim().split(',').join(', '):item.value[1].value1}}  -->
											{{calcValue(item, true)}}
										</mat-label>
										<mat-label *ngIf="!item.value[1]?.value1" fxFlex="55" fxFlex.xs="100" class="font-weight-bold">-</mat-label>
									</div>
								</div>
							</mat-card-content>
						</mat-expansion-panel>
					</mat-accordion>
				</mat-card-content>
			</mat-card-content>
		</mat-card>
	</section>
</div>