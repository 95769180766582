import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ServiceRequestAdminService } from '@londonhydro/customerservice-api';
import { ExportToCsv } from 'export-to-csv';
import * as _ from 'underscore';

@Component({
  selector: 'app-service-request',
  templateUrl: './service-request.component.html',
  styleUrls: ['./service-request.component.scss']
})
export class ServiceRequestComponent implements OnInit, AfterViewInit {

  showPageLoader = false;
  @ViewChild('formsPaginator') formsPaginator: MatPaginator;
  formsFilterForm: FormGroup;
  allForms = null;
  formListDS: MatTableDataSource<any> = null;
  displayedColumns: string[];
  @ViewChild('manageFormsTableSort') sort: MatSort;

  formAttributeConfig: any[] = [
    { name: 'displayName', label: 'Form Name', type: 'displayName', class: 'formsDisplayName' },
    { name: 'groupName', label: 'Group Name', type: 'groupName', class: 'formsGroupName' },
    { name: 'category', label: 'Category', type: 'string', class: 'formsCategory' },
    { name: 'subCategory', label: 'Sub Category', type: 'string', class: 'formsSubCategory' },
    { name: 'enable', label: 'Enable', type: 'string', class: 'formsEnable' },
    { name: 'groupAccess', label: 'Group Access', type: 'string', class: 'formsGroupAccess' },
    { name: 'emailTemplate', label: 'Email Template', type: 'emailTemplate', class: 'formsEmailTemplate' }
  ];

  constructor(private formBuilder: FormBuilder, private srAdminService: ServiceRequestAdminService, private datePipe: DatePipe) {
    this.formsFilterForm = this.formBuilder.group({
      enable: ['true'],
      groupName: [''],
      category: [''],
      name: ['']
    });
  }

  ngOnInit(): void {
    this.showPageLoader = true;
    this.formListDS = new MatTableDataSource<any>([]);
    this.displayedColumns = _.union(['index'], _.pluck(this.formAttributeConfig, 'name'));
    this.getCustomerServiceMasterData();
  }

  ngAfterViewInit(): void {
    this.formListDS.sort = this.sort;
  }

  getCustomerServiceMasterData(): void {
    this.srAdminService.getAllServiceRequests().subscribe(
      (res: any) => {
        _.each(res, (element, index) => {
          element.rowIndex = (index + 1);
          if (element['groupAccess']) {
            element['groupAccess'] = element['groupAccess'].trim().split(',').join(', ');
          }
        });
        this.allForms = res;
        this.formListDS.data = this.allForms;
        setTimeout(() => this.formListDS.paginator = this.formsPaginator);
        this.showPageLoader = false;
      },
      (error: any) => {
        this.showPageLoader = false;
        console.log('error getCustomerServiceMasterData()', error);
      }
    );
  }

  applyFilter(filterValue: string): any {
    this.formListDS.filter = filterValue.trim().toLowerCase();
    this.formListDS.filteredData.forEach(
      (res: any, count) => {
        res.rowIndex = count + 1;
      });
    if (this.formListDS.paginator) {
      this.formListDS.paginator.firstPage();
    }
  }

  exportToCSV(): void {
    const tableDataCSV = [];

    this.formListDS.filteredData.forEach((element) => {
      const configHeader = this.formAttributeConfig;
      const tempObj: any = {};
      for (const attribute of configHeader) {
        let val = element[attribute.name];
        const key = attribute.label;
        tempObj[key] = val ? val : '';

      }
      tableDataCSV.push(tempObj);

    });
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: 'Manage_Forms_' + this.datePipe.transform(new Date(), 'shortDate').split('/').join('.')

    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(tableDataCSV);
  }
}
