import { ContractRate, ContractRates } from './contractrate';
import { Services } from './services';
import * as _ from 'underscore';
import { Supplier } from './supplier';

export class Contract {
  id: string;
  accountId: string;
  service_id: string;
  effDate: any;
  endDate: any;
  supplier: Supplier;
  contractRate: ContractRate;
  accountNumber: string;
  contractEffDate?: Date;
  contractEndDate?: Date;
  utilityService?: Services;
  contractRates?: ContractRate[];

  isActive?(): boolean {
    return this.contractEndDate >= new Date();
  }

  // used to check Residential account or not
  public static checkResidentialContract(contracts): boolean {
    let residentialAccountFlag = false;
    const electricityObj = _.find(contracts, function (contract, ind) {
      return contract.utilityService && contract.utilityService.serviceType === 'E';
    });
    const hasElectricityService = typeof electricityObj !== 'undefined' ? true : false;
    _.each(contracts, function (contract, ind) {
      if (contract.contractRates) {
        _.each(contract.contractRates, function (contractRate, index) {
          if (contractRate.distributionRate) {
            if ((hasElectricityService && ((contractRate.distributionRate === 'ERES_DIST')
              || (contractRate.distributionRate === 'ERES_RGEN')))) {
              residentialAccountFlag = true;
            } else if (!hasElectricityService && contractRate.distributionRate === 'W&S_RESI') {
              residentialAccountFlag = true;
            }
          }
        });
      }
    });
    return residentialAccountFlag;
  }
  // used to check Residential account or not for TOU-TIER
  public static checkResidentialTOUTIER(contract): boolean {
    let residentialAccountFlag = false;
    if (contract.contractRates) {
      _.each(contract.contractRates, function (contractRate, index) {
        if (contractRate.distributionRate) {
          if (((contractRate.distributionRate === 'ERES_DIST')
            || (contractRate.distributionRate === 'ERES_RGEN'))) {
            residentialAccountFlag = true;
          }
        }
      });
    }
    return residentialAccountFlag;
  }

  // used to check Residential account having TOU rate
  public static checkTOURate(contract): boolean {
    let TOUFlag = false;
    if (contract.contractRates && contract.utilityService.servicePoint && contract.utilityService.servicePoint.meter) {
      _.each(contract.contractRates, function (contractRate, index) {
        if (contractRate.rateType && contractRate.supplyRate) {
          const meterType = contract.utilityService.servicePoint.meter.meterType;
          if (_.contains(ContractRates.RESI_RATES_TOU_TIER, contractRate.supplyRate) &&
            meterType === 'SMART' && new Date(contractRate.contractRateEndDate) >= new Date() &&
            new Date(contractRate.contractRateEffDate) <= new Date() &&
            (contractRate.rateType === 'TOU')) {
            TOUFlag = true;
          }
        }
      });
    }
    return TOUFlag;
  }
  public static checkTIERRate(contract): boolean {
    let TIERFlag = false;
    if (contract.contractRates && contract.utilityService.servicePoint && contract.utilityService.servicePoint.meter) {
      const meterType = contract.utilityService.servicePoint.meter.meterType;
      _.each(contract.contractRates, function (contractRate, index) {
        if (contractRate.rateType && contractRate.supplyRate) {
          if (_.contains(ContractRates.RESI_RATES_TOU_TIER, contractRate.supplyRate) &&
            meterType === 'SMART' && new Date(contractRate.contractRateEndDate) >= new Date() &&
            new Date(contractRate.contractRateEffDate) <= new Date() &&
            (contractRate.rateType === 'NON_TOU')) {
            TIERFlag = true;
          }
        }
      });
    }
    return TIERFlag;
  }

  // used to check Generation rate categories present  or not
  public static hasGenerationRateCategories(contracts: Contract[], rateCategories: string[] = null): boolean {
    let hasGenerationRate = false;
    if (rateCategories === null) {
      rateCategories = ContractRates.RATES_FOR_ENERGY_GENERATION;
    }
    _.each(contracts, function (contract: Contract) {
      if (contract.contractRates) {
        _.each(contract.contractRates, function (contractRate: ContractRate) {
          if (_.contains(rateCategories, contractRate.distributionRate)) {
            hasGenerationRate = true;
            return hasGenerationRate;
          } else {
            hasGenerationRate = false;
          }
        });
      }
    });
    return hasGenerationRate;
  }
}
