import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as _ from 'underscore';
import * as moment from 'moment-timezone';
import { UIUtil } from '@londonhydro/ux-lib';

import { Account } from '../../../backend/csr/model/account';
import { Contract } from '../../../backend/csr/model/contract';
import { AbstractService } from '../../../framework/service/abstract.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends AbstractService {

  private userAccountList: Account[];
  private accountCache: Map<string, Account>;

  constructor(@Inject('_CSR_MODULE_CONFIG_') _config: any, private http: HttpClient) {
    super(_config);
    this.resetCache();
  }

  resetCache(): void {
    this.accountCache = new Map<string, Account>();
  }

  hasUserAccountsLoaded(): boolean {
    return this.userAccountList && !_.isEmpty(this.userAccountList);
  }

  hasAccountData(accountId: string): boolean {
    return this.accountCache.has(accountId);
  }

  updateAccountCache(account: Account): void {
    this.accountCache.set(account.id, account);
  }

  getUserAccounts(): Observable<Account[]> {
    const accountsApiEndPoint = `/assets/resources/mockdb/accounts.json`;
    const _this = this;
    return this.http.get<Account[]>(accountsApiEndPoint)
      .pipe(map(accList => (_this.toAccountList(accList))),
        tap(accountList => { console.log('fetched accountList', accountList); }),
        catchError(this.handleError('accountList', null))
      );
  }

  getAccountDetails(accountId: string): Observable<Account> {
    if (this.accountCache && this.accountCache.has(accountId)) {
      return of(this.accountCache.get(accountId));
    } else {
      const accountApiEndPoint = `/assets/resources/mockdb/account.json`;
      const _this = this;
      return this.http.get<Account>(accountApiEndPoint)
        .pipe(map(acc => (_this.toAccount(acc))),
          tap(account => {
            console.log('fetched account', account);
          }),
          catchError(this.handleError('account', null))
        );
    }
  }

  private toAccountList(accList: Array<any>): Account[] {
    const _this = this;
    return _.map(accList, (account: any) => _this.toAccount(account));
  }

  private toAccount(acc: any): Account {
    const account: Account = _.extend(new Account(), acc);
    if (!_.isEmpty(account.contracts)) {
      const _contracts = new Array<Contract>();
      _.each(account.contracts, (c: Contract) => {
        const _contract: Contract = _.extend(new Contract(), c);
        _contract.contractEffDate = moment.tz(c.contractEffDate, UIUtil.UiTimeZone).toDate();
        _contract.contractEndDate = moment.tz(c.contractEndDate, UIUtil.UiTimeZone).toDate();
        _contracts.push(_contract);
      });
      account.contracts = _contracts;
    }
    account.processServices();
    console.log('Active Account:::', account);
    return account;
  }
}