<div fxLayout="column" fxLayoutAlign="start stretch">

	<div fxLayout="row wrap" fxLayoutAlign="space-between top" class="my-2">
		<div fxFlex="100" class="border-bottom border-red border-medium mb-2 p-2"><span class="panel-title">My Worklist</span></div>
		<app-widget-card fxFlex="24" [title]="'My Queue'" [subTitle]="'Active request count in My Queue'" class="mb-3">
			<div fxFill fxLayout="row" fxLayoutAlign="center center">
				<button class="btn btn-link dashboard-badge">10</button>
			</div>
		</app-widget-card>

		<app-widget-card fxFlex="24" [title]="'Pending Requests'" [subTitle]="'Requests pending in My Queue'" class="mb-3">
			<div fxFill fxLayout="row" fxLayoutAlign="center center">
			<button class="btn btn-link dashboard-badge">4</button>
			</div>
		</app-widget-card>

		<app-widget-card fxFlex="50" [title]="'My Priority Items'" [subTitle]="'Top 5 pending requests in My Queue'" class="mb-3">
			<p>Table showing top 5 requests pending for long</p>
		</app-widget-card>
	</div>
	


	<div fxLayout="row wrap" fxLayoutAlign="space-between top" class="my-2">
		<div fxFlex="100" class="border-bottom border-red border-medium mb-2 p-2"><span class="panel-title">All Service Requests</span></div>
		<app-widget-card fxFlex="24" [title]="'Active Customer Now'" [subTitle]="'Customers filling the form currently'" class="mb-3">
			<div fxFill fxLayout="row" fxLayoutAlign="center center">
			<button class="btn btn-link dashboard-badge">124</button>
			</div>
		</app-widget-card>

		<app-widget-card fxFlex="24" [title]="'Statictics'" [subTitle]="'Submitted on last 7 days'" class="mb-3">
			<div class="chart-containerX" fxFlex="100 ">
				<div class="stat-chart" [chart]="statChart"></div>
			</div>
		</app-widget-card>

		<app-widget-card fxFlex="50" [title]="'Priority Items'" [subTitle]="'Top 5 pending requests'" class="mb-3">
			<p>Table showing top 5 requests pending for long</p>
		</app-widget-card>
	</div>
</div>
