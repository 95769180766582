import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { AbstractComponent } from '@londonhydro/ux-lib';

@Component({
  selector: 'app-user-profile-view',
  templateUrl: './user-profile-view.component.html',
  styleUrls: ['./user-profile-view.component.scss']
})
export class UserProfileViewComponent extends AbstractComponent implements OnInit, OnDestroy {

  constructor(injector: Injector) {
    super(injector);
  }

  userSessionLoaded(): void {
    console.log('csr logged user data:::::', this.user);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
  ngOnDestroy(): void {
    console.log('On child component class::::ngOnDestroy');
    super.ngOnDestroy();
  }
}
