<div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="showPageLoader" id="loaderdiv">
	<mat-spinner></mat-spinner>
</div>
<div fxLayout="column" *ngIf="formListDS">

	<div fxFlex="100" fxLayout="column" fxLayoutAlign="space-around stretch" class="p-2">
		<form [formGroup]="formsFilterForm" (ngSubmit)="getFilteredPendingAndProcessedUpdates(true)" (reset)="onResetFormSubmit()" 
		*ngIf="formsFilterForm && advSearchOn">
			<div fxLayout="row wrap" fxLayoutAlign="space-around center" class="p-2">
				<div fxLayout="column" fxFlex="24.5" fxFlex.lt-sm="80">
					<mat-form-field appearance="outline" data-toggle="tooltip" title="Search By User Name">
						<mat-label>Customer ID:</mat-label>
						<input matInput type="text" placeholder="" formControlName="customerId">
					</mat-form-field>
				</div>
				<div fxFlex="1" fxShow.lt-sm="false"></div>
				<div fxLayout="column" fxFlex="24.5" fxFlex.lt-sm="80">
					<mat-form-field appearance="outline" data-toggle="tooltip" title="Search By Email ID">
						<mat-label>Account ID:</mat-label>
						<input matInput type="text" placeholder="" formControlName="accountId">
					</mat-form-field>
				</div>
				<div fxFlex="1" fxShow.lt-sm="false"></div>
				<div fxLayout="column" fxFlex="49" fxFlex.lt-sm="80">Requested Date:
					<div fxLayout="row" fxFlex="100">
						<div fxLayout="column" fxFlex="48" class="mb-4">
							<mat-form-field appearance="fill" data-toggle="tooltip" title="Search From Requested Date">
								<mat-label>From:</mat-label>
								<input matInput [matDatepicker]="pickerFromDate"  
								 formControlName="startDate"
									(dateChange)="dateChangeHandler('startDate')">
								<!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
								<mat-datepicker-toggle matSuffix [for]="pickerFromDate"></mat-datepicker-toggle>
								<mat-datepicker #pickerFromDate></mat-datepicker>
							</mat-form-field>
							<!-- <mat-form-field appearance="outline">
								<input matInput type="text" placeholder="" formControlName="requestedOnAfter">
							</mat-form-field> -->
						</div>
						<div fxFlex="2"></div>
						<div fxLayout="column" fxFlex="48">
							<mat-form-field appearance="fill" data-toggle="tooltip" title="Search upto Requested Date">
								<mat-label>To:</mat-label>
								<input matInput [matDatepicker]="pickerToDate" formControlName="endDate"
									(dateChange)="dateChangeHandler('endDate')">
								<mat-datepicker-toggle matSuffix [for]="pickerToDate"></mat-datepicker-toggle>
								<mat-datepicker #pickerToDate></mat-datepicker>
							</mat-form-field>
						</div>
					</div>
				</div>
			</div>
			<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" class="mb-4">
				<button class="btn btn-sm ux-icon-button" type="submit"
					[disabled]="formsFilterForm.errors?.dateInvalid || formsFilterForm.invalid">
					Search
				</button>
				<button class="btn btn-sm ux-icon-button" type="reset">
					Reset
				</button>
			</div>
		</form>
		<div fxLayout="column" *ngIf="formListDS">
			<div fxFlex="100" fxLayout="column" fxLayoutAlign="space-around stretch" class="p-2">
				<div fxLayout="row" fxLayout.lt-sm="column" [ngClass.lt-sm]="'mb-4'" fxFlex="100"
					fxLayoutAlign="end center" fxLayoutGap="15px" *ngIf="formListDS" class="mb-2">
					<mat-form-field>
						<mat-label>Quick search</mat-label>
						<input matInput class="form-field" #quickSearchInput
							(keyup)="applyFilter(quickSearchInput.value)">
						<button matSuffix mat-icon-button aria-label="Clear"
							(click)="applyFilter(''); quickSearchInput.value=''">
							<mat-icon matSuffix>close</mat-icon>
						</button>
					</mat-form-field>
					<button type="button" class="btn btn-sm ux-icon-button" title="Toggle Advanced Search Form" sty
						(click)="toggleAdvSearch()" fxLayout="row" fxLayoutAlign="start center">
						<mat-icon data-toggle="tooltip" data-placement="bottom" title="Toggle Advanced Search Form"
							class="mr-2">
							manage_search</mat-icon>
						<span>{{advSearchOn ?'Hide' : 'Show'}} Advanced Search</span>
					</button>
					<button type="button" class="btn btn-sm ux-icon-button" fxLayout="row" fxLayoutAlign="start center"
						(click)="getCustomerCISUpdates(false, false)" [ngClass]="{'selected-button' : cisUpdateType === 'PENDING'}">
						<span>Pending CIS Updates</span>
					</button>
					<button type="button" class="btn btn-sm ux-icon-button" fxLayout="row" fxLayoutAlign="start center"
						(click)="getCustomerCISUpdates(false, true)"
						[ngClass]="{'selected-button' : cisUpdateType === 'PROCESSED'}">
						<span>Processed CIS Updates</span>
					</button>
					<button type="button" class="btn btn-sm ux-icon-button" title="Refresh" (click)="getFilteredPendingAndProcessedUpdates(false)"
						fxLayout="row" fxLayoutAlign="start center">
						<mat-icon data-toggle="tooltip" data-placement="bottom" title="Refresh" class="mr-2">refresh
						</mat-icon><span>Refresh</span>
					</button>

					<button type="button" class="btn btn-sm ux-icon-button" title="Export Data in CSV format"
						(click)="exportToCSV()" fxLayout="row" fxLayoutAlign="start center">
						<mat-icon data-toggle="tooltip" data-placement="bottom" title="Export Data" class="mr-2">
							file_download
						</mat-icon><span>Export Data</span>
					</button>

				</div>

				<div>
					<mat-table [dataSource]="formListDS" class="live-data-container mat-elevation-z8" matSort
						[ngClass.lt-md]="'mat-table-responsive'" [ngClass.lt-sm]="'mat-table-responsive'"
						#serviceReqTableSort="matSort" multiTemplateDataRows>
						<ng-container matColumnDef="index">
							<mat-header-cell *matHeaderCellDef class="sl" [ngClass.lt-md]="'mat-cell-responsive'"
								mat-sort-header><span>#</span>
							</mat-header-cell>

							<mat-cell *matCellDef="let element; let i = index" class="sl"
								[ngClass.lt-md]="'mat-cell-responsive'"
								[ngClass.lt-sm]="'mat-cell-responsive-sm padding-left-md'" fxFlex.lt-sm="100"> <span
									class="d-none" fxFlex.lt-sm="35"
									[ngClass.lt-sm]="'mobile-label-display'">Sl:</span><span fxFlex.lt-sm="65">
									{{element.rowIndex}}</span> </mat-cell>
						</ng-container>

						<ng-container *ngFor="let attribute of formAttributeConfig; index as i"
							matColumnDef="{{attribute.name}}">
							<mat-header-cell *matHeaderCellDef class="pl-1" [class]="attribute.class"
								[ngClass.lt-md]="'mat-cell-responsive'" [ngClass.lt-sm]="'d-none'" mat-sort-header
								[disabled]="attribute.type==='document'">
								<div>{{attribute.label}}</div>
							</mat-header-cell>
							<mat-cell class="matCell pl-1 " [class]="attribute.class" *matCellDef="let element;"
								[ngClass.lt-sm]="'mat-cell-responsive-sm'" [ngClass.lt-md]="'mat-cell-responsive'">

								<span *ngIf="attribute.type==='string' || attribute.type==='number'" fxFlex.lt-sm="100">
									<span [ngClass.lt-sm]="'d-flex'" fxFlex.lt-sm="100"><span class="d-none"
											[ngClass.lt-sm]="'mobile-label-display'"
											fxFlex.lt-sm="35">{{attribute.label}}:</span>
										<span fxFlex.lt-sm="65">{{element[attribute.name] || '-'}} </span></span>

								</span>
								<span *ngIf="attribute.type==='tableName'" fxFlex.lt-sm="100">
									<span [ngClass.lt-sm]="'d-flex'" fxFlex.lt-sm="100"><span class="d-none"
											[ngClass.lt-sm]="'mobile-label-display'"
											fxFlex.lt-sm="35">{{attribute.label}}:</span>
										<span fxFlex.lt-sm="65">
											{{(element.table | titlecase) || '-'}}
										</span>
									</span>
								</span>

								<span *ngIf="attribute.type==='date'" fxFlex.lt-sm="100">
									<span [ngClass.lt-sm]="'d-flex'" fxFlex.lt-sm="100"><span class="d-none"
											fxFlex.lt-sm="35"
											[ngClass.lt-sm]="'mobile-label-display'">{{attribute.label}}:</span>
										<span
											fxFlex.lt-sm="65">{{(element[attribute.name] | date: 'short' : uiTimezone) || '-'}}
										</span></span>
								</span>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="expandedDetail">
							<mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
								<div *ngIf="element?.rowExpanded" [@detailExpand]="'expanded'" fxFlex="100">
									<mat-table [dataSource]="extendedTableListDS[element.rowIndex-1]"
										class="p-3 live-data-container mat-elevation-z8"
										[ngClass.lt-md]="'mat-table-responsive'"
										[ngClass.lt-sm]="'mat-table-responsive'">

										<ng-container matColumnDef="index">
											<mat-header-cell *matHeaderCellDef class="sl"
												[ngClass.lt-md]="'mat-cell-responsive'"><span>#</span>
											</mat-header-cell>

											<mat-cell *matCellDef="let element; let i = index" class="sl"
												[ngClass.lt-md]="'mat-cell-responsive'"
												[ngClass.lt-sm]="'mat-cell-responsive-sm padding-left-md'"
												fxFlex.lt-sm="100">
												<span class="d-none" fxFlex.lt-sm="35"
													[ngClass.lt-sm]="'mobile-label-display'">Sl:</span><span
													fxFlex.lt-sm="65">
													{{i+1}}</span> </mat-cell>
										</ng-container>

										<ng-container *ngFor="let attribute of extendedFormAttributeConfig; index as i"
											matColumnDef="{{attribute.name}}">
											<mat-header-cell *matHeaderCellDef class="pl-1" [class]="attribute.class"
												[ngClass.lt-md]="'mat-cell-responsive'" [ngClass.lt-sm]="'d-none'"
												[disabled]="attribute.type==='document'">
												<div>{{attribute.label}}</div>
											</mat-header-cell>

											<mat-cell *matCellDef="let element" class="matCell pl-1"
												[class]="attribute.class"
												[attr.colspan]="extendedTableDisplayedColumns.length"
												[ngClass.lt-sm]="'mat-cell-responsive-sm'"
												[ngClass.lt-md]="'mat-cell-responsive'">
												<span *ngIf="attribute.type==='string' || attribute.type==='number'"
													fxFlex.lt-sm="100">

													<span [ngClass.lt-sm]="'d-flex'" fxFlex.lt-sm="100"><span
															class="d-none" [ngClass.lt-sm]="'mobile-label-display'"
															fxFlex.lt-sm="35">{{attribute.label}}:</span>
														<span fxFlex.lt-sm="65">{{element[attribute.name] || '-'}}
														</span></span>

												</span>
												<span *ngIf="attribute.type==='tableColumn'" fxFlex.lt-sm="100">

													<span [ngClass.lt-sm]="'d-flex'" fxFlex.lt-sm="100"><span
															class="d-none" [ngClass.lt-sm]="'mobile-label-display'"
															fxFlex.lt-sm="35">{{attribute.label}}:</span>
														<span
															fxFlex.lt-sm="65">{{(element[attribute.name].toString().trim().split('_').join(' ') | titlecase) || '-'}}
														</span></span>

												</span>
												<span *ngIf="attribute.type==='document'" fxFlex.lt-sm="100">
													<span [ngClass.lt-sm]="'d-flex'" fxFlex.lt-sm="100"><span
															class="d-none" [ngClass.lt-sm]="'mobile-label-display'"
															fxFlex.lt-sm="35">{{attribute.label}}:</span>
														<span fxFlex.lt-sm="65" *ngIf="element[attribute.name]"
															class="pointer">
															<mat-button
																(click)="downloadPdf('data:application/pdf;base64,'+ element[attribute.name])">
																<mat-icon>cloud_download</mat-icon>
															</mat-button>

														</span>
													</span>
												</span>
											</mat-cell>
										</ng-container>
										<tr class="mat-row d-flex" *matNoDataRow>
											<td class="mat-cell d-flex w-100 p-3 font-weight-bold"
												fxLayoutAlign="center"
												[attr.colspan]="extendedTableDisplayedColumns.length">
												No data matching the filter.
											</td>
										</tr>
										<mat-header-row [ngClass.lt-sm]="'d-none'"
											*matHeaderRowDef="extendedTableDisplayedColumns;sticky: true;"
											class="matHeaderRow child-header-row">
										</mat-header-row>
										<mat-row *matRowDef="let row; columns: extendedTableDisplayedColumns;"
											fxLayout="row" fxLayout.xs="column" class="child-row"
											[ngClass.lt-md]="'responsive-mat-row'"
											[ngClass.lt-sm]="'responsive-mat-row-small'"></mat-row>

									</mat-table>
								</div>
							</mat-cell>
						</ng-container>
						<tr class="mat-row d-flex" *matNoDataRow>
							<td class="mat-cell d-flex w-100 p-3 font-weight-bold" fxLayoutAlign="center"
								[attr.colspan]="displayedColumns.length">
								No data matching the filter.
							</td>
						</tr>
						<mat-header-row [ngClass.lt-sm]="'d-none'" *matHeaderRowDef="displayedColumns; sticky: true;"
							class="matHeaderRow"></mat-header-row>
						<mat-row *matRowDef="let row; columns: displayedColumns; let i = dataIndex;"
							(click)="toggleRowExpansion(row.rowIndex)" fxLayout="row" fxLayout.xs="column"
							class="matHeaderRow pointer" [ngClass.lt-md]="'responsive-mat-row'"
							[ngClass.lt-sm]="'responsive-mat-row-small'"></mat-row>

						<mat-row class="expanded-row" *matRowDef="let row; columns: ['expandedDetail']"
							[class]="row.rowExpanded===true?'d-block':'d-none'"></mat-row>
					</mat-table>
					
				</div>
				<div fxLayout="row" fxLayout.lt-sm="column-reverse" class="table-footer">
					<div fxFlex="40" fxFlex.lt-sm="100" fxLayoutAlign="start center" class="table-disclaimers" *ngIf="!advSearchOn">
						<span class="font-color" *ngIf="cisUpdateType == 'PENDING'" fxLayoutAlign="start end">
							<em>** Please Note: Data is displayed for the last 3 months</em>
						</span>
						<div class="font-color" *ngIf="cisUpdateType == 'PROCESSED'" fxLayoutAlign="start end">
							<em>** Please Note: Data is displayed for the last 2 days</em>
						</div>	
					</div>
					<div fxFlex="grow" fxFlex.lt-sm="100" fxLayoutAlign="end none">
						<mat-paginator #formsPaginator="matPaginator" *ngIf="formListDS" [pageSizeOptions]="[10, 25, 50]"
						showFirstLastButtons [pageSize]="10" aria-label="Select page">
						</mat-paginator>
					</div>
				</div>
								
				
			</div>

		</div>