<div id="modal-content-wrapper">
	<section id="modal-body">

		<mat-card class="ux-card border border-light p-0">
			<mat-card-header class="p-2" fxLayout="row" fxLayoutAlign="space-between center">
				<mat-card-title>{{isShellAccount ? 'Delete Shell Account' : 'Close Online Account'}}</mat-card-title>
				<button (click)="closeModal()" class="btn btn-link " type="button" id="dismiss" aria-label="Close">
					<mat-icon class="material-symbols-outlined">close</mat-icon>
				</button>
			</mat-card-header>
			<mat-card-content class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch"
				*ngIf="user && closeOnlineAccountForm; else noDataBlock">
				<div fxFlex="100">
					<div class="m-1">
						<form [formGroup]="closeOnlineAccountForm">
							<div>
								<label for="reason"><strong>Reason for
										{{isShellAccount ? 'deleting shell account' : 'closing online account'}}*</strong>
								</label>
								<div>
									<textarea id="reason" formControlName="reason" maxlength="250"></textarea>
									<div *ngIf="closeOnlineAccountForm.controls['reason'].dirty || closeOnlineAccountForm.controls['reason'].touched"
										class="text-danger">
										<div *ngIf="closeOnlineAccountForm.controls['reason'].errors?.required || closeOnlineAccountForm.controls['reason'].hasError('whitespace')">
											Reason is required.
										</div>
									</div>
								</div>
								<div *ngIf="!isShellAccount" class="disclaimer">
									<span>Please note that for accounts subscribed to Paperless billing, after the deactivation the account will be set back to Paper Bills.</span>
								</div>
								<label for="check"><strong>I want to
										{{isShellAccount ? 'delete this shell' : 'close this online'}}
										account*</strong></label>
								<input type="checkbox" id="check" formControlName="check" class="checkbox3 mt-3 ml-2"
									tabindex="0" required>

							</div>
						</form>
					</div>
				</div>
			</mat-card-content>
			<ng-template #noDataBlock>
				<div class="m-0 py-2" mat-dialog-content><strong>No Data Found.</strong></div>
			</ng-template>
			<div class="footer footer-buttons" fxLayoutGap="20px">
				<button class="btn btn-sm ux-icon-button" (click)="closeModal()">Cancel</button>
				<button (click)="closeOnlineAccount()" 
				[disabled]="closeOnlineAccountForm.controls['check'].invalid || closeOnlineAccountForm.controls['reason'].invalid || apiCallPending"
					class="btn btn-sm ux-icon-button" data-toggle="tooltip"
					title="Close Online Account">{{isShellAccount ? 'Delete' : 'Close'}} <span
						fxHide.xs>{{isShellAccount ? 'Shell' : 'Online'}}</span> Account</button>
			</div>
			<!-- [ngClass]="{ 'disabled': (!closeOnlineAccountForm.valid || submitted) }" -->

		</mat-card>
	</section>
</div>