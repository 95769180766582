<div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="showPageLoader" id="loaderdiv">
	<mat-spinner></mat-spinner>
</div>
<mat-card *ngIf="showContent">
	<mat-card-header class="bg-light p-2" fxLayout="row" fxLayoutAlign="space-between center">
		<mat-card-title>CSR Reports</mat-card-title>
	</mat-card-header>

	<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
		<div fxLayout="column" fxLayoutAlign="space-between center" class="p-3">
			<ul>
				<li class="p-2 report-link" (click)="downloadCISUpdates()">
					<span>Download Pending CIS Updates (CSV Format)</span></li>
			</ul>

			<ul>
				<li class="p-2 report-link" (click)="downloadPaymentNotification()">
					<span>Download Payment Notifications (CSV Format)</span></li>
			</ul>
		</div>
	</mat-card-content>
</mat-card>
