<div id="modal-content-wrapper">
	<section id="modal-body">
		<mat-card class="ux-card border border-light p-0">
			<mat-card-header class="p-2" fxLayout="row" fxLayoutAlign="space-between center">
				<mat-card-title>Service Request Details</mat-card-title>
				<button (click)="closeModal()" class="btn btn-link text-secondary" type="button" id="dismiss"
					aria-label="Close">
					<mat-icon class="material-symbols-outlined">close</mat-icon>
				</button>
			</mat-card-header>

			<mat-card-content class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch">
				<mat-accordion class="border border-light p-0">
					<mat-expansion-panel [expanded]="true">
						<mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
							fxLayoutAlign="space-between center">
							<mat-panel-title class="mb-0 text-white font-weight-bold">Request Details</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
							<div fxFlex="50" fxFlex.lt-sm="100" fxLayout="row" fxLayoutAlign="space-between center"
								*ngFor="let attribute of (formAttributeConfig | listFilter: 'group': 'request')">
								<div fxFlex="40" fxFlex.lt-sm="40" [fxFlex.lt-md]="25" class="p-2">{{attribute.label}}:
								</div>
								<div fxFlex="60" fxFlex.lt-sm="60" [fxFlex.lt-md]="75"
									class="p-2 font-weight-bold text-break"
									[ngClass]="(attribute.class || '') + ' ' + (attribute.dataClass? formDetails[attribute.dataValue] : '')">
									<span
										*ngIf="attribute.type === 'string' && !attribute.translate">{{formDetails.getDeepData(attribute.name) || (attribute.default || '-')}}</span>
									<span
										*ngIf="attribute.type === 'string' && attribute.translate">{{formDetails.getDeepData(attribute.name) | labelTranslate}}
									</span>
									<span
										*ngIf="attribute.type === 'formDisplayName' && !isFormOriginMyaccount">{{formDetails.getDeepData(attribute.name)}}
									</span>
									<span
										*ngIf="attribute.type === 'formDisplayName' && isFormOriginMyaccount">
										<span>{{attribute.exceptionalFormToTranslate === formDetails.getDeepData('form.name')?(formDetails.getDeepData('form.name') | labelTranslate) : formDetails.getDeepData(attribute.name)}}</span>
									</span>
									<span
										*ngIf="attribute.type === 'number'">{{formDetails.getDeepData(attribute.name) | number: '1.0-2'}}</span>
									<span
										*ngIf="attribute.type === 'date'">{{formDetails.getDeepData(attribute.name) ? (formDetails.getDeepData(attribute.name) | date: 'short' : uiTimezone ) : (attribute.default || '-')}}</span>
									<span
										*ngIf="attribute.type === 'shortDate'">{{formDetails.getDeepData(attribute.name) ? (formDetails.getDeepData(attribute.name) | date: 'shortDate' : uiTimezone ) : (attribute.default || '-')}}</span>	
									<span *ngIf="attribute.type === 'hidden'"
										class="d-none">{{formDetails.getDeepData(attribute.name)}}</span>
									<span
										*ngIf="attribute.type === 'assignedUser'">{{formDetails.getDeepData(attribute.name) && formDetails.getDeepData('assignedTo')?formDetails.getDeepData(attribute.name):(formDetails.getDeepData('assignedUser.userName') && formDetails.getDeepData('assignedTo')?formDetails.getDeepData('assignedUser.userName'):attribute.default)}}</span>
								</div>
							</div>
						</mat-card-content>
					</mat-expansion-panel>
				</mat-accordion>

				<mat-accordion class="border border-light p-0 mt-2">
					<mat-expansion-panel [expanded]="true">
						<mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
							fxLayoutAlign="space-between center">
							<mat-panel-title class="mb-0 text-white font-weight-bold">User/Customer Details</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
							<div [fxFlex]="attribute.width?attribute.width:50" [fxFlex.lt-sm]="100" fxLayout="row"
								fxLayoutAlign="space-between center"
								*ngFor="let attribute of (formAttributeConfig | listFilter: 'group': 'form')"
								[fxHide]="!formDetails.getDeepData(attribute.name)">
								<div [fxFlex]="attribute.width==100?15:40" [fxFlex.lt-sm]="40" [fxFlex.lt-md]="25"
									class="p-2">{{attribute.label}}:
								</div>
								<div [fxFlex]="attribute.width==100?85:60" [fxFlex.lt-sm]="60" [fxFlex.lt-md]="75"
									class="p-2 font-weight-bold text-break">
									<span
										*ngIf="attribute.type === 'string'">{{formDetails.getDeepData(attribute.name) || (attribute.default || '-')}}</span>
									<span
										*ngIf="attribute.type === 'number'">{{masterForm[attribute.name] | number: '1.0-2'}}</span>
									<span
										*ngIf="attribute.type === 'date'">{{masterForm[attribute.name] | date: 'short' : uiTimezone }}</span>
									<span
										*ngIf="attribute.type === 'shortDate'">{{masterForm[attribute.name] | date: 'shortDate' : uiTimezone }}</span>	
									<span *ngIf="attribute.type === 'hidden'"
										class="d-none">{{masterForm[attribute.name]}}</span>

								</div>

							</div>
						</mat-card-content>
					</mat-expansion-panel>
				</mat-accordion>

				<mat-accordion class="border border-light p-0 mt-2" *ngIf="isAdditionalSectionRequired()">
					<mat-expansion-panel [expanded]="true">
						<mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
							fxLayoutAlign="space-between center">
							<mat-panel-title class="mb-0 text-white font-weight-bold">Additional Details
							</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-card-content fxLayout="row wrap" class="mb-0" fxLayoutAlign="space-between center">
							<div fxLayout="row"
								*ngFor="let attribute of (formAttributeConfig | listFilter: 'group': 'additional')"
								[fxFlex]="formDetails.getDeepData(attribute.name) && attribute.type != 'bankingInfo'?50:90" [fxHide]="!formDetails.getDeepData(attribute.name)" fxFlex.xs="100">
								<app-additional-details [formDetails]="formDetails" [attribute]="attribute" *ngIf="formDetails.getDeepData(attribute.name) " fxLayout="row" fxFlex="100"
									fxLayoutAlign="space-between center"></app-additional-details>
							</div>
						</mat-card-content>
					</mat-expansion-panel>
				</mat-accordion>

				<mat-accordion class="border border-light p-0 mt-2" *ngIf="isPaymentTermsSectionRequired()">
					<mat-expansion-panel [expanded]="true">
						<mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
							fxLayoutAlign="space-between center">
							<mat-panel-title class="mb-0 text-white font-weight-bold">Payment Terms
							</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-card-content fxLayout="row wrap" class="mb-0" fxLayoutAlign="space-between center">
							<div fxLayout="row" [class]=" attribute.class "
								*ngFor="let attribute of (formAttributeConfig | listFilter: 'group': 'paymentTerms')"
								[fxFlex]="attribute.type === 'array'?100:50" [fxHide]="!formDetails.getDeepData(attribute.name)" fxFlex.xs="100">
								<div fxLayout="row" fxFlex="100"
									fxLayoutAlign="space-between center">
									<div fxFlex="35" *ngIf="attribute.type !== 'array'" fxFlex.lt-sm="40" [fxFlex.lt-md]="25" class="p-2">
										{{attribute.label}}:</div>
									<div fxFlex="65" *ngIf="attribute.type !== 'array'"  fxFlex.lt-sm="60" [fxFlex.lt-md]="75"
										class="p-2 font-weight-bold text-break"
										[ngClass]="(attribute.class || '') + ' ' + (attribute.dataClass? formDetails[attribute.dataValue] : '')">
										<span *ngIf="attribute.type === 'string' && !attribute.translate">{{formDetails.getDeepData(attribute.name) || (attribute.default || '-')}}</span>
										<span *ngIf="attribute.type === 'currency'">{{formDetails.getDeepData(attribute.name)  | currency:'USD':'symbol'}}</span>
		
									</div>
									<div fxFlex="100" *ngIf="attribute.type === 'array'"  
										class="text-break p-2"
										[ngClass]="(attribute.class || '') + ' ' + (attribute.dataClass? formDetails[attribute.dataValue] : '')">
										
										<div fxFlex="100"  fxLayout="column" *ngIf="attribute.type === 'array'" fxLayoutGap="10px">
												<div fxFlex="100" fxLayout="row" fxLayout.xs="column" [class]="i===formDetails['paymentInstallments'].length-1  && formDetails.getDeepData('totalAmount') ?' '+'border-btm'+' ':''" *ngFor="let item of formDetails['paymentInstallments'];index as i">
													<span [fxFlex]="33" fxFlex.xs="100" *ngFor="let subAttribute of attribute.subAttributeListConfig">
													<span fxFlex="60" fxFlex.xs="100" class="font-weight-light">{{subAttribute.label}} &nbsp;{{'#'+(i+1)}}:&nbsp;</span>
													<span fxFlex="40" fxFlex.xs="100" class="font-weight-bold">
														<span *ngIf="subAttribute.type==='currency'">{{item[subAttribute.name]  | currency:'USD':'symbol' || '-'}}</span>
														<span *ngIf="subAttribute.type==='string'">{{item[subAttribute.name] || '-'}}</span>
														<span *ngIf="subAttribute.type==='date'">{{item[subAttribute.name] | date: 'short' : uiTimezone || '-'}}</span>
														<span *ngIf="subAttribute.type==='shortDate'">{{item[subAttribute.name] | date: 'shortDate' : uiTimezone || '-'}}</span>
													</span>
													</span>
												</div>
										</div>
									</div>
								</div>
							</div>
						</mat-card-content>
					</mat-expansion-panel>
				</mat-accordion>

				<!-- <mat-accordion class="border border-light p-0 mt-2">
					<mat-expansion-panel [expanded]="true">
						<mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
							fxLayoutAlign="space-between center">
							<mat-panel-title class="mb-0 text-white font-weight-bold">User/Customer Details</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
							<div [fxFlex]="attribute.width?attribute.width:50" [fxFlex.lt-sm]="100" fxLayout="row"
								fxLayoutAlign="space-between center"
								*ngFor="let attribute of (formAttributeConfig | listFilter: 'group': 'form')"
								[fxHide]="!formDetails.getDeepData(attribute.name)">
								<div [fxFlex]="attribute.width==100?15:40" [fxFlex.lt-sm]="40" [fxFlex.lt-md]="25"
									class="p-2">{{attribute.label}}:
								</div>
								<div [fxFlex]="attribute.width==100?85:60" [fxFlex.lt-sm]="60" [fxFlex.lt-md]="75"
									class="p-2 font-weight-bold text-break">
									<span
										*ngIf="attribute.type === 'string'">{{formDetails.getDeepData(attribute.name) || (attribute.default || '-')}}</span>
									<span
										*ngIf="attribute.type === 'number'">{{masterForm[attribute.name] | number: '1.0-2'}}</span>
									<span
										*ngIf="attribute.type === 'date'">{{masterForm[attribute.name] | date: 'shortDate': uiTimezone}}</span>
									<span *ngIf="attribute.type === 'hidden'"
										class="d-none">{{masterForm[attribute.name]}}</span>

								</div>

							</div>
						</mat-card-content>
					</mat-expansion-panel>
				</mat-accordion> -->

				<mat-accordion class="border border-light p-0 mt-2">
					<mat-expansion-panel [expanded]="false">
						<mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
							fxLayoutAlign="space-between center">
							<mat-panel-title class="mb-0 text-white font-weight-bold">Uploaded Files</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-card-content class="mb-0">
							<div *ngIf="fileCategories && fileCategories.length > 0; else noFilesBlock"
								fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="25px" class="p-4">
								<div *ngFor="let category of fileCategories">
									<app-file-upload fxFlex="100" [uploadConfig]="fileViewerConfig[category]"
										[mode]="viewMode" [documentRef]="fileListWithCategory[category]">
									</app-file-upload>
								</div>
							</div>
							<ng-template #noFilesBlock>
								<div fxLayout="column" fxLayoutAlign="center start" class="p-4">
									<p class="font-weight-bold text-secondary">There are no files uploaded for the
										selected form.</p>
								</div>
							</ng-template>
						</mat-card-content>
					</mat-expansion-panel>
				</mat-accordion>

				<mat-accordion class="border border-light p-0 mt-2">
					<mat-expansion-panel [expanded]="false">
						<mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
							fxLayoutAlign="space-between center">
							<mat-panel-title class="mb-0 text-white font-weight-bold">Review Details</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-card-content fxLayout="row" fxLayoutAlign="space-between center" class="mb-0">
							<div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
								<div fxShow.lt-sm="false" fxShow="true" fxFlex="100" fxLayout="row"
									fxLayoutAlign="space-between center" class="border-bottom border-secondary">
									<div fxFlex="40" fxFlex.lt-md="30" class="p-2 font-weight-bold">
										<span class="mr-2">#</span><span>Review/Action</span>
									</div>
									<div fxFlex="25" fxFlex.lt-md="40" class="p-2 font-weight-bold">
										<span>By</span>
									</div>
									<div fxFlex="25" fxFlex.lt-md="30" class="p-2 font-weight-bold">
										<span>On</span>
									</div>
								</div>
								<div fxFlex="100" fxLayout="row wrap"
									*ngIf="auditReviews && auditReviews.length > 0; else noAuditBlock">
									<div fxFlex="100" fxLayout="row" fxLayout.lt-sm="column"
										fxLayoutAlign="space-between center"
										*ngFor="let review of auditReviews; index as i"
										[ngStyle.lt-sm]="'border-bottom:double;'">
										<div fxFlex="40" fxFlex.lt-sm="100" fxFlex.lt-md="30" class="p-2"
											[ngClass.lt-sm]="'mr-auto'">
											<span class="mr-2">
												<span class="font-weight-bold" fxShow.lt-sm="true" fxShow="false"><span
														class="mr-2">#</span>
													<span>Review/Action-</span></span>{{(i+1)}}.</span><span>{{review.comment}}.</span>
													<span *ngIf="review.systemMessage && review.systemMessage.code"> {{review.systemMessage.code | labelTranslate}}</span>
										</div>
										<div fxFlex="25" fxFlex.lt-sm="100" fxFlex.lt-md="40" class="p-2"
											[ngClass.lt-sm]="'mr-auto'">
											<span fxShow.lt-sm="true" fxShow="false" class="font-weight-bold"
												[ngStyle.lt-sm]="'white-space:pre;'">By: </span>
											<span>{{review.commentedBy}}</span>
										</div>
										<div fxFlex="25" fxFlex.lt-sm="100" fxFlex.lt-md="30" class="p-2"
											[ngClass.lt-sm]="'mr-auto'">
											<span fxShow.lt-sm="true" fxShow="false" class="font-weight-bold ">On:
											</span>
											<span>{{review.createdDate | date: 'short': uiTimezone}}</span>
										</div>
									</div>
								</div>
								<ng-template #noAuditBlock>
									<div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
										<p class="font-weight-bold text-secondary">There are no reviews for this service
											request.</p>
									</div>
								</ng-template>
								<div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
									<div fxFlex="50" class="p-2">
										<button (click)="toggleReviewMode()" data-toggle="tooltip" title="Add Review"
											type="button" class="btn btn-sm ux-icon-button" *ngIf="!reviewMode">
											<mat-icon data-placement="bottom">post_add</mat-icon>
										</button>
										<mat-form-field class="review-form-field" appearance="fill" *ngIf="reviewMode">
											<input matInput type="text" [(ngModel)]="inpReviewComment">
										</mat-form-field>
									</div>
									<div fxFlex="50" class="p-2" fxLayoutAlign="start center">
										<button (click)="saveReviewComment()" type="button"
											class="btn btn-sm ux-icon-button" *ngIf="reviewMode" data-toggle="tooltip"
											title="Save Review Comment">Save</button>
									</div>
								</div>
							</div>
						</mat-card-content>
					</mat-expansion-panel>
				</mat-accordion>

				<mat-accordion class="border border-light p-0 mt-2">
					<mat-expansion-panel [expanded]="true">
						<mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
							fxLayoutAlign="space-between center">
							<mat-panel-title class="mb-0 text-white font-weight-bold">Generated Alerts</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-card-content fxLayout="row" fxLayoutAlign="space-between center" class="mb-0">
							<div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
								<div fxFlex="100" fxShow.lt-sm="false" fxShow="true" fxLayout="row"
									fxLayoutAlign="space-between center" class="border-bottom border-secondary">
									<div fxFlex="10" fxFlex.lt-md="30" class="p-2 font-weight-bold">
										<span>#</span>
									</div>
									<div fxFlex="65" fxFlex.lt-md="40" class="p-2 font-weight-bold">
										<span>Message/Alert</span>
									</div>
									<div fxFlex="25" fxFlex.lt-md="30" class="p-2 font-weight-bold">
										<span>Generated On</span>
									</div>
								</div>
								<div fxFlex="100" fxLayout="row wrap"
									*ngIf="requestAlerts && requestAlerts.length > 0; else noDataBlock">
									<div fxFlex="100" fxLayout="row" fxLayout.lt-sm="column"
										[ngStyle.lt-sm]="'border-bottom:double;'" fxLayoutAlign="space-between center"
										*ngFor="let alert of requestAlerts; index as i">
										<div fxFlex="10" fxFlex.lt-sm="100" fxFlex.lt-md="30" class="p-2"
											[ngClass.lt-sm]="'mr-auto'">
											<span fxShow.lt-sm="true" fxShow="false" class="font-weight-bold "># </span>
											<span>{{alert.serialNumber}}</span>
										</div>
										<div fxFlex="65" fxFlex.lt-sm="100" fxFlex.lt-md="40" class="p-2" fxLayout="row"
											[ngClass.lt-sm]="'mr-auto'">
											<span fxShow.lt-sm="true" fxShow="false"
												class="font-weight-bold ">Message/Alert</span>
											<div [class]="'bg-severity-' + alert.severity" class="mr-1">
												<svg class="bi flex-shrink-0 me-2" width="20" height="20"
													*ngIf="alert.severity != 'L'">
													<use xlink:href="#exclamation-triangle-fill" /></svg>
												<svg class="bi flex-shrink-0 me-2" width="20" height="20"
													*ngIf="alert.severity === 'L'">
													<use xlink:href="#info-fill" /></svg>
											</div>
											<span>{{alert.message}}</span>
										</div>
										<div fxFlex="25" fxFlex.lt-sm="100" fxFlex.lt-md="30" class="p-2"
											[ngClass.lt-sm]="'mr-auto'">
											<span fxShow.lt-sm="true" fxShow="false" class="font-weight-bold ">Generated
												On</span>
											<span>{{alert.createdDate | date: 'short': uiTimezone}}</span>
										</div>
									</div>
								</div>
								<ng-template #noDataBlock>
									<div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
										<p class="font-weight-bold text-secondary">There are no alerts generated for the
											request.</p>
									</div>
								</ng-template>
							</div>
						</mat-card-content>
					</mat-expansion-panel>
				</mat-accordion>

				<mat-card-actions fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="end center"
					fxLayoutGap.lt-sm="5px" fxLayoutGap="15px" class="bg-light px-2 fixed-boattom sticky-footer">
					<button type="button" class="btn btn-sm ux-icon-button" (click)="assignToMe()"
						*ngIf="!assignedToMe && !formDetails.isCompleted && !this.superAccountUser"
						data-toggle="tooltip" title="Assign to me">Assign to me</button>
					<button type="button" class="btn btn-sm ux-icon-button" (click)="assignToUserViewer()"
						*ngIf="!assignedToMe && !formDetails.isCompleted && this.superAccountUser" data-toggle="tooltip"
						title="Assign">Assign</button>
					<button type="button" class="btn btn-sm ux-icon-button" (click)="unassign()"
						*ngIf="assignedToMe && !formDetails.isCompleted" data-toggle="tooltip"
						title="Unassign">Unassign</button>
					<button type="button" class="btn btn-sm ux-icon-button" *ngIf="formDetails['requestOrigin']!='myaccount'" (click)="navigateToFormView()"
						data-toggle="tooltip" title="View Form">View Form</button>
					<button type="button" class="btn btn-sm ux-icon-button" (click)="updateWorkflow('QUEUED')"
						*ngIf="assignedToMe && formDetails.isInException" data-toggle="tooltip"
						title="Move to Queue">Move to Queue</button>
					<button type="button" class="btn btn-sm ux-icon-button" (click)="skipWorkflow()"
						*ngIf="assignedToMe && skipBtnEnabled" data-toggle="tooltip"
						title="Skip">{{'btn.skip.' + formDetails.nextWorkflowApplicable() | labelTranslate}}</button>
					<button type="button" class="btn btn-sm ux-icon-button" (click)="confirmWorkflowUpdate('CIS')"
						*ngIf="assignedToMe && formDetails.nextWorkflowApplicable() == 'CIS'" data-toggle="tooltip"
						title="Submit to North Star">{{'btn.submitToCIS' | labelTranslate}}</button>
					<button type="button" class="btn btn-sm ux-icon-button" (click)="confirmWorkflowUpdate('BILLING')"
						*ngIf="assignedToMe && formDetails.nextWorkflowApplicable() == 'BILLING'" data-toggle="tooltip"
						title="Submit to Billing">{{'btn.submitToBilling' | labelTranslate}}</button>
					<button type="button" class="btn btn-sm ux-icon-button" (click)="updateWorkflow('BLANKET')"
						*ngIf="assignedToMe && formDetails.nextWorkflowApplicable() == 'BLANKET'" data-toggle="tooltip"
						title="Blanket">{{'btn.blanketUpdate' | labelTranslate}}</button>
					<button type="button" class="btn btn-sm ux-icon-button" (click)="confirmWorkflowUpdate('COMPLETED')"
						*ngIf="assignedToMe && formDetails.nextWorkflowApplicable() == 'COMPLETED'"
						data-toggle="tooltip" title="Mark as Completed">Mark as Completed</button>
					<button type="button" class="btn btn-sm ux-icon-button" (click)="updateWorkflow('CANCELLED')"
						*ngIf="assignedToMe && !formDetails.isCancelled && !formDetails.isCompleted"
						data-toggle="tooltip" title="Cancel Request">Cancel Request</button>
					<button type="button" class="btn btn-sm ux-icon-button"
						(click)="updateWorkflowWithRemarks('EXCEPTION')"
						*ngIf="assignedToMe && !formDetails.isInException && !formDetails.isCompleted"
						data-toggle="tooltip" title="Raise Exception">Raise Exception</button>

				</mat-card-actions>
			</mat-card-content>
		</mat-card>
	</section>
</div>