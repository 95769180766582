import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'labelTranslate',
})
export class ErrorLabelPipe implements PipeTransform {
  translationLabels:any;
  constructor(private translateService:TranslateService){
    this.translationLabels = this.translateService.translations['en'];
  }

  transform(value: any, _args?: any): any {
    if (_args) {
      value = value + '.' + _args;
    }
    if (this.translationLabels && value in this.translationLabels) {
      return this.translationLabels[value];
    } else {
      return value;
    }
  }
}

