import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';

@Pipe({
  name: 'subListFilter'
})
export class SubListFilterPipe implements PipeTransform {

  transform(items: any[], length: number): any[] {
        if (!items) { return []; }
        if (!length) { return items; }
        if (_.size(items) > length){
            return _.first(items, length);
        } else {
            return items;
        }
    }
}
