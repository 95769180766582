import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AbstractComponent } from '@londonhydro/ux-lib';
import { AccountDataProvider } from '../../backend/csr/dataservice/account.dataprovider';
import { CsrDataService } from 'src/app/backend/csr/dataservice/csr-data.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-registration-check-seacrh',
  templateUrl: './registration-check-seacrh.component.html',
  styleUrls: ['./registration-check-seacrh.component.scss']
})
export class RegistrationCheckSeacrhComponent extends AbstractComponent implements OnInit, OnDestroy {

  regCheckForm: FormGroup = this.formBuilder.group({
    searchRegCheckKey: ['', [Validators.required]]
  });
  isError: boolean;
  showContent: boolean;
  displayedColumns: string[] = ['#', 'Posted Date', 'Payment Amount', 'Payment Description'];
  dataSource: any;
  showTableData = false;

  pageLoader: boolean;
  regCheckData: any = [];
  fullName: any;
  customerID: any;
  accountID: any;

  constructor(injector:Injector, route: ActivatedRoute,  private formBuilder: FormBuilder,
              accountProvider: AccountDataProvider, private csrDataService: CsrDataService) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  userSessionLoaded(): void {
    console.log('userSessionLoaded:::', this.user);
  }

  regCheckSearch(): void {
    this.pageLoader = true;
    this.isError = false;
    this.showTableData = false;
    this.csrDataService.getRegCheckbyId(this.regCheckForm.value.searchRegCheckKey).subscribe(res => {
      if (!_.isEmpty(res)) {
        console.log('registration check response::', res);
        this.regCheckData = res;
        this.dataSource = res;
        this.dataSource = new MatTableDataSource(res);
        console.log('registration check response fetched for dataSource', this.dataSource.filteredData);
        if (this.dataSource.filteredData) {
          this.pageLoader = false;
          this.showTableData = true;
        } else {
          this.showTableData = false;
          this.pageLoader = false;
        }
      } else if (_.isEmpty(res)) {
        this.isError = true;
        this.pageLoader = false;
        this.showTableData = false;
      }
      this.filteredData();
    });

  }

  filteredData(): void {
    this.fullName = _.uniq(this.regCheckData, (name) => {
      if (name.firstName == null || name.lastName == null) {
        return name.fullName;
      } else if (name.fullName == null) {
        return (name.firstName + name.lastName);
      }
    })
    this.customerID = _.uniq(this.regCheckData, (id) => {
      return id.customerId;
    })
    this.accountID = _.uniq(this.regCheckData, (accId) => {
      return accId.accountId;
    })
    console.log('filtered data:', this.fullName, this.customerID, this.accountID);
  }

  ngOnDestroy(): void {
  }
}
