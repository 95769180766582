import { Component, OnInit, Output, EventEmitter, Injector, OnDestroy } from '@angular/core';
import { MenuItem, AbstractComponent } from '@londonhydro/ux-lib';
import * as _ from 'underscore';
import { Role } from '@londonhydro/ux-lib/lib/backend/auth/model/role';
import { CsrDataService } from '../../../../backend/csr/dataservice/csr-data.service';
import {fromEvent} from 'rxjs';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent extends AbstractComponent implements OnInit, OnDestroy {

  responsiveSelectedMenu: MenuItem;
  topMenuItems: MenuItem[];
  currentViewIndex: number;
  selectedMenu: MenuItem;
  showSubMenuBar: boolean;
  subMenuActive: MenuItem;
  subMenuItems: MenuItem[];
  subMenuIcon = 'initial';
  menuListJson: any[];
  private topMenuMap: Map<number, MenuItem>;
  loggedInUserName: any;
  temp: any;
  @Output() menuChange = new EventEmitter<MenuItem>();
  popStateCapture = null;

  constructor(injector: Injector, private csrDataService: CsrDataService) {
    super(injector);
    this.showSubMenuBar = false;
  }

  ngOnInit(): void {
    this.topMenuItems = new Array<MenuItem>();
    this.subMenuItems = null;
    this.topMenuMap = new Map();
    this.getAllMenuList();
    this.loggedInUserName = this.loginUser.username;
    if (this.loggedInUserName.indexOf('#') > 0) {
      this.loggedInUserName = this.loggedInUserName.substring(0, this.loggedInUserName.indexOf('#'));
    }
    this.popStateNavigationCapture();
  }

  popStateNavigationCapture(): void {
    this.popStateCapture = fromEvent(window, 'popstate').subscribe((event: any) => {
      if (!_.isEmpty(event.currentTarget.location.pathname)){
        let previousSubMenu: MenuItem = null;
        _.each(this.topMenuItems, (menu: MenuItem) => {
              if ( menu.action !== event.currentTarget.location.pathname && menu.hasSubMenu){
                _.each(menu.children, (subMenu: MenuItem) => {
                  if (subMenu.action === event.currentTarget.location.pathname){
                    previousSubMenu = subMenu;
                    this.showMenuItem(menu);
                  }
                });
              }else if (menu.action === event.currentTarget.location.pathname){
                previousSubMenu = menu;
              }
        });
        if (previousSubMenu){
          this.selectMenuItem(previousSubMenu);
        }
      }
     });
  }

  getAllMenuList(): void {
    this.csrDataService.getMenuList().subscribe((mList: any[]) => {
      const masterMenuList = _.map(mList, (m: any) => {
        const lmenu = MenuItem.create(m.id, m.name, m.label, m.action, m.styleClass, m.iconClass, m.title, m.iconTip, m.role, m.enabled);
        lmenu.parentMenuId = m.parentMenuId || 0;
        return lmenu;
      });

      console.log('masterMenuList:::', masterMenuList);
      const userRoleList = this.loginUser.authorities || this.loginUser.roles;

      let userRoles = _.map(userRoleList, (role: Role) => role.roleCode);
      if (!userRoles || _.isEmpty(userRoles)) {
        userRoles = ['USER'];
      } else {
        userRoles.push('USER');
      }
      const userMenuList = _.filter(masterMenuList, (menu: any) =>
        menu.role === null || (menu.role && _.intersection(userRoles, menu.role.split(',')).length > 0));

      const menuMap = new Map();
      _.each(userMenuList, (menu: MenuItem) => { menuMap.set(menu.id, menu); });

      _.each(userMenuList, (menu: MenuItem) => {
        if (menu.parentMenuId === 0) {
          this.topMenuItems.push(menu);
        } else {
          const pMenu: MenuItem = menuMap.get(menu.parentMenuId);
          if (pMenu) {
            pMenu.addSubMenu(menu);
          }
        }
      });
      console.log('this.topMenuItems:::', this.topMenuItems);
      this.loadDefaultMenu();
    });
  }

  private loadDefaultMenu(): void {
    const lthis = this;
    const allMenuItems = this.topMenuItems;
    console.log('allMenu', allMenuItems);
    _.each(allMenuItems, (menu: MenuItem) => { lthis.topMenuMap.set(menu.id, menu) });

    let sMenu = null;
    _.each(allMenuItems, (menu: MenuItem) => {
      if (menu.hasSubMenu) {
        const fMenu = _.find(menu.children, (smenu: MenuItem) => lthis.router.url === smenu.action);
        if (fMenu) {
          sMenu = fMenu;
        }
      } else {
        if (lthis.router.url === menu.action) {
          sMenu = menu;
        }
      }
    });
    console.log('Exact match ::: sMenu:::', sMenu);
    if (!sMenu) {
      _.each(allMenuItems, (menu: MenuItem) => {
        if (menu.hasSubMenu) {
          const fMenu = _.find(menu.children, (smenu: MenuItem) => lthis.router.url.startsWith(smenu.action));
          if (fMenu) {
            sMenu = fMenu;
          }
        } else {
          if (lthis.router.url.startsWith(menu.action)) {
            sMenu = menu;
          }
        }
      });
    }
    console.log(lthis.router.url, '::sMenu:::', sMenu);
    if (!sMenu) {
      // no match found, load first menu...
      const fMenu = this.topMenuItems[0];
      console.log('got', fMenu);
      if (fMenu.hasSubMenu) {
        sMenu = fMenu.children[0];
      } else {
        sMenu = fMenu;
      }
    }
    this.selectedMenu = sMenu;
    if (this.selectedMenu) {
      if (this.selectedMenu.parentMenuId != null && this.selectedMenu.parentMenuId > 0) {
        const pMenu = this.topMenuMap.get(this.selectedMenu.parentMenuId);
        pMenu.styleClass = (pMenu.styleClass || '').concat(' active');
        this.subMenuItems = pMenu.children;
        this.showSubMenuBar = true;
        this.subMenuActive = this.selectedMenu;
      }
      this.menuChange.emit(this.selectedMenu);
      if (lthis.router.url && lthis.router.url.length <= 1) {
        this.router.navigate([this.selectedMenu.action]);
      }
    }
  }

  deselect(menu): void {
    this.menuChange.emit(this.temp);
    if (this.temp.action !== 'redirectFalse') {
      this.router.navigate([this.temp.action]);
    }
  }

  selectMenuItem(menu: MenuItem): void {
    this.responsiveSelectedMenu = menu;
    if (this.selectedMenu !== menu) {
      this.topMenuMap.forEach((value: MenuItem, key: number, map: Map<number, MenuItem>) => {
        value.styleClass = (value.styleClass || '').replace('active', '');
      });
      if (menu.parentMenuId) {
        const pMenu = this.topMenuMap.get(menu.parentMenuId);
        pMenu.styleClass = (pMenu.styleClass || '').concat(' active');
        this.selectedMenu = menu;
        console.log('this.selectedMenu::::', this.selectedMenu);
        this.menuChange.emit(this.selectedMenu);
        if (this.selectedMenu.action !== 'redirectFalse') {
          this.router.navigate([this.selectedMenu.action]);
        }
      } else if (menu.hasSubMenu) {
        this.subMenuActive = menu;
        this.subMenuItems = menu.children;
        this.showSubMenuBar = true;
        this.subMenuIcon = 'show';
      } else {
        this.selectedMenu = menu;
        this.subMenuItems = null;
        this.showSubMenuBar = false;
        this.menuChange.emit(this.selectedMenu);
        if (this.selectedMenu.action !== 'redirectFalse') {
          this.router.navigate([this.selectedMenu.action]);
        }
      }
    }
  }

  showMenuItem(menu: MenuItem): void {
    if (menu.hasSubMenu) {
      this.subMenuActive = menu;
      this.subMenuItems = menu.children;
      this.showSubMenuBar = true;
      this.subMenuIcon = 'show';
    }
  }

  toggleSubMenuBar(event: any): void {
    console.log('toggleSubMenuBar::', event);
    if (event.target.id.indexOf('topmenu_') < 0) {
      if (this.selectedMenu.hasSubMenu) {
        this.subMenuItems = this.selectedMenu.children;
        this.showSubMenuBar = true;
      } else if (this.selectedMenu.parentMenuId && this.selectedMenu.parentMenuId > 0) {
        const pMenu = this.topMenuMap.get(this.selectedMenu.parentMenuId);
        this.subMenuItems = pMenu.children;
        this.showSubMenuBar = true;
        this.subMenuActive = this.selectedMenu;
      } else {
        this.subMenuItems = null;
        this.showSubMenuBar = false;
      }
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.popStateCapture.unsubscribe();
  }
}
