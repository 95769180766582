import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '@londonhydro/ux-lib';

import { AccountDataProvider } from '../../backend/csr/dataservice/account.dataprovider';
import { ErrorLabelPipe } from '../../framework/shared/service/error-label.pipe';
import { IamLabelPipe } from '../../framework/shared/service/iam-label.pipe';
import { ClickOutsideDirective } from './click-outside.directive';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { WidgetCardComponent } from './components/widget-card/widget-card.component';
import { ListFilterPipe } from './service/list-filter.pipe';
import { SentenceCasePipe } from './service/sentence-case.pipe';

@NgModule({
  declarations: [
    ListFilterPipe, WidgetCardComponent, TopMenuComponent, ClickOutsideDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ListFilterPipe,
    WidgetCardComponent,
    TopMenuComponent,
    ClickOutsideDirective
  ]
})
export class SharedModule {

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        DatePipe,
        DecimalPipe,
        CurrencyPipe,
        TitleCasePipe,
        AccountDataProvider,
        ListFilterPipe,
        ErrorLabelPipe,
        IamLabelPipe,
        SentenceCasePipe
      ]
    };
  }
}
