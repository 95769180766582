import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { AbstractComponent } from '@londonhydro/ux-lib';

@Component({
  selector: 'app-change-login-email',
  templateUrl: './change-login-email.component.html',
  styleUrls: ['./change-login-email.component.scss']
})
export class ChangeLoginEmailComponent extends AbstractComponent implements OnInit, OnDestroy {

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
  changeLoginEmailForm(): void {
    this.router.navigate(['/csrapp/change-login-email-form']);
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
