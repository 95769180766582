import { DatePipe } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { IamDataService } from '@londonhydro/iam-api';
import { AbstractComponent } from '@londonhydro/ux-lib';
import { ExportToCsv } from 'export-to-csv';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as _ from 'underscore';
import { AccountDataProvider } from '../../backend/csr/dataservice/account.dataprovider';
import { CsrDataService } from '../../backend/csr/dataservice/csr-data.service';
import { UserDetailViewComponent } from '../user-detail-view/user-detail-view.component';

@Component({
  selector: 'app-user-search-form',
  templateUrl: './user-search-form.component.html',
  styleUrls: ['./user-search-form.component.scss']
})
export class UserSearchFormComponent extends AbstractComponent implements OnInit, OnDestroy {
  searchUserForm: FormGroup = this.formBuilder.group({
    searchKey: ['']
  });
  pageLoader: boolean;
  blockReason: any[];
  quickSearch = new FormControl();
  hideImg = false;
  userResponseData: any;
  csrCustomer: any;
  csrUserAcc: any;
  csrAccount: any;
  searchByRolesFormControl = new FormControl([]);
  searchByStatusFormControl = new FormControl([]);
  advSearch = false;
  searchType = '';
  roles = [
    {
      value: 'OWNER',
      label: 'Owner',
      checked: false
    },
    {
      value: 'DELEGATE',
      label: 'Delegate',
      checked: false
    },
    {
      value: 'USER',
      label: 'User',
      checked: false
    },
    {
      value: 'ADMIN',
      label: 'Admin',
      checked: false
    },
    {
      value: 'CSR',
      label: 'CSR',
      checked: false
    }
  ];
  status = [
    {
      value: 'Active',
      label: 'Active',
      checked: false
    },
    {
      value: 'Pending',
      label: 'Pending',
      checked: false
    },
    {
      value: 'Locked',
      label: 'Locked',
      checked: false
    },
    {
      value: 'Deactivate',
      label: 'Deactivate',
      checked: false
    },
    {
      value: 'Expired',
      label: 'Expired',
      checked: false
    },
    {
      value: 'Suspended',
      label: 'Suspended',
      checked: false
    }
  ];
  displayedColumns: string[];
  formAttributeConfig: any[] = [
    { name: 'username', label: 'Username', type: 'string', class: 'userSearchUsername' },
    { name: 'fullName', label: 'Full Name', type: 'string', class: 'userSearchFullName' },
    { name: 'customerId', label: 'Customer ID', type: 'string', class: 'userSearchCustomerId' },
    { name: 'currentRoles', label: 'Role', type: 'role', class: 'userSearchRole' },
    { name: 'userStatus', label: 'Status', type: 'status', class: 'userSearchStatus' },
    { name: 'remark', label: 'Remarks', type: 'string', class: 'userSearchRemarks' },
    { name: 'action', label: 'Action', type: 'action', class: 'userSearchAction' }
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  @ViewChild('paginator') paginator: MatPaginator;
  roleParam = '';
  statusParam = '';
  showTable: boolean;
  searchKey = '';
  checkedRoles: any;
  checkedStatus: any;
  isUserViewClicked: boolean;
  UserViewData: any;
  dataList: [];
  brandingName: any;
  @ViewChild('userSearchTableSort') sort: MatSort;

  constructor(injector: Injector, private formBuilder: FormBuilder, private iamDataService: IamDataService, route: ActivatedRoute,
    private toastr: ToastrService, accountDataProvider: AccountDataProvider, private csrDataService: CsrDataService,
    private matDialog: MatDialog, private datePipe: DatePipe) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getBrandingList();
    this.displayedColumns = _.union(['index'], _.pluck(this.formAttributeConfig, 'name'));
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator
  }

  userSessionLoaded(): void {
  }

  onStatusRemoved(data: string) {
    const dataArray = this.searchByStatusFormControl.value as string[];
    this.removeFirst(dataArray, data);
    this.searchByStatusFormControl.setValue(dataArray); // To trigger change detection
  }
  onRolesRemoved(data: string) {
    const dataArray = this.searchByRolesFormControl.value as string[];
    this.removeFirst(dataArray, data);
    this.searchByRolesFormControl.setValue(dataArray); // To trigger change detection
  }
  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  searchList(type = ''): void {
    let queryParams = '';
    this.pageLoader = true;
    this.searchType = type;
    this.checkRole();
    this.checkStatus();
    if (this.roleParam && this.statusParam && this.searchUserForm.value.searchKey) {
      queryParams = '/users?' + this.roleParam + '&' + 'searchText=' + encodeURIComponent(this.searchUserForm.value.searchKey)
        + '&' + this.statusParam + '&maxResults=0';
    } else if (!this.roleParam && this.statusParam && this.searchUserForm.value.searchKey) {
      queryParams = '/users?searchText=' + encodeURIComponent(this.searchUserForm.value.searchKey) + '&' + this.statusParam + '&maxResults=0';
    } else if (this.roleParam && !this.statusParam && this.searchUserForm.value.searchKey) {
      queryParams = '/users?searchText=' + encodeURIComponent(this.searchUserForm.value.searchKey) + '&' + this.roleParam + '&maxResults=0';
    } else if (!this.roleParam && !this.statusParam && this.searchUserForm.value.searchKey) {
      queryParams = '/users?searchText=' + encodeURIComponent(this.searchUserForm.value.searchKey) + '&maxResults=0';
    } else if (this.roleParam && this.statusParam && !this.searchUserForm.value.searchKey) {
      queryParams = '/users?' + this.roleParam + '&' + this.statusParam + '&maxResults=0';
    } else if (this.roleParam && !this.statusParam && !this.searchUserForm.value.searchKey) {
      queryParams = '/users?' + this.roleParam + '&maxResults=0';
    } else if (!this.roleParam && this.statusParam && !this.searchUserForm.value.searchKey) {
      queryParams = '/users?' + this.statusParam + '&maxResults=0';
    } else if (!this.roleParam && !this.statusParam && !this.searchUserForm.value.searchKey) {
      queryParams = '/users';
    }
    if (type === 'SHELL') {
      queryParams = '/users?roles=USER&status=Active';
    }
    if (type === 'REGISTERED') {
      queryParams = '/users?roles=OWNER&status=Active';
    }

    this.csrDataService.getUserAccount(queryParams).pipe(
      catchError(error => {
        this.pageLoader = false;
        this.toastr.warning('No User Found', '', { disableTimeOut: false });
        return of(null);
      })).subscribe(res => {
        if (_.isEmpty(res)) {
          this.pageLoader = false;
          this.toastr.warning('No User Found', '', { disableTimeOut: false });
          console.log("no user found!!! ::::", res);
          this.dataSource.data = null;

        } else {
          if (type === 'SHELL' || (this.checkedRoles.length === 1 && queryParams.includes('roles=USER'))) {
            res = res.filter((value) => value.authorities.length === 1 && value.authorities[0].roleCode === 'USER');
          }
          _.each(res, (element, index) => {
            element['rowIndex'] = (index + 1);
            element['fullName'] = element['firstName'] && element['lastName'] ?
              element['firstName'].trim() + ' ' + element['lastName'].trim() : (element['firstName'] && !element['lastName'] ? element['firstName'].trim() :
                (!element['firstName'] && element['lastName'] ? element['lastName'].trim() : ''));

            element['userStatus'] = element.status.value;
            element['remark'] = element.status.remark;
            if (element['authorities']) {
              _.each(element['authorities'], (value, i) => {
                if (type === 'SHELL') {
                  if (element['authorities'].length === 1 || (element['authorities'].length > 1 && value ?.roleCode !== 'USER')) {
                    element['currentRoles'] = element['currentRoles'] ? element['currentRoles'] + (i > 0 ? ', ' : '') + value ?.roleCode : value ?.roleCode;
                  }
                } else {
                  element['currentRoles'] = element['currentRoles'] ? element['currentRoles'] + (i > 0 ? ', ' : '') + value ?.roleCode : value ?.roleCode;
                }
              });
            }
          });
          this.quickSearch.reset();
          console.log('getSearchUserList fetched', res);
          this.dataSource.data = res;
          this.pageLoader = false;
        }
      });
  }

  checkRole(): void {
    this.roleParam = '';
    this.checkedRoles = _.filter(this.roles, (role: any) => {
      let flagRole = false;
      _.each(this.searchByRolesFormControl.value, (res: any) => {
        if (res.value === role.value) {
          flagRole = true;
        }
      });
      return flagRole;
    });

    if (this.checkedRoles.length === 1) {
      this.roleParam = 'roles=' + this.checkedRoles[0].value;
    } else if (this.checkedRoles.length > 1) {
      let counter = 0;
      for (const rol of this.checkedRoles) {
        this.roleParam = this.roleParam + (counter > 0 ? '&' : '') + 'roles=' + rol.value;
        counter++;
      }
    }
  }

  checkStatus(): void {
    this.statusParam = '';
    this.checkedStatus = _.filter(this.status, (st: any) => {
      let flagStatus = false;
      _.each(this.searchByStatusFormControl.value, (res: any) => {
        if (res.value === st.value) {
          flagStatus = true;
        }
      });
      return flagStatus;
    });

    if (this.checkedStatus.length === 1) {
      this.statusParam = 'status=' + this.checkedStatus[0].value;
    } else if (this.checkedStatus.length > 1) {
      let counter = 0;
      for (const param of this.checkedStatus) {
        this.statusParam = this.statusParam + (counter > 0 ? '&' : '') + 'status=' + param.value;
        counter++;
      }
    }
  }

  getBrandingList(): void {
    this.csrDataService.getBrandingList().subscribe((response: any) => {
      const key = Object.keys(response);
      const brand = key[0]
      this.brandingName = response[brand]['customerPortalName'];
    });
  }
  userDetailViewEnabled(element: any): void {
    console.log('element data::', element);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'modal-component' + element.id;
    dialogConfig.width = '920px';
    dialogConfig.height = 'auto';
    dialogConfig.data = { data: element, brandingName: this.brandingName };
    const userDetailViewer = this.matDialog.open(UserDetailViewComponent, dialogConfig);
    userDetailViewer.afterClosed().subscribe((response: any) => {
      if (response === 'success') {
        this.searchList();
      }
    });
  }

  applyFilter(filterValue: string): any {
    console.log('filterValue::', filterValue);
    console.log('this.datasource', this.dataSource);
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filteredData.forEach(
      (res: any, count) => {
        res.rowIndex = count + 1;
      });
    if (this.dataSource.paginator) {
      console.log('sanket');
      this.dataSource.paginator.firstPage();
    }
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  exportToCSV(): void {
    const tableDataCSV = [];

    this.dataSource.filteredData.forEach((element) => {
      const configHeader = this.formAttributeConfig;
      const tempObj: any = {};
      for (const attribute of configHeader) {
        if (attribute ?.type !== 'action') {
          const val = element[attribute.name];
          const key = attribute.label;
          tempObj[key] = val ? val : '';
        }
      }
      if (element?.lastLoginDate){
        tempObj['Last Login Date'] = element.lastLoginDate;
      }
      tableDataCSV.push(tempObj);
    });
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: 'User_Admin_' + this.datePipe.transform(new Date(), 'shortDate').split('/').join('.')

    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(tableDataCSV);
  }
  advancedSearch(): void {
    this.advSearch = !this.advSearch;
  }
}


