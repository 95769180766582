<div id="modal-content-wrapper">
	<section id="modal-body">
		<mat-card class="ux-card border border-light p-0">
			<mat-card-header class="p-2 pt-3" fxLayout="row" fxLayoutAlign="space-between center">
				<mat-card-title>{{modalData.title}}</mat-card-title>
				<button (click)="actionCancel()" class="btn btn-link text-secondary" type="button" id="dismiss" aria-label="Close">
					<mat-icon class="material-symbols-outlined">close</mat-icon>
				</button>
			</mat-card-header>
			<mat-card-content fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px" class="p-2">
				<p>Please provide your comment<span class="font-weight-bold text-danger">*</span></p>
				<mat-form-field appearance="fill">
					<mat-label>Type here...</mat-label>
					<input matInput [(ngModel)]="modalData.remarks">
				</mat-form-field>
				<mat-card fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center" *ngIf="message"
					class="text-white p-1" [ngClass]="'bg-'+messageType">
					<div class="pl-2" fxLayout="row" fxLayoutAlign="start center">
						<mat-icon aria-hidden="false">
							{{messageType}}
						</mat-icon>
						<span class="pl-3" [innerHTML]="message"></span>
					</div>
					<button type="button" class="btn btn-link text-white" (click)="hideInfo()">X</button>
				</mat-card>
				<mat-card-actions fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px" class="px-2 bg-light">
					<button type="button" class="btn btn-secondary" (click)="actionCancel()">Cancel</button>
					<button type="button" class="btn btn-success" (click)="actionSubmit()" cdkFocusInitial>Submit</button>
				</mat-card-actions>
			</mat-card-content>
		</mat-card>
	</section>
</div>