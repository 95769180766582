import { MenuItem } from '@londonhydro/ux-lib';

export class ApplicationMenu extends MenuItem {

public static menus(): MenuItem[] {

	const menuList = [
	MenuItem.create(1, 'marketdata', 'marketdata', 'marketdata', 'menu', 'ico3', 'marketdata'),
	MenuItem.create(2, 'account', 'account', 'account', 'menu', 'ico3', 'account'),
	MenuItem.create(3, 'customer-form', 'customer-form', 'customer-form', 'menu', 'ico3', 'customer-form'),
	MenuItem.create(4, 'account-form', 'account-form', 'account-form', 'menu', 'ico3', 'account-form'),
	MenuItem.create(5, 'account-notification-form', 'account-notification-form', 'account-notification-form', 'menu', 'ico3', 'account-notification-form'),
	MenuItem.create(6, 'user-form', 'moveIn', 'user-form', 'menu', 'ico3', 'user-form'),
	MenuItem.create(7, 'bnp', 'bnp', 'bnp', 'menu', 'ico3', 'bnp'),
	MenuItem.create(8, 'bnp-form', 'bnp-form', 'bnp-form', 'menu', 'ico3', 'bnp-form'),
	MenuItem.create(9, 'energyusage', 'energyusage', 'energyusage', 'menu', 'ico3', 'energyusage'),
	MenuItem.create(10, 'water-usage', 'water-usage', 'water-usage', 'menu', 'ico3', 'water-usage'),
	MenuItem.create(11, 'location', 'location', 'location', 'menu', 'ico3', 'location'),
	MenuItem.create(12, 'customerservice', 'customerservice', 'customerservice', 'menu', 'ico3', 'customerservice'),
	MenuItem.create(13, 'customerserviceForm', 'customerserviceForm', 'customerserviceForm', 'menu', 'ico3', 'customerserviceForm')
	];
	return menuList;
	}
}
