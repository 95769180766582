import { Component, OnInit, Inject } from '@angular/core';
import { UserSearchFormComponent } from '../user-search-form/user-search-form.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IamUserTxnService} from '@londonhydro/iam-api';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { CsrDataService } from '../../backend/csr/dataservice/csr-data.service';

@Component({
  selector: 'app-send-registraion-email-form',
  templateUrl: './send-registraion-email-form.component.html',
  styleUrls: ['./send-registraion-email-form.component.scss']
})
export class SendRegistraionEmailFormComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<UserSearchFormComponent>, @Inject(MAT_DIALOG_DATA) public userData: any,
               private toastr: ToastrService, private iamUserTxnService: IamUserTxnService,private csrDataService:CsrDataService) {
    console.log(userData);
  }

  ngOnInit(): void {
  }

  sendRegistrationEmail(): void {

    this.iamUserTxnService.resendRegistrationEmail(this.userData.id).pipe(
      catchError(error => {
        this.toastr.error('Error occured while sending the registration email.', 'Error', {
          timeOut: 10000,
        });
       
           return of(null);
      })).subscribe(res=> {
        
      console.log('response', res);
      if(res){
        this.toastr.success('You have successfully sent the registration email ', 'Success', {
          timeOut: 10000,
        });
        this.closeModal('success');
      }else{
        this.toastr.error('Error occured while sending the registration email.', 'Error', {
          timeOut: 10000,
        });
      }
    
    });

  }
  
  closeModal(str?: string): void {
    this.dialogRef.close(str);
  }
}
