import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Account } from '../model/account';

@Injectable()
export class AccountDataProvider {

  private _accountData: BehaviorSubject<Account> = new BehaviorSubject<Account>(null);
  private lastAccount: Account;
  currentAccount = this._accountData.asObservable();

  constructor() {
  }

  updateAccount(account: Account): void {
    if (this.lastAccount !== account) {
      this.lastAccount = account;
      this._accountData.next(account);
    }
  }

  getSelectedAccount(): Account {
    return this._accountData.getValue();
  }
}