import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOMERSERVICE_MODULE_CONFIG } from '@londonhydro/customerservice-api';
import { IAM_API_MODULE_CONFIG } from '@londonhydro/iam-api';
import { AngularMaterialModule, APP_CONIG, APP_SESSION_CONIG, BRANDING_CONFIG, CASSelectorComponent, DOCUMENTSERVICE_MODULE_CONFIG, FileUploadModule, FrameworkModule, GOOGLE_CLIENT_ID, IAM_MODULE_CONFIG, RECAPTCHA_SETTINGS, WebApp } from '@londonhydro/ux-lib';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import patternFill from 'highcharts/modules/pattern-fill';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrModule } from 'ngx-toastr';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { BRANDING } from '../environments/branding';
import { environment } from '../environments/environment';
import { HomeComponent } from '../home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { ApplicationMenu } from './applicationmenu';
import { CsrDataProxyModule } from './backend/csr-proxy.module';
import { SharedModule } from './framework/shared/shared.module';
import { NgIdleModule } from '@ng-idle/core';
import { NgxMaskModule } from 'ngx-mask';








patternFill(Highcharts);



const config = { baseURI: environment.customerApiEndPoint };

@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    /*  Angulartics2Module.forRoot({
       pageTracking: {
         excludedRoutes: [],
         clearQueryParams: true,
         clearIds: true
       }
     }), */
    AngularMaterialModule,
    NgSelectModule,
    UiSwitchModule,
    ChartModule,
    SharedModule.forRoot(),
    FileUploadModule,
    CsrDataProxyModule.forRoot(config),
    FrameworkModule.forRoot(environment, BRANDING, Object.assign({}, { developermode: false, menus: ApplicationMenu.menus() }),
      [CASSelectorComponent]),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: true,
      enableHtml: true,
      maxOpened: 1,
      autoDismiss: true
    }),
    NgIdleModule.forRoot(),
    NgxMaskModule.forRoot()
  ],
  providers: [CookieService, DeviceDetectorService,
    {
      provide: APP_CONIG,
      useFactory: () => environment
    },
    { provide: APP_SESSION_CONIG, useFactory: () => environment.iam },
    { provide: IAM_MODULE_CONFIG, useFactory: () => environment.iam },
    {
      provide: IAM_API_MODULE_CONFIG, useFactory: () => environment.iam
    },
    { provide: BRANDING_CONFIG, useFactory: () => BRANDING },
    { provide: DOCUMENTSERVICE_MODULE_CONFIG, useValue: environment.documentservice },
    {
      provide: CUSTOMERSERVICE_MODULE_CONFIG, useValue: environment.customerservice
    },
    {
      provide: RECAPTCHA_SETTINGS, useFactory: () => environment.security.recaptcha,
    },
    {
      provide: GOOGLE_CLIENT_ID, useFactory: () => environment.security.google_client_id
    },
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting, patternFill] }
  ],
  bootstrap: [WebApp]
})
export class AppModule {}
