import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { IamUserTxnService } from '@londonhydro/iam-api';
import { AbstractComponent, BrandingConfig, BRANDING_CONFIG, AppConfg, APP_CONIG } from '@londonhydro/ux-lib';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountDataProvider } from '../../backend/csr/dataservice/account.dataprovider';
import { CsrDataService } from '../../backend/csr/dataservice/csr-data.service';

@Component({
  selector: 'app-unblock-account-form',
  templateUrl: './unblock-account-form.component.html',
  styleUrls: ['./unblock-account-form.component.scss']
})
export class UnblockAccountFormComponent extends AbstractComponent implements OnInit, OnDestroy {
  selectedOption = 0;
  numberOfCharacter = 0;
  maxCharacter = 250;
  interaction = {
    reasonText: ''
  };
  counter = true;
  unBlockAccount = this.formBuilder.group({
    checkUnblockAccount: ['', [Validators.required]],
    reason: [''],
  });
  isUnblockAccount: boolean;
  isLoginUserWaterCSR: boolean;
  notYetRegistered: boolean;
  userStatus: any;
  successFlag: boolean;
  errorFlag: boolean;
  mailSent = false;
  userId: any;
  branding:any;
  isMultiPortalSupport = true;
  apiCallPending = false;

  constructor(injector: Injector, private toastr: ToastrService, public formBuilder: FormBuilder,
    private iamUserTxnService: IamUserTxnService, route: ActivatedRoute, accountProvider: AccountDataProvider,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public userData: any, private csrDataService:CsrDataService,
    @Inject(BRANDING_CONFIG) public brandingConfig: BrandingConfig,
    @Inject(APP_CONIG) public appConfig: AppConfg) {
    super(injector);
    this.branding=this.userData.siteName;
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.isMultiPortalSupport = this.brandingConfig[this.appConfig['brandName']]['multiplePortalSupport'] ?? true;
    this.unBlockAccount.controls.reason.valueChanges.subscribe(newVal => {
      this.unBlockAccount.controls.reason.setValidators([Validators.required, this.noWhitespaceValidator]);
    })
  }

  noWhitespaceValidator(control: FormControl): any {
    return (control.value || '').trim().length? null : { 'whitespace': true };       
  }

  resetAlertFlags(): void {
    this.successFlag = false;
    this.errorFlag = false;
  }

  onTextChange(reasonText: string): void {
    this.numberOfCharacter = reasonText.length;
    console.log('number of character:', this.numberOfCharacter);
  }

  unblockAccount(): void {

    const params = JSON.parse(JSON.stringify({
      remark: this.unBlockAccount.controls.reason.value
    }));
    this.apiCallPending = true;
    this.iamUserTxnService.unblockUserAccount(this.userData.data.id, params).pipe(
      catchError(error => {
        this.errorFlag = true;
        this.closeModal('error');
        this.apiCallPending = false;
        this.resetAlertFlags();
        this.toastr.error('Error occured while unblocking the account.', '', {
          timeOut: 10000,
        });
       
           return of(null);
      })).subscribe((res:any) => {
      console.log('response', res);
        this.userStatus = res.status.value;
        this.mailSent = true;
        this.successFlag = true;
        this.resetAlertFlags();
        this.toastr.success('You have unblocked the account successfully', '', {
          timeOut: 10000,
        });
        this.closeModal('success');
        this.apiCallPending = false;
      return false;
    });
  }

  closeModal(str?: string): void {
    this.dialogRef.close(str);
  }
}