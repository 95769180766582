import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractComponent } from '@londonhydro/ux-lib';
import { AccountDataProvider } from '../../backend/csr/dataservice/account.dataprovider';

@Component({
  selector: 'app-my-profile-options',
  templateUrl: './my-profile-options.component.html',
  styleUrls: ['./my-profile-options.component.scss']
})
export class MyProfileOptionsComponent extends AbstractComponent implements OnInit, OnDestroy {

  constructor(injector: Injector, route: ActivatedRoute, accountProvider: AccountDataProvider,
    router: Router) {
    super(injector);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
