<div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="showPageLoader" id="loaderdiv">
	<mat-spinner></mat-spinner>
</div>
<div fxLayout="column" *ngIf="formListDS">
	<div fxFlex="100" fxLayout="column" fxLayoutAlign="space-around stretch" class="p-2">
		<div fxLayout="row" fxLayout.lt-sm="column" [ngClass.lt-sm]="'mb-4'" fxFlex="100" fxLayoutAlign="end center"
			fxLayoutGap="15px" *ngIf="formListDS" class="mb-2">
			<mat-form-field>
				<mat-label>Quick search</mat-label>
				<input matInput class="form-field"  #quickSearchInput (keyup)="applyFilter($event.target.value)">
							<button matSuffix mat-icon-button aria-label="Clear"
										 (click)="applyFilter(''); quickSearchInput.value=''">
									<mat-icon matSuffix>close</mat-icon>
							</button>
			</mat-form-field>

			<button type="button" class="btn btn-sm ux-icon-button" title="Refresh"
				(click)="refreshApi()" fxLayout="row" fxLayoutAlign="start center">
				<mat-icon data-toggle="tooltip" data-placement="bottom" title="Refresh" class="mr-2">refresh
				</mat-icon><span>Refresh</span>
			</button>

			<button type="button" class="btn btn-sm ux-icon-button" title="Export Data in CSV format"
				(click)="exportToCSV()" fxLayout="row" fxLayoutAlign="start center">
				<mat-icon data-toggle="tooltip" data-placement="bottom" title="Export Data" class="mr-2">file_download
				</mat-icon><span>Export Data</span>
			</button>
			<button type="button" [disabled]="concatUsernameInfo.length<1" class="btn btn-sm ux-icon-button" title="Delete User " data-toggle="tooltip"
				(click)="deletePendingRegistraions()" fxLayout="row" fxLayoutAlign="start center">
				<mat-icon class="mr-2">delete</mat-icon>
				<span>Delete</span>
			</button>
			<button type="button" [disabled]="concatUsernameInfo.length<1" class="btn btn-sm ux-icon-button" title="Renotify User" data-toggle="tooltip"
				(click)="renotifyCustomers()" fxLayout="row" fxLayoutAlign="start center">
				<mat-icon class="mr-2">notifications</mat-icon>
				<span>Renotify</span>
			</button>
			
			
		</div>
		<div>
			<mat-table  [dataSource]="formListDS" class="live-data-container mat-elevation-z8" matSort
				 [ngClass.lt-md]="'mat-table-responsive'" [ngClass.lt-sm]="'mat-table-responsive'"
				#serviceReqTableSort="matSort" >
				<ng-container matColumnDef="index">
					<mat-header-cell *matHeaderCellDef class="sl" [disabled]="true" mat-sort-header><span></span>
						<!-- <mat-checkbox  class="header-checkbox align-middle"
						(change)="selectChangeAll($event)">
           				</mat-checkbox> -->
					</mat-header-cell>

					<mat-cell *matCellDef="let element; let i = index" class="sl" [ngClass.lt-sm]="'mat-cell-responsive-sm padding-left-md'">
					<span>
						<mat-checkbox  [checked]="element.isSelected"
						(change)="selectChangeUnique($event,element.username,element.id)">
           				</mat-checkbox>
					</span>	
					</mat-cell>
				</ng-container>

				<ng-container *ngFor="let attribute of formAttributeConfig; index as i" matColumnDef="{{attribute.name}}">
					<mat-header-cell *matHeaderCellDef class="pl-1" [class]="attribute.class"
						[ngClass.lt-sm]="'d-none'" mat-sort-header>
						<div>{{attribute.label}}</div>
					</mat-header-cell>
					<mat-cell class="matCell pl-1 " [class]="attribute.class" *matCellDef="let element;"
						[ngClass.lt-sm]="'mat-cell-responsive-sm'">
						<span *ngIf="attribute.type==='string' || attribute.type==='number'" fxFlex.lt-sm="100">
							<span [ngClass.lt-sm]="'d-flex'" fxFlex.lt-sm="100"><span class="d-none"
									[ngClass.lt-sm]="'mobile-label-display'" fxFlex.lt-sm="35">{{attribute.label}}:</span>
									<span fxFlex.lt-sm="65">{{element[attribute.name] || '-'}} </span></span>
						</span>
						<span *ngIf="attribute.type==='createDate'" fxFlex.lt-sm="100">
							<span [ngClass.lt-sm]="'d-flex'" fxFlex.lt-sm="100"><span class="d-none"
									[ngClass.lt-sm]="'mobile-label-display'" fxFlex.lt-sm="35">{{attribute.label}}:</span><span
									fxFlex.lt-sm="65">{{element[attribute.name]  | date: 'short'}}</span>
								</span>
						</span>
						
						<span *ngIf="attribute.type==='notes'" fxFlex.lt-sm="100">
							<span [ngClass.lt-sm]="'d-flex'" fxFlex.lt-sm="100"><span class="d-none"
									[ngClass.lt-sm]="'mobile-label-display'" fxFlex.lt-sm="36">{{attribute.label}}:</span>
									<span *ngIf="element.customerId" fxFlex.lt-sm="65">Customer ID = {{element.customerId}}</span>
																		
								<span *ngIf="!element.customerId" fxFlex.lt-sm="65">-
								</span>
								</span>						
							
						</span> 
				   		<span *ngIf="attribute.type === 'role'" fxFlex.lt-sm="100"> <span fxFlex.lt-sm="35"
				           class="d-none" [ngClass.lt-sm]="'mobile-label-display'">{{attribute.label}}:</span>
				          <span *ngFor="let role of element[attribute.name], let i= index" fxFlex.lt-sm="65"> <span *ngIf="i>0"> ,</span>	            
					       {{role.roleCode}}
			            </span>
			          </span>
						
						<span *ngIf="attribute.type==='registrationLinkExpiry'" fxFlex.lt-sm="100">
							<span *ngIf="element[attribute.name]>=currentDate" fxFlex.lt-sm="100">
							<p><span >N</span></p>
							<p><span  [ngClass.lt-sm]="'d-flex'" fxFlex.lt-sm="100"><span class="d-none" fxFlex.lt-sm="35"
									[ngClass.lt-sm]="'mobile-label-display'">{{attribute.label}}:</span><span fxFlex.lt-sm="65">
										Will expire on ({{element[attribute.name] | date: 'short' : uiTimezone }}) </span>
							</span></p>
							</span>
							<span *ngIf="element[attribute.name]<currentDate" fxFlex.lt-sm="100">
							<p><span >Y</span></p>
							<p><span  [ngClass.lt-sm]="'d-flex'" fxFlex.lt-sm="100"><span class="d-none" fxFlex.lt-sm="35"
									[ngClass.lt-sm]="'mobile-label-display'">{{attribute.label}}:</span><span fxFlex.lt-sm="65"> 
									Expired on ({{element[attribute.name] | date: 'short' : uiTimezone }}) </span>
								</span></p>
							</span>	
							<span *ngIf="!element[attribute.name]" fxFlex.lt-sm="65">-</span>
						</span>
						
					</mat-cell>
				</ng-container>

				<tr class="mat-row d-flex" *matNoDataRow >
					<td class="mat-cell d-flex w-100 p-3 font-weight-bold" 
					fxLayoutAlign="center" [attr.colspan]="displayedColumns.length">
						No data matching the filter.
					</td>
				</tr>
				<mat-header-row [ngClass.lt-sm]="'d-none'" *matHeaderRowDef="displayedColumns; sticky: true;"
					class="matHeaderRow"></mat-header-row>
				<mat-row *matRowDef="let row; columns: displayedColumns;" class="matHeaderRow" fxLayout="row" fxLayout.xs="column"
					[ngClass.lt-md]="'responsive-mat-row'" [ngClass.lt-sm]="'responsive-mat-row-small'"></mat-row>
			</mat-table>
			<mat-paginator #formsPaginator="matPaginator" *ngIf="formListDS" [pageSizeOptions]="[10, 25, 50]"
				showFirstLastButtons [pageSize]="10" aria-label="Select page">
			</mat-paginator>
		</div>
	</div>

</div>