import * as _ from 'underscore';
import { ContractRate } from './contractrate';
import { Contract } from './contract';
import { Services } from './services';
import { ServicePoint } from './servicepoint';
import { AccountInsights } from './accountinsights';
import { AccountDetails } from './accountdetails';
import { CollectionActivities } from './collectionactivities';

export class Account {
  id: string;
  displayName: string;
  friendlyName: string;
  customerId: string;
  status: string;
  rpDisplayName: string;
  accountInsights: AccountInsights;
  accountDetails: AccountDetails;

  redCardDue: boolean;
  arrearManagement: boolean;
  isAddressFixed: boolean;
  isBudgetBilling: boolean;
  isBudgetBillingAllowed: boolean;
  isPaNotificationAllowed: boolean;
  isPaperlessBilling: boolean;
  isPaperlessBillingAllowed: boolean;
  isPaymentArrangement: boolean;
  isPaymentArrangementAllowed: boolean;
  contracts: Contract[];
  collectionActivities: CollectionActivities[];
  mailingAddress: string;

  electricityServices: Services[];
  waterServices: Services[];

  public static of(id: string): Account {
    const a: Account = new Account();
    a.id = id;
    return a;
  }

  public processServices(): void {
    this.electricityServices = [];
    this.waterServices = [];
    if (!_.isEmpty(this.contracts)) {
      let serviceList = new Array<Services>();
      _.each(this.contracts, function (c: Contract) {
        if (!_.isEmpty(c.contractRates)) {
          c.contractRates = _.sortBy(c.contractRates, function (cRate: ContractRate) {
             return new Date(cRate.contractRateEndDate); }).reverse();
          c.contractRate = _.extend(new ContractRate(), c.contractRates[0]);
        }
        c.utilityService.isActive = c.contractEndDate >= new Date();
        c.utilityService.contractRates = c.contractRates;
        c.utilityService.latestContract = c;
        serviceList.push(c.utilityService);
      });
      if (serviceList) {
        serviceList = _.filter(serviceList, function (sp: Services) { return sp && !_.isEmpty(sp.contractRates); });
        _.each(serviceList, function (sp: Services) {
          if (sp.servicePoints && !_.isEmpty(sp.servicePoints)) {
            sp.servicePoints = _.map(_.uniq(sp.servicePoints, false, function (_sp) { return _sp.serviceId + '-' + _sp.meterId; }),
              function (obj) { return _.extend(new ServicePoint(), obj); });
          }
        });
        const servicesMap = _.groupBy(serviceList, function (service: Services) { return service.serviceType; });
        const _this = this;
        if (servicesMap['E'] && !_.isEmpty(servicesMap['E'])) {
          _.each(servicesMap['E'], function (ser: Services) {
            if (ser.latestContract.contractRate.isEnergyTileApplicable) {
              const eService: Services = _.extend(new Services(), ser);
              eService.processContractRates(ser.contractRates);
              _this.electricityServices.push(eService);
            }
          });
        }
        if (servicesMap['W'] && !_.isEmpty(servicesMap['W'])) {
          _.each(servicesMap['W'], function (ser: Services) {
            const wService: Services = _.extend(new Services(), ser);
            wService.processContractRates(ser.contractRates);
            _this.waterServices.push(wService);
          });
        }
      }
    }
  }

  // used to check Residential account or not
  public get isResidentialAccount(): boolean {
    let isResidentialAccount = false;
    const electricityObj = _.find(this.contracts, function (contract: Contract) {
      return contract.utilityService && contract.utilityService.serviceType === 'E';
    });
    const hasElectricityService = typeof electricityObj !== 'undefined' ? true : false;
    _.each(this.contracts, function (contract: Contract) {
      if (contract.contractRates) {
        _.each(contract.contractRates, function (contractRate: ContractRate) {
          if (contractRate.distributionRate) {
            if ((hasElectricityService && ((contractRate.distributionRate === 'ERES_DIST')
              || (contractRate.distributionRate === 'ERES_RGEN')))) {
              isResidentialAccount = true;
            } else if (!hasElectricityService && contractRate.distributionRate === 'W&S_RESI') {
              isResidentialAccount = true;
            }
          }
        });
      }
    });
    return isResidentialAccount;
  }
}