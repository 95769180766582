import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { UIUtil, transaction } from '@londonhydro/ux-lib';
import { ExportToCsv } from 'export-to-csv';
import * as moment from 'moment';
import { Moment } from 'moment-timezone';
import * as _ from 'underscore';
import { CsrDataService } from '../../backend/csr/dataservice/csr-data.service';

@Component({
  selector: 'app-pending-cis-updates',
  templateUrl: './pending-cis-updates.component.html',
  styleUrls: ['./pending-cis-updates.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class PendingCisUpdatesComponent implements OnInit, AfterViewInit {

  formsFilterForm: FormGroup;
  showPageLoader = false;
  @ViewChild('formsPaginator') formsPaginator: MatPaginator;
  allForms = null;
  dataForCSVexport = null;
  formListDS: MatTableDataSource<any> = null;
  extendedTableListDS: MatTableDataSource<any>[] = [];
  extendedTableDisplayedColumns: string[];
  displayedColumns: string[];
  prevClickedRowIndex = '';
  @ViewChild('serviceReqTableSort') sort: MatSort;
  cisUpdateType = 'PENDING';
  advSearchOn = false;
  localtz = moment.tz.guess();

  uiTimezone: string;

  formAttributeConfig: any[] = [

    { name: 'transactionId', label: 'Transaction ID', type: 'string', class: 'ID' },
    { name: 'table', label: 'Process', type: 'tableName', class: 'tableName' },
    { name: 'customerId', label: 'Customer ID', type: 'string', class: 'customerId' },
    { name: 'accountId', label: 'Account ID', type: 'string', class: 'accountId' },
    { name: 'createDate', label: 'Transaction Date', type: 'date', class: 'createDateLocal' }
  ];

  extendedFormAttributeConfig: any[] = [
    { name: 'column', label: 'Attribute', type: 'tableColumn', class: 'columnName' },
    { name: 'oldValue', label: 'Previous Value', type: 'string', class: 'oldValue' },
    { name: 'newValue', label: 'Changed Value', type: 'string', class: 'newValue' },
    { name: 'document', label: 'Document', type: 'document', class: 'document' },
  ];

  csvExportformAttributeConfig: any[] = [
    { name: 'transactionId', label: 'Transaction ID', type: 'string' },
    { name: 'table', label: 'Process', type: 'tableName' },
    { name: 'column', label: 'Attribute', type: 'tableColumn' },
    { name: 'oldValue', label: 'Previous Value', type: 'string' },
    { name: 'newValue', label: 'Changed Value', type: 'string' },
    { name: 'customerId', label: 'Customer ID', type: 'string' },
    { name: 'accountId', label: 'Account ID', type: 'string' },
    { name: 'createDate', label: 'Transaction Date', type: 'date' },
    { name: 'document', label: 'Document', type: 'document' },
  ];


  constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef,
    private csrDataService: CsrDataService, private datePipe: DatePipe, private sanitizer: DomSanitizer) {
    this.resetSearchForm();
    this.uiTimezone = UIUtil.UiTimeZone;
  }

  ngOnInit(): void {
    this.showPageLoader = true;
    this.formListDS = new MatTableDataSource<any>([]);
    this.displayedColumns = _.union(['index'], _.pluck(this.formAttributeConfig, 'name'));
    this.getCustomerCISUpdates(false, false);
  }

  ngAfterViewInit(): void {
    this.formListDS.sort = this.sort;
    this.formListDS.paginator = this.formsPaginator;
    this.formListDS.filterPredicate = (data: any, filter: string) => {
      return this.filterDeepValue(data, filter);
    }
  }

  filterDeepValue(obj: any, val: string): boolean {
    if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
      return false;
    }

    else if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        let result = this.filterDeepValue(obj[i], val);
        if (typeof obj[i] === 'object' || Array.isArray(obj[i])) {
          result = this.filterDeepValue(obj[i], val);
        }
        else {
          let temp = obj[i].toString().toLowerCase();
          result = temp.includes(val.toLowerCase()) || temp.replace(' ', '_').includes(val.toLowerCase());
        }
        if (result) {
          return result;
        }
      }
    }
    else if (typeof obj === 'object') {
      for (const k in obj) {
        let result: boolean = false;
        if (typeof obj[k] === 'object' || Array.isArray(obj[k])) {
          result = this.filterDeepValue(obj[k], val);
        }
        else {
          let temp = obj[k].toString().toLowerCase();
          result = temp.includes(val.toLowerCase()) || temp.replace(' ', '_').includes(val.toLowerCase());
        }
        if (result) {
          return result;
        }
      }
    }

    return false;
  }

  setDefaultSearchCriteria(): void {
    if (!this.formsFilterForm.controls.startDate.value && !this.formsFilterForm.controls.endDate.value) {
      const startDt = this.getDefaultStartDate();
      const endDt = this.getTodaysDate();
      this.formsFilterForm.controls.startDate.setValue(startDt);
      this.formsFilterForm.controls.endDate.setValue(endDt);
    }
  }

  getDefaultStartDate() {
    let startDt;
    if (this.cisUpdateType === 'PENDING') {
      startDt =  moment().add(-90, 'days').toDate();
    }
    else {
      startDt = moment().add(-1, 'days').toDate();
    }
    return startDt;
;
  }

  toggleAdvSearch(): void {
    this.advSearchOn = !this.advSearchOn;
    this.setDefaultSearchCriteria();
  }

  getFilteredPendingAndProcessedUpdates(advancedSearchOn: boolean): void {
    this.showPageLoader = true;
    if (this.cisUpdateType === 'PENDING') {
      this.getCustomerCISUpdates(advancedSearchOn, false);
    }
    else {
      this.getCustomerCISUpdates(advancedSearchOn, true);
    }
  }

  getCustomerCISUpdates(advancedSearchOn: boolean, processed: boolean): void {
    if (processed){
      this.cisUpdateType = 'PROCESSED';
    } else {
      this.cisUpdateType = 'PENDING';
    }
    this.setAdvancedSearch(advancedSearchOn);
    this.showPageLoader = true;
    const formValue = this.formsFilterForm.value;
    const customerId = formValue.customerId;
    const accountId = formValue.accountId;
    let startDate = null;
    let endDate = null;
    if (!customerId && !accountId && formValue &&
      !formValue.startDate && !formValue.endDate) {
      startDate = this.getFormattedDate(this.getDefaultStartDate());
      endDate = this.getFormattedDate(this.getTodaysDate());
    }
     else {
      if (formValue && formValue.startDate && formValue.endDate) {
        startDate = this.getFormattedDate(formValue.startDate);
        endDate = this.getFormattedDate(formValue.endDate);
      }
    }
    this.csrDataService.getCISUpdates(processed , customerId, accountId, startDate, endDate).subscribe(
      (res: any) => {
        this.dataForCSVexport = res;
        this.groupDataByTableName(res);
        _.each(this.allForms, (element, index) => {
          element.rowIndex = (index + 1);
          this.extendedTableListDS.push(new MatTableDataSource<any>([]));
          this.extendedTableListDS[element.rowIndex - 1].data = element.transactions;
        });
        this.formListDS.data = this.allForms;
        console.log('this.extendedTableListDS::', this.extendedTableListDS);
        this.extendedTableDisplayedColumns = _.union(['index'], _.pluck(this.extendedFormAttributeConfig, 'name'));
        this.showPageLoader = false;
      },
      (error: any) => {
        this.showPageLoader = false;
        console.log('error getCustomerPendingCISUpdates)', error);
      }
    );
  }

  getFormattedDate(date: any) {
    if (date) {
      return moment.tz(date, this.localtz).format('YYYY-MM-DD');
    }
  }

  getTodaysDate() {
    return moment().add(1, 'days').toDate();
  }

  setAdvancedSearch(advancedSearchOn: boolean){
    if (advancedSearchOn) {
      this.advSearchOn = true;
    }
    else {
      this.advSearchOn = false;
      this.resetSearchForm();
    }
  }

  toggleRowExpansion(index: number): void {
    if (this.prevClickedRowIndex) {
      if ((index - 1).toString() !== this.prevClickedRowIndex) {
        this.formListDS.data[parseInt(this.prevClickedRowIndex)].rowExpanded = false;
        this.formListDS.data[index - 1].rowExpanded = true;
      } else {
        this.formListDS.data[parseInt(this.prevClickedRowIndex)].rowExpanded = !this.formListDS.data[parseInt(this.prevClickedRowIndex)].rowExpanded;
      }
    }
    else {
      this.formListDS.data[index - 1].rowExpanded = true;
    }
    this.prevClickedRowIndex = (index - 1).toString();
  }

  groupDataByTableName(res: any): void {
    const groupedByTable = _.groupBy(res, 'transactionId');
    const mapTableName = _.map(groupedByTable, function (array) {
      return _.groupBy(array, 'transactionId')
    });
    const processedCISdataArray = []
    _.each(mapTableName, function (arrayOfTable) {
      _.each(arrayOfTable, function (arrayOfTransaction) {
        var computedItem = {
          transactionId: "",
          table: "",
          customerId: "",
          accountId: "",
          createDate: "",
          transactions: [],
          rowExpanded: false
        };
        computedItem.table = arrayOfTransaction[0].table.toString().trim().split('_').join(' ') || '';
        computedItem.customerId = arrayOfTransaction[0].customerId || '';
        computedItem.accountId = arrayOfTransaction[0].accountId || '';
        computedItem.createDate = arrayOfTransaction[0].createDate || '';
        computedItem.transactionId = arrayOfTransaction[0].transactionId || '';

        _.each(arrayOfTransaction, function (item, index) {
          if (item.column && item.newValue && item.column.toLowerCase() === 'effectivedate') {
            item.newValue = moment.utc(item.newValue, 'MM-DD-YYYY HH:mm:ss').format('M/DD/YY'); //"07-17-2023 07:10:17"
          }
          computedItem.transactions.push(item);
        });
        processedCISdataArray.push(computedItem);
      });
    });
    this.allForms = processedCISdataArray.sort((a, b) => a.createDate > b.createDate ? -1 : 1);
  }

  applyFilter(filterValue: string) {
    this.formListDS.data.forEach((element, index) => {
      element.rowExpanded = false;
    });
    this.formListDS.filter = filterValue.trim().toLowerCase();
  }

  exportToCSV(): void {
    const tableDataCSV = [];
    this.dataForCSVexport.forEach((element) => {
      const configHeader = this.csvExportformAttributeConfig.filter(element => element.name !== 'document');
      const tempObj: any = {};
      for (const attribute of configHeader) {
        let val = element[attribute.name];
        if (attribute.type === 'document') {
          val = '';
        }
        if (attribute.type === 'date') {
          val = this.datePipe.transform(val, 'mediumDate');
        }
        const key = attribute.label;
        tempObj[key] = val ? val : '';
      }
      tableDataCSV.push(tempObj);
    });
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: 'Pending_CIS_Updates_' + this.datePipe.transform(new Date(), 'shortDate').split('/').join('.')

    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(tableDataCSV);
  }

  sanitize(url: string): any {
    console.log(url);
    this.sanitizer.bypassSecurityTrustUrl(url);
  }

  downloadPdf(url: string) {
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = 'document.pdf';
    downloadLink.click();
    downloadLink.remove();
  }

  dateChangeHandler(dateFormControl: string): void {
    console.log('viewPeriodChangeHandler:::', this.formsFilterForm.controls[dateFormControl]);
  }

  onResetFormSubmit(): void {
    this.resetSearchForm();
    this.cdr.detectChanges();
  }

  private resetSearchForm(): void {
    this.formsFilterForm = this.formBuilder.group({
      transactionId: [''],
      customerId: [''],
      accountId: [''],
      startDate: [''],
      endDate: ['']
    }, { validator: this.dateValidator });

    const startDt = this.getDefaultStartDate();
    const endDt = this.getTodaysDate();
    if (startDt && endDt) {
      this.formsFilterForm.reset();
      setTimeout(() => {
        this.formsFilterForm.controls.startDate.setValue(startDt);
        this.formsFilterForm.controls.endDate.setValue(endDt);
      }, 10);
    }
  }


  dateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const startDt = control.get('startDate');
    const endDt = control.get('endDate');
    return startDt?.value > endDt?.value ? { dateInvalid: true } : null;
  }
}
