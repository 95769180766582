import { Meter } from './meter';

export class ServicePoint {
  meterId: string;
  connectionPoint: string;
  serviceId: string;
  effDate: Date;
  endDate: Date;
  meter: Meter;

  get meterNumber(): string {
    return this.meter ? this.meter.meterNumber : '';
  }

  get isIntervalMeter(): boolean {
    return this.meter && this.meter.meterType === 'WATER_INTERVAL';
  }
}
