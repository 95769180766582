<div id="modal-content-wrapper">
	<section id="modal-body">
		<mat-card class="ux-card border border-light p-0">
			<mat-card-header class="p-2" fxLayout="row" fxLayoutAlign="space-between center">
				<mat-card-title>Address Viewer</mat-card-title>
				<button (click)="closeModal()" class="btn btn-link text-secondary" type="button" id="dismiss"
					aria-label="Close">
					<mat-icon class="material-symbols-outlined">close</mat-icon>
				</button>
			</mat-card-header>

			<mat-card-content class="content-body p-2 " [ngClass.xs]="'address-viewer-content'" fxLayout="column" fxLayoutAlign="start stretch">

				<div fxFlex="100" fxLayout="row" fxLayoutAlign="flex-end">
					<div fxFlex="90" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center" fxLayoutAlign.xs="start stretch"  fxLayoutGap.xs="10px" fxLayoutGap="100px">
						<div fxFlex="50" fxFlex.xs="100">
							<span fxFlex="30">Permit Name: </span>
							<span class="font-weight-bold" fxFlex="70">{{permitName}}</span>
						</div>
						<div fxFlex="50" fxFlex.xs="100">
							<span fxFlex="30">Permit Number: </span>
							<span class="font-weight-bold" fxFlex="70">{{permitNumber}}</span>
						</div>
					</div>
				</div>
				<div fxFlex="100" fxLayout="column" fxLayoutAlign="space-around stretch" class="p-4">
					<div fxLayout="row" fxLayout.lt-sm="column" [ngClass.lt-sm]="'mb-4'" fxFlex="100"
						fxLayoutAlign="end center" fxLayoutGap="15px" class="mb-2">
						<mat-form-field>
							<mat-label>Quick search</mat-label>
							<input matInput class="form-field" #quickSearchInput
								(keyup)="applyFilter($event.target.value)">
							<button matSuffix mat-icon-button aria-label="Clear"
								(click)="applyFilter(''); quickSearchInput.value=''">
								<mat-icon matSuffix>close</mat-icon>
							</button>
						</mat-form-field>

						<button type="button" class="btn btn-sm ux-icon-button" title="Export Data in CSV format"
							(click)="exportToCSV()" fxLayout="row" fxLayoutAlign="start center">
							<mat-icon data-toggle="tooltip" data-placement="bottom" title="Export Data" class="mr-2">
								file_download
							</mat-icon><span>Export Data</span>
						</button>

					</div>
				</div>
				<div>

					<mat-table matTableExporter [dataSource]="formListDS" class="live-data-container mat-elevation-z8"
						matSort #serviceReqTableSort="matSort" #exporter="matTableExporter"
						[ngClass.lt-md]="'mat-table-responsive'" [ngClass.lt-sm]="'mat-table-responsive'">

						<ng-container matColumnDef="index">
							<mat-header-cell *matHeaderCellDef class="sl" mat-sort-header>
								<span>#</span></mat-header-cell>

							<mat-cell *matCellDef="let element; let i = index"
								[ngClass.lt-sm]="'mat-cell-responsive-sm padding-left-md'" class="sl"> <span class="d-none"
									[ngClass.lt-sm]="'mobile-label-display'">#</span><span [ngClass.lt-sm]="'ml-2'">
									{{element.rowIndex}}</span> </mat-cell>
						</ng-container>

						<ng-container *ngFor="let attribute of formAttributeConfig" matColumnDef="{{attribute.name}}">
							<mat-header-cell *matHeaderCellDef class="pl-1"  [class]="attribute.class"
								[ngClass.lt-sm]="'d-none'" mat-sort-header>
								<div>{{attribute.label}}</div>
							</mat-header-cell>
							<mat-cell class="matCell pl-1" [class]="attribute.class" *matCellDef="let element;"
								[ngClass.lt-sm]="'mat-cell-responsive-sm'">
								<span *ngIf="element[attribute.name] !=null">
									<span [ngClass.lt-sm]="'d-flex'"><span class="d-none"
											[ngClass.lt-sm]="'mobile-label-display'">{{attribute.label}}:</span><span
											[ngClass.lt-sm]="'ml-2 text-break'">{{element[attribute.name]}}
										</span></span>
								</span>
								<span *ngIf="element[attribute.name] ==null || element[attribute.name].length==0"><span
										class="d-none"
										[ngClass.lt-sm]="'mobile-label-display'">{{attribute.label}}:</span>-</span>

							</mat-cell>
						</ng-container>

						<mat-row class="mat-row" *matNoDataRow>
							<mat-cell class="mat-cell" [attr.colspan]="displayedColumns.length">
								No data matching the filter.
							</mat-cell>
						</mat-row>
						<mat-header-row [ngClass.lt-sm]="'d-none'" *matHeaderRowDef="displayedColumns; sticky: true;"
							class="matHeaderRow">
						</mat-header-row>
						<mat-row *matRowDef="let row; columns: displayedColumns;" class="matHeaderRow"
							[ngClass.lt-md]="'responsive-mat-row'" [ngClass.lt-sm]="'responsive-mat-row-small'">
						</mat-row>
					</mat-table>
					<mat-paginator #formsPaginator="matPaginator" [pageSizeOptions]="[10, 25, 50]"
						showFirstLastButtons [pageSize]="5" aria-label="Select page">
					</mat-paginator>
				</div>
			</mat-card-content>
		</mat-card>
	</section>
</div>