import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AbstractComponent } from '@londonhydro/ux-lib';
import { IamUserTxnService } from '@londonhydro/iam-api';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent extends AbstractComponent implements OnInit, OnDestroy {
  password: any;
  newPassword: any;
  repeatPassword: any;
  passwordChangeForm: FormGroup = this.formBuilder.group({
    oldPassword: ['', [Validators.required]],
    newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(30),
    Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{6,30})$')]],
    repeatPassword: ['', [Validators.required]]
  });
  submitted = false;

  constructor(injector: Injector, private formBuilder: FormBuilder, private toastr: ToastrService, private iamService: IamUserTxnService) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  userSessionLoaded(): void {
    console.log('LoggedUser', this.user);
  }

  changeAccountPassword(): void {
    const params = {
      oldPassword: '',
      password: ''
    };
    params.oldPassword = this.passwordChangeForm.controls.oldPassword.value;
    params.password = this.passwordChangeForm.controls.newPassword.value;
    if (this.passwordChangeForm.controls.newPassword.value !== this.passwordChangeForm.controls.repeatPassword.value) {
      this.toastr.error('repeat password not matching with new password', 'please provide the same ', {
        timeOut: 10000,
      });
    } else {
      this.iamService.changePassword(this.user.id, params).pipe(
        catchError(error => {
          this.toastr.error('Error Occured in Account Password Change !!!', 'error', {
            timeOut: 10000,
          });
         
             return of(null);
        })).subscribe(
        res => {
          console.log('Response', res);
         
            this.toastr.success('Your password changes has been successfully saved.!!', 'success', {
              timeOut: 10000,
            });
          
        });
    }
  }

  closeMe(): void {
    this.router.navigate(['./csrapp/my-profile-options']);
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
