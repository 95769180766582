<div fxFlex="100" fxLayout="column" class="py-4" *ngIf="loginUser">
	<!--<div fxFlex="58" fxLayoutAlign="space-between stretch">
		 <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="15px">
			<div id="csr-img"></div>
			<div fxLayout="column" fxLayoutAlign="end start">
				<h2 class="m-0">Welcome to the</h2>
				<h1 class="font-weight-bold font-raleway text-black">CSR Dashboard</h1>
			</div>
		</div> -->
	<div *ngIf="loginUser" fxLayout="row" fxLayoutAlign="start center"
		fxLayoutAlign.xs="center center" class="mb-4 ml-3" fxFlex="20"
		fxLayout.xs="column" fxLayout.sm="column">
		<span fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center start"
			fxLayoutAlign.xs="center center">
			<h2>Welcome to the</h2>
			<h1 class="font-weight-bold font-raleway text-black ml-2 mr-2">CSR
				Dashboard.</h1>
		</span> <span fxLayout="row" fxLayout.xs="column"
			fxLayoutAlign="center start" fxLayoutAlign.xs="center center">
			<h2>You are signed in as</h2>
			<h1 class="font-weight-bold font-raleway text-black ml-2">{{loggedInUserName}}</h1>
		</span>
	</div>
	<div fxLayout="row wrap" fxLayoutAlign="center center"
		fxLayoutAlign.xs="center center" fxLayout.xs="column" fxLayoutGap="30px grid"
		fxFlex="80" class="my-2 mt-5">
		<div *ngFor="let link of quickLinks"
			[class]="link.enable?'d-block':'d-none'">
			<div *ngIf="link.enable" class="box-model p-2">
				<a href="{{link.url}}" target="_blank">
					<div class="circle-model" fxLayout="column"
						fxLayoutAlign="center center">
						<div id="{{link.id}}" class="img-feature" role="img"
							aria-label="About Oakville Hydro Image"
							[style.background]="'url(' + link.imgUrl + ') no-repeat center'"></div>
						<span class="circle-label">{{link.label}}</span>
					</div>
				</a>
			</div>
		</div>
	</div>

	<!--<div fxLayout="column" fxLayoutAlign="start stretch" class="box-model p-2" fxFlex="100">
		<div fxLayout="row" fxLayoutAlign="center center" class="p-2">
			<h2 class="font-weight-bold font-raleway">QUICK LINKS</h2>
		</div>

		<div fxLayout="row" fxLayoutAlign="space-evenly center" class="my-2">
			<div fxFlex="45" fxLayoutAlign="center center">
				<a href="https://www.oakvillehydro.com/">
					<div class="circle-model" fxLayout="column" fxLayoutAlign="center center">
						<div id="img-kpi-reports" role="img" aria-label="Kpi Reports Image"></div>
						<span class="circle-label">KPI REPORTS</span>
					</div>
				</a>
			</div>

			<div fxFlex="45" fxLayoutAlign="center center">
				<a href="https://www.oakvillehydro.com/">
					<div class="circle-model" fxLayout="column" fxLayoutAlign="center center">
						<div id="img-training-manual" role="img" aria-label="Training Manual Image"></div>
						<span class="circle-label">TRAINING MANUAL</span>
					</div>
				</a>
			</div>
		</div>
		<div fxLayout="row wrap" fxLayoutAlign="space-evenly center" class="my-2">
			<div fxFlex="45" fxLayoutAlign="center center">
				<a href="https://www.oakvillehydro.com/about-us/about-oakville-hydro">
					<div class="circle-model" fxLayout="column" fxLayoutAlign="center center">
						<div id="img-about" role="img" aria-label="About Oakville Hydro Image"></div>
						<span class="circle-label">ABOUT OAKVILLE HYDRO</span>
					</div>
				</a>
			</div>

			<div fxFlex="45" fxLayoutAlign="center center">
				<a href="https://www.oakvillehydro.com/accounts-customer-services/forms-and-information">
					<div class="circle-model" fxLayout="column" fxLayoutAlign="center center">
						<div id="img-forms" role="img" aria-label="Link to Forms"></div>
						<span class="circle-label">LINK TO FORMS</span>
					</div>
				</a>
			</div>
		</div> -->