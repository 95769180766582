import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { AbstractComponent, ProductConfigService } from '@londonhydro/ux-lib';

@Component({
  selector: 'app-csr-landing-page',
  templateUrl: './csr-landing-page.component.html',
  styleUrls: ['./csr-landing-page.component.scss']
})
export class CsrLandingPageComponent extends AbstractComponent implements OnInit, OnDestroy {

  loggedInUserName: string;
  quickLinks: any;
  featureConfig: any;
  constructor(injector: Injector, private productConfigService: ProductConfigService) {
    super(injector);
  }

  ngOnInit(): void {
    this.loggedInUserName = this.loginUser.username;
    if (this.loggedInUserName.indexOf('#') > 0) {
      this.loggedInUserName = this.loggedInUserName.substring(0, this.loggedInUserName.indexOf('#'));
    }
    console.log('loggedInUserName::: ' + this.loggedInUserName);
    this.loadQuickLinks();
  }

  loadQuickLinks(): void{
     this.productConfigService.productConfig.subscribe((data: any) => {
      if (data.quickLinks){
      this.quickLinks = data.quickLinks;
      console.log('this.quickLinks :: ', this.quickLinks);
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
