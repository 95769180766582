import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { AbstractComponent } from '@londonhydro/ux-lib';

@Component({
  selector: 'app-close-online-account',
  templateUrl: './close-online-account.component.html',
  styleUrls: ['./close-online-account.component.scss']
})
export class CloseOnlineAccountComponent extends AbstractComponent implements OnInit, OnDestroy {

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  closeAccountForm(): void {
    this.router.navigate(['/csrapp/close-account-form']);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
