export class AuditEvents {
	public static ActivateAccount = 'ActivateAccount';
	public static ActivationReminderMail = 'ActivationReminderMail';
	public static ActivationReminderMailLinkExpired = 'ActivationReminderMailLinkExpired';
	public static ChangeEmail = 'ChangeEmail';
	public static ChangePassword = 'ChangePassword';
	public static CreateUser = 'CreateUser';
	public static Deactivated = 'Deactivated';
	public static DelegateUser = 'DelegateUser';
	public static ForgotPassword = 'ForgotPassword';
	public static GrantUserRole = 'GrantUserRole';
	public static LinkAccount = 'LinkAccount';
	public static LinkCustomer = 'LinkCustomer';
	public static Login = 'Login';
	public static Logout = 'Logout';
	public static RequestForChangeEmail = 'RequestForChangeEmail';
	public static ResentRegistrationMail = 'ResentRegistrationMail';
	public static ResetPassword = 'ResetPassword';
	public static RevokeUserRole = 'RevokeUserRole';
	public static UpdateCustomerName = 'UpdateCustomerName';
	public static UpdateUserCustomerAndRole = 'UpdateUserCustomerAndRole';
	public static UpdateUserNameAndContact = 'UpdateUserNameAndContact';
	public static UpdateUserStatus = 'UpdateUserStatus';
	public static UserRegistration = 'UserRegistration';
	public static ValidateEmailLink = 'ValidateEmailLink';
	public static ValidatePromoCode = 'ValidatePromoCode';

	public static AllEventList = ['All',AuditEvents.ActivateAccount, AuditEvents.ActivationReminderMail,
	AuditEvents.ActivationReminderMailLinkExpired, AuditEvents.ChangeEmail, AuditEvents.ChangePassword,
	AuditEvents.CreateUser, AuditEvents.Deactivated,
	AuditEvents.DelegateUser, AuditEvents.ForgotPassword, AuditEvents.GrantUserRole, AuditEvents.LinkAccount,
	AuditEvents.LinkCustomer, AuditEvents.Login, AuditEvents.Logout, AuditEvents.RequestForChangeEmail,
	AuditEvents.ResentRegistrationMail, AuditEvents.ResetPassword, AuditEvents.RevokeUserRole, AuditEvents.UpdateCustomerName,
	AuditEvents.UpdateUserCustomerAndRole, AuditEvents.UpdateUserNameAndContact, AuditEvents.UpdateUserStatus,
	AuditEvents.UserRegistration, AuditEvents.ValidateEmailLink, AuditEvents.ValidatePromoCode];
}