import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceRequestAdminService } from '@londonhydro/customerservice-api';
import { ServiceRequestAlert, ServiceRequestQueue } from '@londonhydro/utility-model';
import { UIUtil } from '@londonhydro/ux-lib';
import * as moment from 'moment-timezone';

@Component({
	selector: 'app-workflow-confirmation-dialog',
	templateUrl: './workflow-confirmation-dialog.component.html',
	styleUrls: ['./workflow-confirmation-dialog.component.scss']
})
export class WorkflowConfirmationDialogComponent implements OnInit {
	title: string;
	message: string;
	formDetails: ServiceRequestQueue;
	requestAlerts: ServiceRequestAlert[];
	uiTimezone: string;

	constructor(public dialogRef: MatDialogRef<WorkflowConfirmationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public modalData: WorkflowConfirmationDialogData,
		private srAdminService: ServiceRequestAdminService) {
		this.title = modalData?.title || 'Confirmation';
		this.message = modalData?.message || 'Are you sure to proceed?';
		this.formDetails = modalData?.formDetails || null;
		this.requestAlerts = modalData?.requestAlerts || null;
		this.uiTimezone = moment(new Date()).tz(UIUtil.UiTimeZone).format('z');
		console.log("Hello", this.uiTimezone);
	}

	private fetchRequestAlerts(): void {
		this.srAdminService.getServiceRequestAlerts(this.formDetails.requestId).subscribe(
			(res: ServiceRequestAlert[]) => {
				this.requestAlerts = res;
				this.requestAlerts.forEach(element => {
					element.createdDate = moment.utc(element.createdDate).toDate();
				})

			},
			(error: any) => {
				console.log('error getFormsCountByType', error);
			}
		);
	}

	ngOnInit(): void {
		if (this.formDetails) {
			if (!this.requestAlerts || this.requestAlerts === null) {
				this.fetchRequestAlerts();
			}
		}
	}

	actionSubmit(): void {
		this.dialogRef.close(true);
	}

	actionCancel(): void {
		this.dialogRef.close();
	}
}

export class WorkflowConfirmationDialogData {
	constructor(public title: string, public message: string, public formDetails: ServiceRequestQueue,
		public requestAlerts?: ServiceRequestAlert[]) {
	}
}
