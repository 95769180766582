import { FilterRule } from './filterrule';

export class DataFilter {
	label: string;
	filter: FilterRule[];

	constructor(label: string) {
		this.label = label;
		this.filter = null;
	}
}
