import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';

@Pipe({
  name: 'listFilter'
})
export class ListFilterPipe implements PipeTransform {

  transform(items: any[], searchProperty: string, searchText: string): any[] {
        if (!items) { return []; }
        if (!searchProperty) { return items; }
        return items.filter(it => {
           if (searchText) {
              return ('' + it[searchProperty]) === searchText;
           } else {
              return _.has(it, searchProperty);
           }
        });
    }
}
