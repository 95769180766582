import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { AbstractComponent } from '@londonhydro/ux-lib';

@Component({
  selector: 'app-block-account',
  templateUrl: './block-account.component.html',
  styleUrls: ['./block-account.component.scss']
})
export class BlockAccountComponent extends AbstractComponent implements OnInit, OnDestroy {

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
