<div id="modal-content-wrapper">
	<section id="modal-body">

		<mat-card class="ux-card border border-light p-0">
			<mat-card-header class="p-2" fxLayout="row" fxLayoutAlign="space-between center">
				<mat-card-title>Assign Request</mat-card-title>
				<button (click)="closeModal()" class="btn btn-link text-secondary" type="button" id="dismiss"
					aria-label="Close">
					<mat-icon class="material-symbols-outlined">close</mat-icon>
				</button>
			</mat-card-header>
			<mat-card-content class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch">


				<form fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch center"
					[formGroup]="assignToUserForm">
					<h4 fxLayout="row" fxFlex="100" class="mt-1 ml-4 font-weight-bold">Select User: *</h4>
					<div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center" class="mt-1 ml-5">
						
						
							<mat-radio-group fxFlex="100" fxLayout="row" formControlName="userId" (change)="selectedOption = $event.value" 
							required [(value)]="selectedOption" >
							<div fxFlex="100" fxLayout="column">
								<mat-radio-button *ngFor="let user of accountsCurrent" [value]="user.value"
								placeholder="Select" fxLayout="row" fxFlex="100" [checked]="user.value===loginUser.id">
								
									<div  class="pr-2">
										{{user.name}}
									</div>
					
								</mat-radio-button>
							</div>
							</mat-radio-group>
					
					</div>
					<div *ngIf="selectedOption === 'Others'" fxLayout="row wrap" fxFlex="70" class="mt-1 ml-5 pl-4"
						fxLayoutAlign="space-between center">
							<mat-form-field appearance="outline">
								<mat-select [(value)]="selectedOptionOthers" placeholder="Select" required
									formControlName="userId">
									<mat-option *ngFor="let user of userList" [value]="user.id"
										(change)="selectedOptionOthers = $event.value" 
										title="{{user.fullName? (user.fullName + ' (' + user.userName + ')') : user.userName}}">
										{{user.fullName? (user.fullName + ' (' + user.userName + ')') : user.userName}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
		
			</form>
				<mat-card-actions fxLayoutGap=" 20px" fxLayoutAlign="end end" class="mr-2">
					<button class="btn btn-sm ux-icon-button" (click)="closeModal()">Cancel</button>
					<button class="btn btn-sm ux-icon-button" (click)="assignToUser()" [disabled]="assignToUserForm.invalid || (selectedOption==='Others' && 
						!selectedOptionOthers)" data-toggle="tooltip" title="Assign Request">Confirm & Assign</button>
				</mat-card-actions>
			</mat-card-content>
		</mat-card>
	</section>
</div>