import { ServicePoint } from './servicepoint';
import * as _ from 'underscore';
import { ContractRate, ContractRates } from './contractrate';
import { Premises } from './premises';
import { Contract } from './contract';

export class Services {
  id: string;
  serviceType: string;
  usdpId: any;
  premises: Premises;
  servicePoint: ServicePoint;
  servicePoints: ServicePoint[];

  isActive: boolean;
  latestContract: Contract;
  latestContractRate: ContractRate;
  contractRates: ContractRate[];

  processContractRates(cRates: ContractRate[]): void {
    this.contractRates = cRates;
    this.latestContractRate = _.extend(new ContractRate(), cRates[0]);
  }

  get energyUsageApplicable(): boolean {
    if (this.latestContractRate) {
    }
    return false;
  }

  get isDcb(): boolean {
    if (this.latestContractRate && this.latestContractRate.supplyRate) {
      return this.latestContractRate.supplyRate.endsWith('DCB');
    }
    return false;
  }

  get isSpot(): boolean {
    if (this.latestContractRate && this.latestContractRate.supplyRate) {
      return this.latestContractRate.supplyRate.endsWith('SPO');
    }
    return false;
  }

  get isDemand(): boolean {
    if (this.latestContractRate && this.latestContractRate.distributionRate) {
      return _.contains(ContractRates.RATES_FOR_DEMAND, this.latestContractRate.distributionRate);
    }
    return false;
  }

  get isDemandAnytime(): boolean {
    if (!_.isEmpty(this.contractRates)) {
      const cRate = _.find(this.contractRates, function (cr: ContractRate) {
        return _.contains(ContractRates.RATES_FOR_DEMAND, cr.distributionRate);
      });
      return cRate ? true : false;
    }
    return false;
  }

  get isConsumption(): boolean {
    if (this.latestContractRate && this.latestContractRate.distributionRate) {
      return _.contains(ContractRates.RATES_FOR_CONSUMPTION, this.latestContractRate.distributionRate);
    }
    return false;
  }

  get isTou(): boolean {
    return this.usdpId !== null;
  }

  get isHdwm(): boolean {
    if (this.latestContractRate && this.latestContractRate.distributionRate) {
      return this.isTou && _.contains(ContractRates.RATES_FOR_HDWM, this.latestContractRate.distributionRate);
    }
    return false;
  }

  get isRpp(): boolean {
    if (this.latestContractRate && this.latestContractRate.supplyRate) {
      return this.isHdwm && this.latestContractRate.supplyRate.endsWith('RPP');
    }
    return false;
  }
  get isEG50(): boolean {
    if (this.latestContractRate && this.latestContractRate.supplyRate) {
      return this.latestContractRate.supplyRate === 'EG<50_RPP';
    }
    return false;
  }

  get isNetMeteredService(): boolean {
    if (this.latestContractRate && this.latestContractRate.distributionRate) {
      if (this.latestContractRate.distributionRate === 'E_FIT' || this.latestContractRate.distributionRate === 'E_MFGN') {
        return true;
      }
    }
    if (this.meterType && this.meterType === 'BI-DI') {
      return true;
    }
    return false;
  }

  get meterType(): string {
    if (this.servicePoint && this.servicePoint.meter) {
      return this.servicePoint.meter.meterType;
    }
    return null;
  }

  get chartType(): string {
    if (!this.isRpp) {
      if ((this.latestContractRate && this.latestContractRate.distributionRate && this.latestContractRate.distributionRate.endsWith('_DISN'))
        || (this.meterType && this.meterType === 'DEMAND')) {
        return 'DEMAND';
      } else if ((this.latestContractRate && this.latestContractRate.distributionRate &&
        this.latestContractRate.distributionRate.endsWith('_DISI')) || (this.meterType && this.meterType === 'INTERVAL')) {
        return 'INTERVAL';
      }
    }
    return this.isTou ? 'SMART' : 'NONTOU';
  }
}