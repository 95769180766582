<div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="showPageLoader" id="loaderdiv">
	<mat-spinner></mat-spinner>
</div>
<div fxLayout="column" *ngIf="formListDS">
	<div fxFlex="100" fxLayout="column" fxLayoutAlign="space-around stretch" class="p-2">
		<div fxLayout="row" fxLayout.lt-sm="column" [ngClass.lt-sm]="'mb-4'" fxFlex="100" fxLayoutAlign="end center"
			fxLayoutGap="15px" *ngIf="formsFilterForm" class="mb-2">
			<mat-form-field>
				<mat-label>Quick search</mat-label>
				<input matInput class="form-field"  #quickSearchInput (keyup)="applyFilter($event.target.value)">
							<button matSuffix mat-icon-button aria-label="Clear"
										 (click)="applyFilter(''); quickSearchInput.value=''">
									<mat-icon matSuffix>close</mat-icon>
							</button>
			</mat-form-field>

			<button type="button" class="btn btn-sm ux-icon-button" title="Export Data in CSV format"
				(click)="exportToCSV()" fxLayout="row" fxLayoutAlign="start center">
				<mat-icon data-toggle="tooltip" data-placement="bottom" title="Export Data" class="mr-2">file_download
				</mat-icon><span>Export Data</span>
			</button>
		</div>
		<div>
			<mat-table matTableExporter [dataSource]="formListDS" class="live-data-container mat-elevation-z8" matSort
				#exporter="matTableExporter" [ngClass.lt-md]="'mat-table-responsive'" [ngClass.lt-sm]="'mat-table-responsive'"
				#manageFormsTableSort="matSort">
				<ng-container matColumnDef="index">
					<mat-header-cell *matHeaderCellDef class="sl" mat-sort-header><span>#</span>
					</mat-header-cell>

					<mat-cell *matCellDef="let element; let i = index" class="sl" [ngClass.lt-sm]="'mat-cell-responsive-sm padding-left-md'">
						<span class="d-none" [ngClass.lt-sm]="'mobile-label-display'">#</span><span [ngClass.lt-sm]="'ml-2'">
							{{element.rowIndex}}</span> </mat-cell>
				</ng-container>

				<ng-container *ngFor="let attribute of formAttributeConfig; index as i" matColumnDef="{{attribute.name}}">
					<mat-header-cell *matHeaderCellDef class="pl-1" [class]="attribute.class"
						[ngClass.lt-sm]="'d-none'" [ngClass.lt-md]="'mat-cell-responsive'" mat-sort-header>
						<div>{{attribute.label}}</div>
					</mat-header-cell>
					<mat-cell class="matCell pl-1 " [class]="attribute.class" *matCellDef="let element;"
						[ngClass.lt-sm]="'mat-cell-responsive-sm'" [ngClass.lt-md]="'mat-cell-responsive'">
						<span *ngIf="element[attribute.name] !=null">
							<span [ngClass.lt-sm]="'d-flex'"><span class="d-none"
									[ngClass.lt-sm]="'mobile-label-display'">{{attribute.label}}:</span><span
									[ngClass.lt-sm]="'ml-2 '" >{{element[attribute.name]}} </span></span>
						</span>
						<span *ngIf="element[attribute.name] ==null">-</span>
					</mat-cell>
				</ng-container>

				<tr class="mat-row d-flex" *matNoDataRow >
						<td class="mat-cell d-flex w-100 p-3 font-weight-bold" 
						fxLayoutAlign="center" [attr.colspan]="displayedColumns.length">
							No data matching the filter.
						</td>
				</tr>
				<mat-header-row [ngClass.lt-sm]="'d-none'" *matHeaderRowDef="displayedColumns; sticky: true;"
					class="matHeaderRow"></mat-header-row>
				<mat-row *matRowDef="let row; columns: displayedColumns;" class="matHeaderRow"
					[ngClass.lt-md]="'responsive-mat-row'" [ngClass.lt-sm]="'responsive-mat-row-small'"></mat-row>
			</mat-table>
			<mat-paginator #formsPaginator="matPaginator" *ngIf="formsFilterForm" [pageSizeOptions]="[10, 25, 50]"
				showFirstLastButtons [pageSize]="10" aria-label="Select page">
			</mat-paginator>
		</div>
	</div>

</div>