import { Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
export abstract class AbstractService {
    protected config: any;
    constructor(@Inject('_MARKETDATA_MODULE_CONFIG_') _config: any) {
        this.config = _config;
        console.log('this.config', this.config);
    }

    protected handleError<T>(operation = 'operation', result?: T): any {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            console.error(`${operation} failed: ${error.message}`);
            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
