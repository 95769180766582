<div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="pageLoader" id="loaderdiv">
	<mat-spinner></mat-spinner>
</div>
<div fxLayout="column" *ngIf="regCheckForm">
	<mat-card>
		<mat-card-content class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch">
			<div fxLayout="row wrap" fxLayoutAlign="start stretch">
				<div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
					<mat-label id="top-label" class="font-weight-bold float-left text-dark">Search by Customer
						Number, Account Number or Email Address:
					</mat-label>
				</div>
				<form fxFlex="50" [formGroup]="regCheckForm" fxLayout="row" fxLayoutAlign="space-between center">
					<mat-form-field appearance="outline" class="m-0" fxFlex="80">
						<input type="search" matInput id="searchRegCheckKey"
							placeholder="Search by Customer Number, Account Number or Email Address"
							formControlName="searchRegCheckKey">
						<button matSuffix mat-icon-button aria-label="Clear"
							(click)="regCheckForm.controls.searchRegCheckKey.setValue(null)">
							<mat-icon matSuffix>close</mat-icon>
						</button>
					</mat-form-field>
					<div fxFlex="19">
						<button type="button" class="btn btn-sm ux-icon-button"  data-toggle="tooltip" title="Search registration check"
							(click)="regCheckSearch()" fxLayout="row" fxLayoutAlign="start center">
							<mat-icon  data-placement="bottom"  class="mr-2">
								search</mat-icon>
							<span>Search</span>
						</button>
					</div>
				</form>
			</div>
			<div *ngIf="isError == true">
				<mat-error>
					<h2>Your search did not match any customer or user.</h2>
					<p> Suggestion:</p>
					<ul>
						<li>Make sure that all words are spelled correctly</li>
						<li>Try different keywords.</li>
						<li>Try more general keywords.</li>
						<li>Try fewer keywords. </li>
					</ul>
				</mat-error>
			</div>
			<div *ngIf="showTableData && dataSource !== null">
				<br />
				<div fxLayoutAlign="center center" fxLayout="column">
					<h2 class="font-weight-bold text-dark">{{fullName[0].fullName}}</h2>
				</div>
				<div fxLayoutAlign="center center">
					<h3 class="font-weight-bold text-dark">Customer ID# <span>{{customerID[0].customerId}}</span></h3>
				</div>
			</div>
			<div *ngIf="showTableData && dataSource !== null">
				<div fxLayoutAlign="center center">
					<h4 class="font-weight-bold text-dark">Account Number # <span>{{accountID[0].accountId}}</span></h4>
				</div>
			</div>

			<div>
				<mat-divider *ngIf="showTableData && dataSource !== null" fxFlex="100"></mat-divider>
				<mat-table #table [dataSource]="dataSource.filteredData"
					class="live-data-container mat-elevation-z8 m-2" *ngIf="showTableData && dataSource !== null"
					[ngClass.lt-md]="'mat-table-responsive'" [ngClass.lt-sm]="'mat-table-responsive'">

					<ng-container *ngFor="let attribute of displayedColumns;" matColumnDef="{{attribute}}">
						<mat-header-cell *matHeaderCellDef class="table-header text-white">
							<h4>
								{{attribute}}
							</h4>
						</mat-header-cell>
						<mat-cell *matCellDef="let element; let i = index" class="matCell ml-1"
							[ngClass.lt-sm]="'mat-cell-responsive-sm padding-left-md'">
							<span *ngIf="attribute === '#'">
								<span class="d-none" [ngClass.lt-sm]="'mobile-label-display'">#</span>
								<span [ngClass.lt-sm]="'ml-2'">{{i + 1}}</span>
							</span>

							<span *ngIf="attribute === 'Posted Date'">
								<span class="d-none" [ngClass.lt-sm]="'mobile-label-display'">Posted Date</span>
								<span [ngClass.lt-sm]="'ml-2'">
									<span>{{element.dueDate | date: 'short' : uiTimezone }}
									</span>
								</span>
							</span>


							<span *ngIf="attribute === 'Payment Amount'">
								<span class="d-none" [ngClass.lt-sm]="'mobile-label-display'">Payment Amount</span>
								<span [ngClass.lt-sm]="'ml-2'">{{element.amount | uicurrency}}
								</span>
							</span>

							<span *ngIf="attribute === 'Payment Description'">
								<span class="d-none" [ngClass.lt-sm]="'mobile-label-display'">Payment
									Description</span>
								<span class="uppcase" [ngClass.lt-sm]="'ml-2'">{{element.description}}
								</span></span>

						</mat-cell>

					</ng-container>

					<mat-header-row [ngClass.lt-sm]="'d-none'" *matHeaderRowDef="displayedColumns; sticky: true;"
						class="matHeaderRow"></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumns;" class="matHeaderRow"
						[ngClass.lt-md]="'responsive-mat-row'" [ngClass.lt-sm]="'responsive-mat-row-small'"></mat-row>
				</mat-table>
			</div>

		</mat-card-content>
	</mat-card>
</div>