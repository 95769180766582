    <div [fxFlex]="attribute.type === 'bankingInfo'?70:40" fxFlex.lt-sm="40" [fxFlex.lt-md]="25" class="p-2">
        {{attribute.label}}:</div>
    <div [fxFlex]="attribute.type === 'bankingInfo'?30:60" fxFlex.lt-sm="60" [fxFlex.lt-md]="75"
        class="p-2 font-weight-bold text-break"
        [ngClass]="(attribute.class || '') + ' ' + (attribute.dataClass? formDetails[attribute.dataValue] : '')">
        <span
            *ngIf="attribute.type === 'string' && !attribute.translate">{{formDetails.getDeepData(attribute.name) || (attribute.default || '-')}}</span>
        <span
            *ngIf="attribute.type === 'string' && attribute.translate">{{formDetails.getDeepData(attribute.name) | labelTranslate}}
        </span>
        <span
            *ngIf="attribute.type === 'date'">{{formDetails.getDeepData(attribute.name)  | date: 'short' : uiTimezone}}
        </span>
        <span
            *ngIf="attribute.type === 'currency'">{{formDetails.getDeepData(attribute.name)  | currency:'USD':'symbol'}}
        </span>
        <span *ngIf="attribute.type === 'bankingInfo'">{{formDetails.getDeepData(attribute.name)}}
        </span>
        <span *ngIf="attribute.type === 'bankingInfoDoc'" class="download-btn">
            <mat-button (click)="downloadPdf('data:application/pdf;base64,'+ formDetails.getDeepData(attribute.name))">
                <mat-icon>cloud_download</mat-icon>
            </mat-button>
        </span>
    </div>