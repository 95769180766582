export class Audit {
	id: number;
	name: string;
	description: string;
	status: string;
	userId: number;
	userEmail: string;
	userName: string;
	additionalData: any;
	createdDate: Date;
	rowIndex: number;
}
