import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractComponent } from '@londonhydro/ux-lib';
import { AccountDataProvider } from '../../backend/csr/dataservice/account.dataprovider';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent extends AbstractComponent implements OnInit, OnDestroy {

  constructor(injector: Injector,route: ActivatedRoute,  accountProvider: AccountDataProvider) {
    super(injector);
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
  }
}
